import { Center, Heading, Text, VStack } from "@chakra-ui/react"
import { useAdminRoles } from "@olagg/api-hooks";
import type { Role } from "@olagg/db-types";
import { useNavigate, useParams } from "react-router-dom";
import RolesForm, { FormRolesData } from "./Form"
import { ROUTES } from "../../../consts/routes";
import { useEffect } from "react";

const EditRole = () => {
  const navigate = useNavigate();
  const { role, findRole, updateRole } = useAdminRoles({ page: 1, limit: 10 })
  const { id } = useParams<{ id: string }>();
  
  useEffect(() => {
    findRole(Number(id));
  }, [id]);

  const onSubmit = (dataForm: FormRolesData) => {
    const rolesData: Partial<Role> = {
      ...dataForm,
    };
    updateRole(Number(id), rolesData, () => navigate(ROUTES.ROLES.all));
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Rol
        </Heading>
        <RolesForm role={role} onSubmit={onSubmit}/>
      </VStack>
    </Center>
  )
}

export default EditRole
