import { TableContainer, Thead, Tr, Th, Tbody, Td, Table as ChakraTable, Flex } from "@chakra-ui/react";
import { TokenDrop } from "@olagg/db-types";
import moment from "moment";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";

const Table = ({ tokenDrops }: { tokenDrops: TokenDrop[] }) => {
    const navigate = useNavigate()
    const goTo = (id: string) => () => {
        navigate(generatePath(ROUTES.TOKEN_DROPS.update, { id }))
    }
    return (
        <Flex w='full' justifyContent='center' >
            <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
                <ChakraTable
                    variant="striped"
                    size="sm"
                    colorScheme="olaggPurple"
                    fontSize="18px"
                    position='relative'
                >
                    <Thead>
                        <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                            <Th color="white" maxW='200px' h='10'>ID</Th>
                            <Th key={'title'} color="white" maxW='200px'>Título</Th>
                            <Th key={'releaseDate'} color="white" maxW='200px'>Fecha de lanzamiento</Th>
                            <Th key={'dueDate'} color="white" maxW='200px'>Fecha de expiración</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tokenDrops?.map((tokenDrop, index) => (
                            <Tr key={index} color="white" onClick={goTo(tokenDrop.id)} cursor="pointer">
                                <Td>{tokenDrop.id}</Td>
                                <Td>{tokenDrop.title}</Td>
                                <Td>{moment(tokenDrop.releaseDate).toLocaleString()}</Td>
                                <Td>{tokenDrop.dueDate ? moment(tokenDrop.dueDate).toLocaleString() : "-"}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </ChakraTable>
            </TableContainer>
        </Flex>
    )
};

export default Table;