import { AnalyticsBrowser } from '@segment/analytics-next'

let _analytics: AnalyticsBrowser | null = null

export function loadAnalytics() {
  try {
    _analytics = AnalyticsBrowser.load(
      {
        writeKey: import.meta.env.VITE_SEGMENT_KEY || '2bVFMstw3pn6OQgzDOndHgqzHd20eRIq',
        cdnURL: 'https://analytics-cdn.olagg.io'
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: 'analytics.olagg.io/v1',
            protocol: 'https' // optional
          }
        }
      }
    )
  } catch (e) {
    catchSegmentError(e)
  }
}

export const analytics = () => _analytics

export const catchSegmentError = (error: any) => {
  console.info(
    '[Segment error] User probably has adblock activated',
    error
  )
}
