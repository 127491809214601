import { Center, VStack, Heading, Spinner } from "@chakra-ui/react";
import { useAdminRelevantActions } from "@olagg/api-hooks";
import { RelevantAction } from "@olagg/db-types";
import { FormRelevantActionData } from "@olagg/validation-schemas/relevantAction.schemas";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import RelevantActionForm from "./Form";

const EditTournament = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { relevantAction, find, updateRelevantAction } = useAdminRelevantActions({ page: 1, limit: 10 });
  
  useEffect(() => {
    find(Number(id));
  }, [id]);

  const onSubmit = (dataForm: FormRelevantActionData) => {
    const relevantActionData: Partial<RelevantAction> = {
      ...dataForm,
      icon: dataForm.icon.value
    };
    updateRelevantAction(Number(id), relevantActionData, () => navigate(ROUTES.RELEVANT_ACTIONS.all));
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Acción Relevante
        </Heading>
        {relevantAction ?
          <RelevantActionForm onSubmit={onSubmit} relevantAction={relevantAction} /> :
          <Spinner>
            Cargando...
          </Spinner>}
      </VStack>
    </Center>
  );
}


export default EditTournament;
