export interface IUseInscriptions {
  tournamentId: number;
  userId?: number;
  onlyWinners?: boolean;
  page: number;
  limit: number;
  sortBy?: inscriptionSortBy;
  orderBy?: 'asc' | 'desc';
}

export enum inscriptionSortBy {
  USER = 'user',
  WINNER = 'winner',
  POSITION = 'position',
  SCORE = 'score',
  PRIZE = 'prize',
}