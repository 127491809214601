import { useState } from 'react';
import { Box, Center, Flex, Heading } from '@chakra-ui/react';
import { useAdminUsers, useUsers } from '@olagg/api-hooks';
import Pagination from '@olagg/ui-kit/Pagination';
import UsersFilter from '../../components/Filters/UsersFilter';
import TokenUsersTable from './Assignments/Create';
import { useSearchParams, useParams } from 'react-router-dom';
import { IUseUsers } from '@olagg/api-hooks/interfaces/IUseUsers';

const TokenAddWhitelistedUser = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filter, setFilter] = useState<IUseUsers>({
    query: searchParams.get('query') || '',
    page: Number(searchParams.get('offset') || 0),
    limit: Number(searchParams.get('limit') || 100)
  });

  const { query, page, limit } = filter;

  const { users, count, error, isLoading: isLoadingUsers } = useAdminUsers({
    query,
    page,
    limit,
    withWallets: true,
  });

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <>
      <Box color="white" flexDirection="column">
        <Center display="block" px={8}>
          <Flex direction={'column'} w="100%" justifyContent="flex-end" mb={4}>
            <Heading textAlign="center" my={2} mb='8'>
              Agregar usuario a la whitelist
            </Heading>
            <caption>Solo apareceran usuarios que tengan asignada una wallet</caption>
          </Flex>

          {/* FILTER */}
          <UsersFilter
            filter={filter}
            onFilter={onFilter}
          />
          {/* TABLE */}
          <Box overflow="auto" mt={4}>
            <Box w="75vw" h="55vh">
              <TokenUsersTable
                tokenId={params.id!}
                users={users}
                isLoading={isLoadingUsers}
              />
            </Box>
          </Box>
        </Center>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: (page + 1)
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Box>
    </>
  )
}

export default TokenAddWhitelistedUser;
