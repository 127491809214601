import { Flex, VStack, Heading, useToast, Spinner, InputGroup, Input, InputLeftElement, FormControl, FormLabel, RadioGroup, Button, Box, Text, Stack, Radio, Checkbox } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useCallback, useEffect, useState } from "react";
import useRoles from "../../features/Roles/hooks/useRoles";
import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminSurveysEndpoints
} from '@olagg/api-hooks'
import OlaggFormInput from "../../components/Forms/OlaggFormInput";
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from '../../consts/routes'
import { convertUTCToLocalISOString, toLocalTimezone } from '../../utils'

type FormData = {
    title: string,
    coverImage: string,
    backgroundImage: string,
    theme: string,
    description: string,
    shortDescription: string,
    og: OGAttributes
    slug: string,
    startDate: string,
    endDate: string,
    visible: boolean,
    authorId: number,
    requireLogin: boolean,
    requireDiscord: boolean,
    requireWallet: boolean,
    requireDepositWallet: boolean,
    typeformId: string,
    score: number,
    notify: boolean,
    urgent: boolean,
    showStatisticsResponse: boolean,
    showStatisticsEnd: boolean,
    publicStatistics: boolean,
    extraData: extraDataAttributes,
}

type OGAttributes = {
    title: string,
    description: string
}

type extraDataAttributes = {
    en: {
        title: string,
        description: string,
        shortDescription: string,
        og: {
            title: string,
            description: string,
        }
    },
    pt: {
        title: string,
        description: string,
        shortDescription: string,
        og: {
            title: string,
            description: string,
        }
    }
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        title: Yup.string().required('El título es obligatorio'),
        coverImage: Yup.string().required('Imagen de la portada es obligatorio'),
        backgroundImage: Yup.string().required('Imagen de fondo es obligatorio'),
        theme: Yup.string().required('El tema es obligatorio'),
        description: Yup.string().required('La descripción es obligatoria'),
        shortDescription: Yup.string().required('La descripción corta es obligatoria'),
        og: Yup
            .object({
                title: Yup
                    .string()
                    .required('El OG Title es obligatorio'),
                description: Yup
                    .string()
                    .required('La OG Description es obligatoria'),
            })
            .required('OG es obligatorio'),
        slug: Yup.string().required('El slug es obligatorio'),
        startDate: Yup
            .date()
            .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
            .required('La fecha y hora de inicio es obligatoria'),
        endDate: Yup
            .date()
            .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
            .required('La fecha y hora de finalización es obligatoria'),
        visible: Yup.boolean().required(),
        requireLogin: Yup.boolean().required(),
        requireDiscord: Yup.boolean().required(),
        requireWallet: Yup.boolean().required(),
        requireDepositWallet: Yup.boolean().required(),
        typeformId: Yup.string().required('El ID de Typeform es obligatorio'),
        score: Yup.string().required('XP es obligatorio'),
        notify: Yup.boolean().required(),
        urgent: Yup.boolean().required(),
        showStatisticsResponse: Yup.boolean().required(),
        showStatisticsEnd: Yup.boolean().required(),
        publicStatistics: Yup.boolean().required(),
        extraData: Yup.object({
            en: Yup.object({
                title: Yup.string().optional().nullable(),
                description: Yup.string().optional().nullable(),
                shortDescription: Yup.string().optional().nullable(),
                og: Yup.object({
                    title: Yup.string().optional().nullable(),
                    description: Yup.string().optional().nullable(),
                })
            }),
            pt: Yup.object({
                title: Yup.string().optional().nullable(),
                description: Yup.string().optional().nullable(),
                shortDescription: Yup.string().optional().nullable(),
                og: Yup.object({
                    title: Yup.string().optional().nullable(),
                    description: Yup.string().optional().nullable(),
                })
            })
        })
    })
    .required()

const SurveyEdit = () => {

    const params = useParams()
    const id = params.id!
    const navigate = useNavigate()
    const { missingPermission } = useRoles();
    const toast = useToast()
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [isLoading, setIsLoading] = useState(false)
    const [markdownDescription, setMarkdownDescription] = useState('')

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    const initForm = () => {
        useFormMethods.setValue('theme', 'light')
        useFormMethods.setValue('visible', false)
        useFormMethods.setValue('requireLogin', true)
        useFormMethods.setValue('requireDiscord', false)
        useFormMethods.setValue('requireWallet', false)
        useFormMethods.setValue('requireDepositWallet', false)
        useFormMethods.setValue('notify', false)
        useFormMethods.setValue('urgent', false)
        useFormMethods.setValue('showStatisticsResponse', false)
        useFormMethods.setValue('showStatisticsEnd', false)
        useFormMethods.setValue('publicStatistics', false)

        fetcher(AdminSurveysEndpoints.find(id))
            .then((response) => {
                const { survey } = response

                useFormMethods.setValue('title', survey?.title)
                useFormMethods.setValue('extraData.en.title', survey?.extraData?.en?.title)
                useFormMethods.setValue('extraData.pt.title', survey?.extraData?.pt?.title)
                useFormMethods.setValue('coverImage', survey?.coverImage)
                useFormMethods.setValue('backgroundImage', survey?.backgroundImage)
                useFormMethods.setValue('description', survey?.description)
                useFormMethods.setValue('extraData.en.description', survey?.extraData?.en?.description)
                useFormMethods.setValue('extraData.pt.description', survey?.extraData?.pt?.description)
                useFormMethods.setValue('shortDescription', survey?.shortDescription)
                useFormMethods.setValue('extraData.en.shortDescription', survey?.extraData?.en?.shortDescription)
                useFormMethods.setValue('extraData.pt.shortDescription', survey?.extraData?.pt?.shortDescription)
                useFormMethods.setValue('og.title', survey?.og.title)
                useFormMethods.setValue('extraData.en.og.title', survey?.extraData?.en?.og?.title)
                useFormMethods.setValue('extraData.pt.og.title', survey?.extraData?.pt?.og?.title)
                useFormMethods.setValue('og.description', survey?.og.description)
                useFormMethods.setValue('extraData.en.og.description', survey?.extraData?.en?.og?.description)
                useFormMethods.setValue('extraData.pt.og.description', survey?.extraData?.pt?.og?.description)
                useFormMethods.setValue('slug', survey?.slug)
                useFormMethods.setValue('startDate', toLocalTimezone(survey?.startDate))
                useFormMethods.setValue('endDate', toLocalTimezone(survey?.endDate))
                useFormMethods.setValue('typeformId', survey?.typeformId)
                useFormMethods.setValue('score', survey?.score)
                useFormMethods.setValue('theme', survey?.theme)
                useFormMethods.setValue('visible', survey?.visible)
                useFormMethods.setValue('requireLogin', survey?.requireLogin)
                useFormMethods.setValue('requireDiscord', survey?.requireDiscord)
                useFormMethods.setValue('requireWallet', survey?.requireWallet)
                useFormMethods.setValue('requireDepositWallet', survey?.requireDepositWallet)
                useFormMethods.setValue('notify', survey?.notify)
                useFormMethods.setValue('urgent', survey?.urgent)
                useFormMethods.setValue('showStatisticsResponse', survey?.showStatisticsResponse)
                useFormMethods.setValue('showStatisticsEnd', survey?.showStatisticsEnd)
                useFormMethods.setValue('publicStatistics', survey?.publicStatistics)
            })
    }

    useEffect(() => {
        initForm()
    }, [id])


    useEffect(() => {
        console.log(errors);

    }, [errors])

    const onSubmit = (dataForm) => {
        let payload = { survey: dataForm }
        setIsLoading(true)
        fetcher(AdminSurveysEndpoints.update(id, payload))
            .then(() => {
                toast({
                    title: 'Encuesta actualizada',
                    colorScheme: 'olaggPink',
                    status: 'success',
                    description: '',
                    position: 'bottom-right'
                })
                useFormMethods.reset()
                navigate(ROUTES.SURVEYS.all)
                setIsLoading(false)
            })
            .catch(e => {
                toast({
                    title: 'Error',
                    colorScheme: 'olaggYellow',
                    status: 'error',
                    description: e?.error?.message,
                    position: 'bottom-right'
                })
                setIsLoading(false)
            })
    }

    return (
        <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
            <VStack color={'white'} w={'full'} maxW={'60%'}>
                <Flex flexDirection="column" w={'full'}>
                    <Heading fontSize={'30px'}>Editar encuesta</Heading>
                </Flex>

                <Flex w={'full'} flexDirection='column'>

                    <Flex position={'relative'} zIndex='0' mt={'20px'}>
                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>

                                <OlaggFormInput
                                    required={true}
                                    label='Título en Español'
                                    controlName='title'
                                    placeholder='Título de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='Título en Inglés'
                                        controlName='extraData.en.title'
                                        placeholder='Título de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='Título en Portugués'
                                        controlName='extraData.pt.title'
                                        placeholder='Título de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <OlaggFormInput
                                    required={true}
                                    label='Imagen de portada (URL)'
                                    controlName='coverImage'
                                    placeholder='Imagen de la portada de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='Imagen de fondo (URL)'
                                    controlName='backgroundImage'
                                    placeholder='Imagen de fondo de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='Descripción en Español (puedes usar texto con Markdown)'
                                    inputType="textarea"
                                    controlName='description'
                                    placeholder='Descripción de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700',
                                        minHeight: '250px'
                                    }}
                                    onChange={(e) => {
                                        setMarkdownDescription(e.target.value)
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción en Inglés (puedes usar texto con Markdown)'
                                        controlName='extraData.en.description'
                                        placeholder='Descripción de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700',
                                            minHeight: '250px'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción en Portugués (puedes usar texto con Markdown)'
                                        controlName='extraData.pt.description'
                                        placeholder='Descripción de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700',
                                            minHeight: '250px'
                                        }}
                                    />

                                </Flex>

                                <OlaggMarkdown>
                                    {markdownDescription || ''}
                                </OlaggMarkdown>

                                <OlaggFormInput
                                    required={true}
                                    label='Descripción corta en Español (NO USAR Markdown)'
                                    inputType="textarea"
                                    controlName='shortDescription'
                                    placeholder='Descripción corta de la encuesta en español'
                                    customStyles={{
                                        fontWeight: '700',
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción corta en Inglés (NO USAR Markdown)'
                                        controlName='extraData.en.shortDescription'
                                        placeholder='Descripción corta de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        inputType="textarea"
                                        label='Descripción corta en Portugués (NO USAR Markdown)'
                                        controlName='extraData.pt.shortDescription'
                                        placeholder='Descripción corta de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />


                                <Text fontSize={'18px'} my='10px' fontWeight='700'>OG para Meta Tags</Text>

                                <OlaggFormInput
                                    required={true}
                                    label='OG Title'
                                    controlName='og.title'
                                    placeholder='Título para meta tags'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='OG Description (no debe ser muy larga)'
                                    controlName='og.description'
                                    placeholder='Descripción para meta tags'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Flex direction='column' w='full' bgColor='#20173d' p='8' mb={'40px'} borderRadius={'12px'}>

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Título en Inglés'
                                        controlName='extraData.en.og.title'
                                        placeholder='OG Título de la encuesta en inglés'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Description en Inglés (no debe ser muy larga)'
                                        controlName='extraData.en.og.description'
                                        placeholder='OG Descripción para meta tags'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <br />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Título en Portugués'
                                        controlName='extraData.pt.og.title'
                                        placeholder='OG Título de la encuesta en portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                    <OlaggFormInput
                                        required={true}
                                        label='OG Description en Portugués (no debe ser muy larga)'
                                        controlName='extraData.pt.og.description'
                                        placeholder='OG Descripción para meta tags en Portugués'
                                        customStyles={{
                                            fontWeight: '700'
                                        }}
                                    />

                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <OlaggFormInput
                                    required={true}
                                    label='Slug'
                                    controlName='slug'
                                    placeholder='Slug de la encuesta'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    label='Fecha y hora de Inicio'
                                    controlName='startDate'
                                    inputType='datetime'
                                    description={`Zona horaria: ${currentTimeZone}`}
                                    datePickerProps={{ backgroundColor: 'white' }}
                                    required={true}
                                />

                                <OlaggFormInput
                                    label='Fecha y hora de Fin'
                                    controlName='endDate'
                                    inputType='datetime'
                                    description={`Zona horaria: ${currentTimeZone}`}
                                    datePickerProps={{ backgroundColor: 'white' }}
                                    required={false}
                                />

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <Text fontSize={'18px'} my='10px' fontWeight='700'>Requiere</Text>

                                {/* <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='requireLogin'
                                    />
                                    <Flex mt={'-10px'}>Login</Flex>
                                </Flex> */}

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='requireDiscord'
                                    />
                                    <Flex mt={'-10px'}>Discord</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='requireWallet'
                                    />
                                    <Flex mt={'-10px'}>Wallet</Flex>
                                </Flex>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='requireDepositWallet'
                                    />
                                    <Flex mt={'-10px'}>Deposit Wallet</Flex>
                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <OlaggFormInput
                                    required={true}
                                    label='ID de encuesta en Typeform'
                                    controlName='typeformId'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <OlaggFormInput
                                    required={true}
                                    label='XP'
                                    controlName='score'
                                    customStyles={{
                                        fontWeight: '700'
                                    }}
                                />

                                <Text fontSize={'18px'} my='10px' fontWeight='700'>Notificaciones y estadisticas</Text>

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='notify'
                                    />
                                    <Flex mt={'-10px'}>Disparar Notificación In-App cuando inicie la encuesta</Flex>
                                </Flex>

                                {/* <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='urgent'
                                    />
                                    <Flex mt={'-10px'}>Urgente</Flex>
                                </Flex>
                                */}

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='showStatisticsResponse'
                                    />
                                    <Flex mt={'-10px'}>Mostrar Estadísticas de Resultados al responder</Flex>
                                </Flex>

                                {/* <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='showStatisticsEnd'
                                    />
                                    <Flex mt={'-10px'}>Mostrar Estadísticas de Resultados al finalizar</Flex>
                                </Flex> */}

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='publicStatistics'
                                    />
                                    <Flex mt={'-10px'}>Mostrar estadisticas publicamente</Flex>
                                </Flex>

                                <Box my='20px' sx={{
                                    width: '100%',
                                    height: '1px',
                                    backgroundColor: 'grey',
                                }} />

                                <Flex
                                    flexDirection={'row'}
                                    alignItems='center'
                                    gap='12px'
                                    sx={{
                                        '& div:first-child': {
                                            width: 'auto !important'
                                        }
                                    }}>
                                    <OlaggFormInput
                                        inputType='checkbox'
                                        controlName='visible'
                                    />
                                    <Flex mt={'-10px'}>Visible</Flex>
                                </Flex>

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                    isDisabled={isLoading || missingPermission(OlaggModule.Survey, PermissionEnum.EDIT)}
                                >
                                    Actualizar encuesta
                                </Button>
                            </form>
                        </FormProvider>
                    </Flex>
                </Flex>
            </VStack >
        </Flex >
    )
};

export default SurveyEdit;