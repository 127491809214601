import {
  Select,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, Role, RolePermission } from "@olagg/db-types";
import { Table as ChakraTable } from "@chakra-ui/react";
import { getKeyByValue } from "../../../utils";

const Table = ({
  roles,
  permissions,
  createRolesPermission,
  updateRolesPermission,
}: {
  roles?: Role[],
  permissions?: RolePermission[],
  createRolesPermission: (data: Partial<RolePermission>, callback?: () => void) => Promise<void>,
  updateRolesPermission: (id: number, data: Partial<RolePermission>, callback?: () => void) => Promise<void>,
}) => {
  const permissionsOptions = Object.entries(PermissionEnum).splice(0, Object.entries(PermissionEnum).length/2);

  const sortedModules = permissions?.map(p => OlaggModule[p.moduleName as keyof typeof OlaggModule]).sort();
  const distinctModules = [...new Set(sortedModules)];
  const modules = distinctModules.map(module => ({
    label: module,
    name: getKeyByValue(OlaggModule, module)
  }));

  const getValue = (role: Role, moduleName: string) => permissions?.filter(p => p.role.id === role.id && p.moduleName === moduleName)[0]?.permission ?? 0;

  const colorFor = (role: Role, moduleName: string) => getValue(role, moduleName) === PermissionEnum.NONE ? 'red.600' : 'green';
  
  const handlePermissionChange = (e: React.ChangeEvent<HTMLSelectElement>, role: Role, moduleName: string) => {
    const id = permissions?.filter(
      p => p.role.id === role.id &&
      p.moduleName === moduleName
    )[0]?.id;

    const data = {
      role_id: role.id,
      permission: Number(e.target.value),
      moduleName
    };

    id ? updateRolesPermission(id, data) : createRolesPermission(data);
  }

  if (!roles || !permissions) return null;

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
      <ChakraTable
        variant="striped"
        size="sm"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white" maxW='200px' h='10'>Módulo</Th>
            {roles.map((role, index) => (
              <Th key={index} color="white" maxW='200px'>{role.name.toUpperCase()}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {modules?.map((module, index) => (
            <Tr key={index} _hover={{ color: 'cyan' }}>
              <Td>{module.label}</Td>
              {roles.map((role, index) => (
                <Td key={index}>
                  <Select
                    size='xs'
                    width='80px'
                    value={getValue(role, module.name!)}
                    bgColor={colorFor(role, module.name!)}
                    onChange={(e) => handlePermissionChange(e, role, module.name!)}
                  >
                    {permissionsOptions.map((option, index) => (
                      <option
                        key={index}
                        style={{ backgroundColor: '#1a202c' }}
                        value={option[0]}
                      >
                        {option[1]}
                      </option>
                    ))}
                  </Select>
                </Td>
              ))}             
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table
