import {
  TableContainer,
  Table as ChakraTable,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Flex,
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useToast,
  Tooltip
} from "@chakra-ui/react"
import { AdminAssignmentEndpoints, fetcher } from "@olagg/api-hooks";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { AssignmentData } from "@olagg/web3/dist/domain/entities/assignment.entity"
import { FaRegClone } from "react-icons/fa";
import useRoles from "../../../features/Roles/hooks/useRoles";

type TableProps = {
  assignments: AssignmentData[]
  tokenId: string
}

const Table = ({ assignments, tokenId }: TableProps) => {
  const toast = useToast();
  const { missingPermission } = useRoles();
  
  const removeAssignment = (assignment: AssignmentData) => () => {
    fetcher(AdminAssignmentEndpoints.delete(tokenId, assignment.id))
      .then(() => {
        toast({
          title: 'Whitelist',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Usuario quitado de la whitelist',
          position: 'bottom-right'
        });
        window.location.reload()
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  };

  const copyToClipboard = (text: string) => () => {
    navigator.clipboard.writeText(text)
  }

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="70vh">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
      >
        <Thead>
          <Tr>
            <Th color="white">Id</Th>
            <Th color="white">Email</Th>
            <Th color="white">Discord</Th>
            <Th color="white" textAlign='center'>Billetera Web3</Th>
            <Th color="white" textAlign='center'>Status</Th>
            <Th textAlign='center' color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {assignments.map((assignment, index) => (
            <Tr key={index}>
              <Td maxWidth='100px'>
                {assignment.user.id}
              </Td>
              <Td>
                {assignment.user.email}
              </Td>
              <Td>
                {assignment.user.discordId}
              </Td>
              <Td textAlign='center'>{assignment.address && (
              <>
                <Tooltip label={assignment.address}>
                  {`${assignment.address.slice(0, 5)}...${assignment.address.slice(-5)}`}
                </Tooltip>
                <Button
                  minW='1rem'
                  height='1rem'
                  ml='0.4rem' p='0'
                  background={'none'}
                  _hover={{ background: 'none', color: 'gray.500' }}
                  onClick={copyToClipboard(assignment.address)}
                >
                  <FaRegClone color="inherit" />
                </Button>
              </>
              )}</Td>
              <Td textAlign='center'>
                {assignment.status}
              </Td>
              <Td textAlign='center'>
                <Flex gap='3' justifyContent={'center'}>
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        isDisabled={missingPermission(OlaggModule.TokenAssignment, PermissionEnum.DELETE)}
                      >
                        Quitar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red' onClick={removeAssignment(assignment)}
                          >
                            Confirmar quitar de la Whitelist
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table
