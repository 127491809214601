import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, Role } from "@olagg/db-types";
import { Table as ChakraTable } from "@chakra-ui/react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../consts/routes";
import useRoles from '../../../features/Roles/hooks/useRoles';

const Table = ({
  roles,
  deleteRole,
}: { 
  roles: Role[],
  deleteRole: (id: number) => Promise<void>,
}) => {
  const navigate = useNavigate();
  const { missingPermission } = useRoles();

  const editRole = (id: number) => navigate(generatePath(ROUTES.ROLES.update, { id }))

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white" maxW='200px'>Nombre</Th>
            <Th color="white">Descripción</Th>
            <Th color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {roles && roles.map((role, index) => (
            <Tr
              key={index} _hover={{ color: 'cyan' }}
              onClick={() => editRole(role.id)} cursor='pointer'
            >
              <Td>{role.name}</Td>
              <Td>{role.description}</Td>
              <Td textAlign='center'>
                <Flex gap='3'>
                  <Button size={'sm'} padding='0px 8px' colorScheme='green'
                    onClick={(e) => { e.stopPropagation(); editRole(role.id); }}
                    isDisabled={missingPermission(OlaggModule.Role, PermissionEnum.EDIT)}
                    >
                    Editar
                  </Button>
                        
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        onClick={(e) => e.stopPropagation()}
                        isDisabled={missingPermission(OlaggModule.Role, PermissionEnum.DELETE)}
                      >
                        Eliminar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red'
                            onClick={(e) => { e.stopPropagation(); deleteRole(role.id); }}
                          >
                            Confirmar Eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table
