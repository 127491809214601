import { Alert, AlertTitle, Box, List, ListItem, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form';

/**
 * Customized Form component for use in OlaGG forms
 *
 * Implements React Hook Forms and validations
 *
 * @example
 *
 *
 */

interface IOlaggForm {
    useFormMethods: UseFormReturn<any>,
    onSubmit: SubmitHandler<any>,
    style?: React.CSSProperties,
    children: ReactNode,
};

const OlaggForm: FC<IOlaggForm> = ({
    useFormMethods,
    onSubmit,
    style = {},
    children
}) => {
    return (
        <FormProvider {...useFormMethods}>
            <Box>
                {useFormMethods.formState.errors && Object.keys(useFormMethods.formState.errors).length > 0 && (
                    <Alert status='error' bgColor={'red'} borderRadius="base">
                        <AlertTitle>Error</AlertTitle>
                        <List>
                            {Object.keys(useFormMethods.formState.errors).map((error, index) => (
                                <ListItem key={index}>
                                    <> {useFormMethods.formState.errors[error]?.message} </>
                                </ListItem>
                            ))}
                        </List>
                    </Alert>
                )}
            </Box>
            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={style}>
                {children}
            </form>
        </FormProvider>
    );
}

export default OlaggForm;
