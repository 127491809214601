import { User } from '@olagg/db-types'
import { AdminUserEndpoints } from '../endpoints/adminEndpoints'
import { fetcher } from '../fetcher'
import { IUseUsers } from '../interfaces/IUseUsers'
import { useQ } from './useQ'

export const useAdminUsers = ({
  country,
  withWallets,
  query,
  page,
  limit
}: IUseUsers) => {
  const response = useQ<{ users: User[], meta: { count: number } }>(
    AdminUserEndpoints.all({
      country,
      query,
      page,
      limit,
      withWallets
    }),
    {
      revalidateOnFocus: true
    }
  )

  // On demand user search for use with searchable dropdowns
  const asyncUserOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { users } = await fetcher<{ users: User[], meta: { count: number } }>(AdminUserEndpoints.all({query: input, page: 1, limit: 50}))

      const userOptions = users?.map(user => ({
        value: user.id, label: `${user.id} - ${user.name} ${user.discordId ? `(${user.discordId})` : ''}`
      }));

      resolve(userOptions);
    });
  }

  return {
    ...response,
    users: response.data?.users ?? [],
    count: response.data?.meta?.count ?? 0,
    asyncUserOptions,
  }
}
