import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Spinner,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { IUseCredentials } from '@olagg/api-hooks/interfaces/IUseCredentials'
import useAdminCredentials from '@olagg/api-hooks/queries/useAdminCredentials'
import { OlaggModule, PermissionEnum } from '@olagg/db-types'
import Pagination from '@olagg/ui-kit/Pagination'
import { FC, useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CredentialsFilter from '../components/Filters/CredentialsFilter'
import CredentialsTable from '../components/Tables/CredentialsTable'
import { ROUTES } from '../consts/routes'
import BulkCreationScholarship from '../features/BulkCreationScholarship'
import useRoles from '../features/Roles/hooks/useRoles'

const Credentials: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState<IUseCredentials>({
    slug: searchParams.get('slug') || '',
    status: searchParams.get('status') || '',
    query: searchParams.get('query') || '',
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 100)
  })

  const { slug, status, query, page, limit } = filter

  const { credentials, count, isLoading, getAll } = useAdminCredentials()

  const { missingPermission } = useRoles();
  
  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    getAll({ slug, status, query, page, limit })
  }, [slug, status, query, page, limit])

  return (
    <>
      <Box color="white" flexDirection="column" p={4}>
        <Heading textAlign="center" my={2}>
          Becas
        </Heading>

        <Flex justifyContent="space-between" alignItems="center" >
          <CredentialsFilter
            filter={filter}
            onFilter={onFilter}
          />
          <Flex 
            mt='2'
            mb='5'
            gap='4'
          >
            <Button
              variant="outlinedGradient"
              size="md"
              leftIcon={<FaPlus />}
              onClick={onOpen}
              isDisabled={missingPermission(OlaggModule.Credential, PermissionEnum.CREATE)}
            >
              Creación masiva
            </Button>
            <Button
              variant="outlinedGradient"
              size="md"
              leftIcon={<FaPlus />}
              onClick={() => navigate(ROUTES.CREDENTIALS.create)}
              isDisabled={missingPermission(OlaggModule.Credential, PermissionEnum.CREATE)}
            >
              Crear Beca
            </Button>
          </Flex>
        </Flex>
        <Box overflow="auto" w="80vw" h="60vh">
          {isLoading ? <Center><Spinner /></Center> : <CredentialsTable credentials={credentials} />}
        </Box>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Box>
      <BulkCreationScholarship
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}
export default Credentials
