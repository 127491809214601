import { Flex, Box, Spinner, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useEffect, useState } from "react";
import PaymentsForm from "./components/form";
import History from "./components/history";
import BatchCreate from "./components/batchCreate";
import BatchUpdate from "./components/batchUpdate";

const Payments = () => {

    const [tabIndex, setTabIndex] = useState<number>(0)

    return (
        <>
            <Tabs
                index={tabIndex}
                onChange={setTabIndex}
            >
                <TabList
                    margin='0 20px 0px 20px'
                    borderColor='#343847'
                    position='relative'
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important'
                        },
                        overflowX: 'auto !important',
                        overflowY: 'hidden !important',
                    }}
                >
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Historial de pagos
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Cargar un pago
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Carga masiva de pagos
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Confirmación masiva de pagos
                    </Tab>
                </TabList>

                <TabPanels p='0'>
                    <TabPanel p='0'>
                        <History tabIndex={tabIndex} />
                    </TabPanel>
                    <TabPanel p='0'>
                        <PaymentsForm />
                    </TabPanel>
                    <TabPanel p='0'>
                        <BatchCreate />
                    </TabPanel>
                    <TabPanel p='0'>
                        <BatchUpdate />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
};

export default Payments;
