import { ApplicationStatus, OlaggModule } from '@olagg/db-types'
import {
  FaAward,
  FaBell,
  FaBitcoin,
  FaDollarSign,
  FaGamepad,
  FaGem,
  FaGift,
  FaHandHolding,
  FaList,
  FaRegFile,
  FaRocket,
  FaRunning,
  FaUser,
  FaUsers
} from 'react-icons/fa'
import { IoAlertCircle, IoSettingsSharp } from "react-icons/io5"

import { GiCartwheel } from "react-icons/gi";
import { ROUTES } from '../../consts/routes'
import { ReactElement } from 'react'

export interface ISidebarRoute {
  path: string
  name: string
  icon: ReactElement
  module: OlaggModule
}

export const routes: ISidebarRoute[] = [
  {
    path: ROUTES.GAMES.all,
    name: 'Juegos',
    icon: <FaRocket color="white" />,
    module: OlaggModule.Game
  },
  {
    path: `${ROUTES.SCHOLARSHIP_APPLICATIONS}?status=${ApplicationStatus.PENDING}`,
    name: 'Solicitudes',
    icon: <FaRegFile color="white" />,
    module: OlaggModule.Scholarship
  },
  {
    path: ROUTES.CREDENTIALS.all,
    name: 'Becas',
    icon: <FaList color="white" />,
    module: OlaggModule.Credential
  },
  {
    path: ROUTES.USERS.all,
    name: 'Usuarios',
    icon: <FaUser color="white" />,
    module: OlaggModule.User
  },
  {
    path: ROUTES.OWNERS.all,
    name: 'Owners',
    icon: <FaHandHolding color="white" />,
    module: OlaggModule.Owner
  },
  {
    path: ROUTES.QUESTS.all,
    name: 'Quests',
    icon: <FaGem color="white" />,
    module: OlaggModule.Quest
  },
  {
    path: ROUTES.RELEVANT_ACTIONS.all,
    name: 'Acciones relevantes',
    icon: <FaRunning color="white" />,
    module: OlaggModule.RelevantAction
  },
  {
    path: ROUTES.NOTIFICATIONS.all,
    name: 'Notificaciones',
    icon: <FaBell color="white" />,
    module: OlaggModule.Notification
  },
  {
    path: ROUTES.USER_CONTENT.all,
    name: 'UGC',
    icon: <FaUsers color="white" />,
    module: OlaggModule.UserContent
  },
  {
    path: ROUTES.MARKETPLACE.all,
    name: 'Tienda',
    icon: <FaGift color="white" />,
    module: OlaggModule.Item
  },
  {
    path: ROUTES.TOURNAMENTS.all,
    name: 'Torneos',
    icon: <FaAward color="white" />,
    module: OlaggModule.Tournament
  },
  {
    path: ROUTES.TOKENS.all,
    name: 'Tokens',
    icon: <FaBitcoin color="white" />,
    module: OlaggModule.Token
  },
  {
    path: ROUTES.ALERTS.all,
    name: 'Alertas',
    icon: <IoAlertCircle color="white" />,
    module: OlaggModule.Alert
  },
  {
    path: ROUTES.ROULETTE.all,
    name: 'Ruleta',
    icon: <GiCartwheel color="white" />,
    module: OlaggModule.RouletteValue
  },
  {
    path: ROUTES.USER_PERMISSIONS.all,
    name: 'Permisos',
    icon: <IoSettingsSharp color="white" />,
    module: OlaggModule.Role
  },
  {
    path: ROUTES.TOKEN_DROPS.all,
    name: 'Token Drops',
    icon: <FaRocket color="white" />,
    module: OlaggModule.TokenDrop
  },
  {
    path: ROUTES.PAYMENTS.all,
    name: 'Pagos',
    icon: <FaDollarSign color="white" />,
    module: OlaggModule.Payment
  },
  {
    path: ROUTES.MINI_GAMES.all,
    name: 'Mini Juegos',
    icon: <FaGamepad color="white" />,
    module: OlaggModule.MiniGame
  },
  {
    path: ROUTES.SURVEYS.all,
    name: 'Encuestas',
    icon: <FaDollarSign color="white" />,
    module: OlaggModule.Survey
  }
]
