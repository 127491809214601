import React, { useEffect } from 'react';
import {
  Button,
  Center,
  Spinner,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast
} from '@chakra-ui/react';
import { AdminAssignmentEndpoints, fetcher, useAdminTokens } from '@olagg/api-hooks';
import type { User } from '@olagg/db-types';
import { ROUTES } from '../../../consts/routes';
import { useNavigate, generatePath } from 'react-router-dom';
import { useSDK } from '@thirdweb-dev/react'
import useTokens from '@olagg/api-hooks/queries/useTokens'



interface IProps {
  tokenId: string
  users?: User[]
  isLoading: boolean
}

const CreateToken = ({
  tokenId,
  users = [],
  isLoading,
}: IProps) => {
  const [metadata, setMetadata] = React.useState({ name: '', image: '' })
  const [loading, setLoading] = React.useState(false)
  const toast = useToast();
  const navigate = useNavigate();
  const sdk = useSDK();
  const { getTokenData, getToken } = useTokens(sdk);

  const getTokenMetadata = async (tokenId: string) => {
    setLoading(true)
    const token = await getToken(tokenId);
    const data = await getTokenData(token.address, token.tokenId);
    const { name, image } = data.metadata
    setMetadata({ name, image })
    setLoading(false)
  }

  useEffect(() => {
    getTokenMetadata(tokenId)
  }, [])

  const addUserToWhitelist = (userId: number) => async () => {

    fetcher(AdminAssignmentEndpoints.create(tokenId, userId, metadata.name, metadata.image))
      .then(() => {
        toast({
          title: 'Whitelist',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Usuario agregado a la whitelist',
          position: 'bottom-right'
        });
        navigate(generatePath(ROUTES.TOKENS.whitelist, { tokenId }))
      })
      .catch((error: { errors: string }) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.errors,
          position: 'bottom-right'
        });
      })
  };

  if (loading) return <Center h="full"><Spinner /></Center>

  return (
    <ChakraTable
      variant="striped"
      colorScheme="olaggPurple"
      fontSize="18px"
    >
      <Thead position={'relative'}>
        <Tr>
          <Th color="white">Id</Th>
          <Th color="white">Nombre</Th>
          <Th color="white">Email</Th>
          <Th color="white">Discord ID</Th>
          <Th color="white">País</Th>
          <Th color="white">Acciones</Th>
        </Tr>
      </Thead>
      <Tbody fontSize={'md'}>
        {users.map(user => (
          <Tr key={user.id}>
            <Td>{user.id}</Td>
            <Td>{user.name}</Td>
            <Td>{user.email}</Td>
            <Td>{user.discordId}</Td>
            <Td>{user.country}</Td>
            <Td>
              <Button
                colorScheme="blue"
                onClick={addUserToWhitelist(user.id)}
                size='sm'
              >
                Agregar
              </Button>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  )
}

export default CreateToken
