import { FC, useState } from 'react';
import {
  Center,
  Heading,
  VStack,
  Button,
  Box,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import { FaEdit } from 'react-icons/fa';
import Pagination from '@olagg/ui-kit/Pagination';
import PermissionsTable from './Table';
import { useAdminUsers } from '@olagg/api-hooks';
import { IUseUsers } from '@olagg/api-hooks/interfaces/IUseUsers';
import UsersFilter from '../../components/Filters/UsersFilter';

const Permissions: FC = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseUsers>({
    query: searchParams.get('query') || '',
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 100)
  })

  const { query, page, limit } = filter;
  const { users, count, isLoading, mutate } = useAdminUsers({ query, page, limit });

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Permisos
        </Heading>

        <Flex w='full' alignItems='center' justifyContent='space-between'>
          <UsersFilter
            filter={filter}
            onFilter={onFilter}
          />
          <Flex>
            <Button
              mt='2' mr='2'
              display='flex'
              variant="outlinedGradient"
              leftIcon={<FaEdit />}
              onClick={() => navigate(ROUTES.ROLES.all)}
            >
              Roles
            </Button>
            <Button
              mt='2' mr='2'
              display='flex'
              variant="outlinedGradient"
              leftIcon={<FaEdit />}
              onClick={() => navigate(ROUTES.ROLES_PERMISSIONS.all)}
            >
              Permisos de Rol
            </Button>
          </Flex>
        </Flex>

        {isLoading ? (
          <Spinner />
        ) : (
          <Box overflow="auto" mt={4} w="80vw" h="55vh">
            <PermissionsTable
              users={users}
              onUpdate={mutate}
            />
          </Box>
        )}

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center >
  )
};

export default Permissions;
