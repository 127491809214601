import { IUseAssignments } from "@olagg/api-hooks/interfaces/IUseAssignments"
import { useState } from "react"
import { generatePath, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { useAdminAssignments } from "@olagg/api-hooks"
import { Center, VStack, Heading, Button, Flex } from "@chakra-ui/react"
import { FaPlus } from "react-icons/fa"
import { ROUTES } from "../../../consts/routes"
import Table from "./Table"
import Pagination from "@olagg/ui-kit/Pagination"
import useRoles from "../../../features/Roles/hooks/useRoles"
import { OlaggModule, PermissionEnum } from "@olagg/db-types"



const Assignments = () => {
  const navigate = useNavigate()
  const { missingPermission } = useRoles();
  const params = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseAssignments>({
    userId: searchParams.get('userId') ? Number(searchParams.get('userId')) : undefined,
    tokenId: params.tokenId!,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const onFilter = ({
    name,
    value
  }: EventTarget & HTMLInputElement) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const AddUserToWhitelist = (tokenId: string) => () => {
    navigate(generatePath(ROUTES.TOKENS.addWhitelistedUser, { id: tokenId }))
  }

  const AddUsersToWhitelist = (tokenId: string) => () => {
    navigate(generatePath(ROUTES.TOKENS.addWhitelistedUsers, { id: tokenId }))
  }

  const { page, limit } = filter
  const { tokenId } = params
  const { count, assignments } = useAdminAssignments(filter)

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} >
        <Heading my={2}>
          Whitelist
        </Heading>

        <Flex justifyContent='flex-start' gap='2' w='full'>
          <Button
            display='flex'
            alignSelf='self-start'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={AddUserToWhitelist(tokenId!)}
            isDisabled={missingPermission(OlaggModule.TokenAssignment, PermissionEnum.CREATE)}
          >
            Agregar User
          </Button>
          <Button
            display='flex'
            alignSelf='self-start'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={AddUsersToWhitelist(tokenId!)}
            isDisabled={missingPermission(OlaggModule.TokenAssignment, PermissionEnum.CREATE)}
          >
            Carga Masiva de Users
          </Button>
        </Flex>
        
        <Table assignments={assignments} tokenId={tokenId!} />
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
        />
      </VStack>
    </Center>
  )
}

export default Assignments
