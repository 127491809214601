import { Flex, Box } from "@chakra-ui/react"
import { useAdminUsers } from "@olagg/api-hooks"
import { IUseTournaments } from "@olagg/api-hooks/interfaces/IUseTournaments"
import { useForm } from "react-hook-form"
import OlaggForm from "../../../components/Forms/OlaggForm"
import OlaggFormSelect from "../../../components/Forms/OlaggFormSelect"

const Filter = ({
  onFilter,
}: {
  onFilter: (filter: any) => void,
}) => {
  const useFormMethods = useForm<IUseTournaments>({
    mode: 'onChange',
  })

  const { asyncUserOptions } = useAdminUsers({ page: 1, limit: 20 })

  const winnersOptions = [
    { label: 'Todos', value: false },
    { label: 'Solo ganadores', value: true },
  ]

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onFilter}
    >
      <Flex mx={5} justifyContent={"space-between"} w="100%">
        <Box w="70%" mr="15px" zIndex={101}>
          <OlaggFormSelect
            controlName="userId"
            placeholder="Buscar usuario por Id, email o username"
            staticOptions={[]}
            asyncOptions={asyncUserOptions}
            isSearchable={true}
            onChange={newValue => onFilter({ name: 'userId', value: newValue?.value })}
          />
        </Box>
        <Box w="30%" zIndex={101}>
          <OlaggFormSelect
            controlName="onlyWinners"
            placeholder="Ganadores"
            staticOptions={winnersOptions}
            isSearchable={false}
            onChange={newValue => onFilter({ name: 'onlyWinners', value: newValue?.value })}
          />
        </Box>
      </Flex>
    </OlaggForm>
  )
}

export default Filter
