import {
  Button,
  Center,
  Flex,
  useToast,
  VStack,
  Heading,
  Switch
} from '@chakra-ui/react'
import { UserContent, ContentType, UserContentStatus, PermissionEnum, OlaggModule } from '@olagg/db-types'
import {
  fetcher,
  AdminUserContentEndpoints,
  useAdminGames,
  useAdminUsers,
} from '@olagg/api-hooks'
import {
  CreateUserContentInput, createUserContentSchema
} from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import OlaggForm from './OlaggForm'
import OlaGGFormInput from './OlaggFormInput'
import OlaggFormSelect from './OlaggFormSelect'
import useRoles from '../../features/Roles/hooks/useRoles'

interface IProps {
  userContent: UserContent
  mode: 'edit' | 'create'
  mutate: () => void
}

const typeOptions = [
  { value: String(ContentType.VIDEO), label: 'Video' },
  { value: String(ContentType.TEXT), label: 'Texto' },
  { value: String(ContentType.IMAGE), label: 'Imagen' },
  { value: String(ContentType.LINK), label: 'Link' },
]

const statusOptions = [
  { value: String(UserContentStatus.PENDING), label: 'Pendiente' },
  { value: String(UserContentStatus.APPROVED), label: 'Aprobado' },
  { value: String(UserContentStatus.REJECTED), label: 'Rechazado' },
]

const UserContentForm: FC<IProps> = ({ userContent, mode, mutate }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [query, setQuery] = useState()
  const isCreate = mode === 'create'
  const isEdit = mode === 'edit'
  const heading = isCreate ? 'Creando nuevo Contenido de usuario' : 'Editar Contenido'
  const { missingPermission } = useRoles();

  const useFormMethods = useForm<CreateUserContentInput>({
    resolver: yupResolver(createUserContentSchema),
    mode: 'onChange'
  })

  const [isEditable, setIsEditable] = useState(
    isEdit ? false : true
  )

  const onSubmit = (data: CreateUserContentInput) => {
    const body: Partial<UserContent> = {
      title: data.title,
      description: data.description!,
      type: data.type.value as ContentType,
      userId: data.userId.value,
      gameId: data.gameId.value,
      content: data.content,
      status: data.status.value as UserContentStatus,
    }

    if (isEdit && userContent.id) {
      fetcher(
        AdminUserContentEndpoints.update(userContent.id, body)
      )
        .then(() =>
          onSuccess(
            'Contenido editado correctamente',
            'El contenido fue editado con éxito'
          )
        )
        .catch(onError)
    }
  }

  const onSuccess = async (title: string, body?: string) => {
    if (mode === 'create') useFormMethods.reset()
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El contenido fue creado con éxito',
      position: 'bottom-right'
    })
    if (mode === 'edit' && userContent.id) {
      mutate();
    }
  }

  const onError = (e: { details: string } | string) => {
    const details = typeof e === 'string' ? e : e.details
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: details
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  const { games } = useAdminGames({ page: 1, limit: 1000 })
  const { users } = useAdminUsers({ query, page: 1, limit: 10 })

  const userOptions = users.map(user => ({
    value: user.id,
    label: user.name || user.email || 'Sin nombre'
  })) || [{ value: '', label: 'Seleccione un usuario' }]

  const gameOptions = games.map(game => ({
    value: game.id,
    label: game.title
  })) || [{ value: '', label: 'Seleccione un juego' }]

  const selectedGame = gameOptions.find(game => game.value === userContent.gameId)
  const selectedStatus = statusOptions.find(status => status.value === userContent.status)
  const selectedType = typeOptions.find(type => type.value === userContent.type)
  const setSelectedUser = (user: { value: number, label: string }) => {
    useFormMethods.setValue('userId', user)
  }

  useEffect(() => {
    useFormMethods.setValue('title', userContent.title)
    useFormMethods.setValue('description', userContent.description)
    useFormMethods.setValue('type', selectedType || { value: '', label: 'Seleccione un tipo' })
    useFormMethods.setValue('userId', {
      value: userContent.userId,
      label: userContent.user.name || 'Seleccione un usuario'
    })
    useFormMethods.setValue('gameId', selectedGame || { value: 0, label: 'Seleccione un juego' })
    useFormMethods.setValue('content', userContent.content)
    useFormMethods.setValue('status', selectedStatus || { value: '', label: 'Seleccione un estado' })
  }, [games])

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>{heading}</Heading>
        {isEdit && (
          <Flex
            w="full"
            justify="end"
            align="center"
            style={{ marginTop: '30px' }}
          >
            <label
              htmlFor="enableEdition"
              style={{ cursor: 'pointer' }}
            >
              Habilitar edición
            </label>
            <Switch
              id="enableEdition"
              colorScheme="purple"
              size="md"
              ml={2}
              onChange={() => setIsEditable(prev => !prev)}
              isDisabled={missingPermission(OlaggModule.UserContent, PermissionEnum.EDIT)}
            />
          </Flex>
        )}
        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
          <fieldset
            disabled={!(isEditable || isCreate)}
          >
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <OlaGGFormInput
                label='Título'
                controlName='title'
                placeholder='Primeros pasos en Axie Infinity'
                required={true}
              />
              <OlaGGFormInput
                label='Contenido'
                controlName='content'
                placeholder='Ej: Vincular Discord'
                required={true}
              />
              <OlaggFormSelect
                label='Tipo'
                controlName='type'
                required={true}
                staticOptions={typeOptions}
              />
              <OlaGGFormInput
                label='Descripción'
                controlName='description'
                placeholder='Ej: En este video te enseñaremos a vincular tu cuenta de Discord con tu cuenta de Olagg.'
              />
              <OlaggFormSelect
                label='Usuario'
                controlName='userId'
                required={true}
                isSearchable={true}
                onInputChange={setQuery}
                staticOptions={userOptions}
                onChange={setSelectedUser}
              />
              <OlaggFormSelect
                label='Juego'
                controlName='gameId'
                required={true}
                staticOptions={gameOptions}
              />
              <OlaggFormSelect
                label='Estado'
                controlName='status'
                required={true}
                staticOptions={statusOptions}
              />
              {isEditable && (
                <Flex w="full" style={{ marginTop: '20px' }}>
                  <Button
                    variant="filledGradient"
                    type="submit"
                    flex="1"
                  >
                    {isCreate ? 'Crear' : 'Guardar cambios'}
                  </Button>
                  {isEdit && (
                    <Button
                      ml={2}
                      variant="outlinedGradient"
                      onClick={() => navigate(-1)}
                      flex="1"
                    >
                      Descartar Cambios
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </fieldset>
        </OlaggForm>
      </VStack>
    </Center>
  )
}

export default UserContentForm
