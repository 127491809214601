import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formRelevantActionSchema = Yup.object({
  name: Yup
    .string()
    .required('Introduce el nombre de la acción relevante'),
  statsLabel: Yup
    .string()
    .required('Introduce la descripción de la estadística'),
  actionLabel: Yup
    .string()
    .required('Introduce la descripción de la acción'),
  buttonLabel: Yup
    .string()
    .required('Introduce el título del botón'),
  buttonLink: Yup
    .string()
    .required('Introduce el link del botón'),
  icon: Yup
    .object({
      value: Yup
        .string(),
      label: Yup
        .object(),
    })
    .required('Elije un icono'),
  score: Yup
    .number()
    .required('Introduce los XP que otorga la acción'),
  requiredUserScore: Yup
    .number()
    .required('Introduce los XP para el umbral mínimo')
    .default(0),
  maxReferrers: Yup
    .number()
    .optional()
    .default(0),
})
  .required();

export type FormRelevantActionData = InferType<typeof formRelevantActionSchema>;
