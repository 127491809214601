import type { QuestOwner } from '@olagg/db-types'
import { AdminOwnersEndpoints } from '../endpoints/adminEndpoints'
import { fetcher } from '../fetcher'
import { useQ } from './useQ'

export const useAdminOwnerQuests = ({
  page,
  limit,
  name
}:{
  page: number,
  limit: number,
  name?: string
}) => {
  const response = useQ<{ owners: QuestOwner[], meta: {count: number} }>(
    AdminOwnersEndpoints.all({name, page, limit})
  )

  // On demand owner search for use with searchable dropdowns
  const asyncOwnerOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { owners } = await fetcher<{ owners: QuestOwner[], meta: {count: number} }>(AdminOwnersEndpoints.all({ name: input, page: 1, limit: 50 }))

      const ownersOptions = owners?.map(owner => ({
        value: owner.id, label: owner.name
      }));

      resolve(ownersOptions);
    });  
  }
  
  return {
    ...response,
    owners: response.data?.owners ?? [],
    asyncOwnerOptions,
    count: response.data?.meta.count ?? 0
  }
}
