export const typeOptions = [
  {
    value: 'information',
    label: 'Información'
  },
  {
    value: 'confirmation',
    label: 'Confirmación'
  },
  {
    value: 'warning',
    label: 'Advertencia'
  },
  {
    value: 'urgent',
    label: 'Urgente'
  },
]
