import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  fetcher,
  AdminGameEndpoints,
  useAdminGames
} from '@olagg/api-hooks'
import {
  Game,
  GameCategory,
  SocialLinks,
  SocialLinksType
} from '@olagg/db-types'
import Category from '@olagg/ui-kit/GameCategory/Category'
import CategoryModal from '@olagg/ui-kit/GameCategory/CategoryModal'
import Edit from '@olagg/ui-kit/svg/Edit'
import {
  CreateGameInput,
  createGameSchema
} from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { mutate } from 'swr'
import Option from '../../Option'
import ChooseBlockchains from './ChooseBlockchains'
import ChoosePlatforms from './ChoosePlatforms'

interface IProps {
  game?: Partial<Game>
  mode: 'create' | 'edit'
}

const buildDescriptionLength = (game?: Partial<Game>) => ({
  es: game?.description?.length || 0,
  en: game?.extraData?.en?.description?.length || 0,
  pt: game?.extraData?.pt?.description?.length || 0
})

const buildLongDescription = (game?: Partial<Game>) => ({
  es: game?.longDescription || '',
  en: game?.extraData?.en?.longDescription || '',
  pt: game?.extraData?.pt?.longDescription || ''
})

const buildTOS = (game?: Partial<Game>) => ({
  es: game?.tos?.content || '',
  en: game?.extraData?.en?.tos || '',
  pt: game?.extraData?.pt?.tos || ''

})

const GameForm: FC<IProps> = ({ game, mode }) => {
  const [otherLangs, setOtherLangs] = useState<boolean>(game?.extraData?.translated || false)
  const { data: { games: allGames } = {} } = useAdminGames({
    page: 1,
    limit: 200
  })
  const {
    handleSubmit,
    register,
    setValue,
    clearErrors,
    formState: { errors, isSubmitting }
  } = useForm<CreateGameInput>({
    defaultValues: {
      longDescription: game?.longDescription || '',
      categories: game?.categories || [],
      blockchains: game?.blockchains || [],
      platforms: game?.platforms || [],
      index: game?.index || allGames?.length || 0
    },
    resolver: zodResolver(createGameSchema.shape['body']),
    mode: 'all'
  })

  const [tos, setTOS] = useState<{ es: string, en: string, pt: string }>(buildTOS(game))
  const handleTOSChange = (lang: string) => (value: string) => setTOS({ ...tos, [lang]: value })

  const { isOpen, onOpen, onClose } = useDisclosure()

  const toast = useToast()

  const [charCount, setCharCount] = useState<{ es: number, en: number, pt: number }>(
    buildDescriptionLength(game)
  )


  const [longDescription, setLongDescription] = useState(buildLongDescription(game))

  const [selectedCategories, setSelectecCategories] = useState<
    GameCategory[]
  >(game?.categories || [])

  const onSubmit = (data: CreateGameInput) => {
    if (socialNetworkLinks.length > 0)
      data.socialLinks = socialNetworkLinks
    const body = {
      ...data,
      longDescription: longDescription.es,
      tosAttributes: {
        id: game?.tos?.id,
        content: tos || ''
      },
      gameCategoryIds: selectedCategories.map(c => c.id),
      extraData: {
        translated: otherLangs,
        en: {
          ...data?.extraData?.en,
          longDescription: longDescription.en,
          description: data.extraData?.en?.description,
          tos: tos.en
        },
        pt: {
          ...data?.extraData?.pt,
          longDescription: longDescription.pt,
          description: data.extraData?.pt?.description,
          tos: tos.pt
        }
      }
    }

    if (mode === 'create') {
      fetcher(AdminGameEndpoints.create(body as Partial<Game>))
        .then(() => {
          toast({
            title: 'Juego creado',
            colorScheme: 'olaggPink',
            status: 'success',
            description: 'El juego fue creado con éxito',
            position: 'bottom-right'
          })
        })
        .catch(e => {
          toast({
            title: 'Error',
            colorScheme: 'olaggYellow',
            status: 'error',
            description: e.errors,
            position: 'bottom-right'
          })
        })
    } else {
      if (game?.slug) {
        fetcher(AdminGameEndpoints.update(game.slug, body as Partial<Game>))
          .then(() => {
            toast({
              title: 'Juego actualizado',
              colorScheme: 'olaggPink',
              status: 'success',
              description: 'El juego fue actualizado con éxito',
              position: 'bottom-right'
            })
            mutate(AdminGameEndpoints.find(game.slug!).path)
          })
          .catch(e => {
            console.log('error')
            toast({
              title: 'Error',
              colorScheme: 'olaggYellow',
              status: 'error',
              description: e.errors,
              position: 'bottom-right'
            })
          })
      }
    }
  }

  const handleCategories = (categories: GameCategory[]) => {
    setValue('categories', categories)
    clearErrors('categories')
    setSelectecCategories(categories)
    onClose()
  }

  const handleLongDescription = (lang: string, value: string) => {
    if (lang === 'es')
      clearErrors('longDescription')

    setLongDescription({ ...longDescription, [lang]: value })
  }

  const baseSocialNetworks = [
    { type: SocialLinksType.WEB, link: '' },
    { type: SocialLinksType.DISCORD, link: '' },
    { type: SocialLinksType.YOUTUBE, link: '' },
    { type: SocialLinksType.TWITTER, link: '' },
    { type: SocialLinksType.INSTAGRAM, link: '' },
    { type: SocialLinksType.TWITCH, link: '' }
  ]

  const [socialNetworkLinks, setSocialNetworkLinks] = useState<
    SocialLinks[]
  >([])
  const [socialNetworkLinksArray, setSocialNetworkLinksArray] =
    useState<SocialLinks[]>(baseSocialNetworks)

  const handleSocialNetworkLinks = (
    link: string,
    type: SocialLinksType
  ) => {
    let newArray: SocialLinks[] = []
    socialNetworkLinksArray.map((sn, i) => {
      let val = sn
      if (sn.type === type) {
        val.link = link
        newArray.push(val)
      } else {
        newArray.push(sn)
      }
    })

    setSocialNetworkLinks(newArray)
  }

  const hydrateSocialLinks = () => {
    game?.socialLinks?.map(sl => {
      handleSocialNetworkLinks(sl.link, sl.type)
    })
  }

  useEffect(() => {
    hydrateSocialLinks()
  }, [game])

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'}>
        <Heading my={2}>
          {mode === 'create'
            ? 'Creando nuevo juego'
            : `Editando ${game?.title}`}
        </Heading>

        <form onSubmit={handleSubmit(onSubmit)}>
          <VStack>
            <Flex
              w="full"
              justify="start"
              align="center"
              style={{ marginTop: '30px' }}
            >
              <label
                htmlFor="enableShowLocale"
                style={{ cursor: 'pointer' }}
              >
                Otros idiomas
              </label>
              <Switch
                id="enableShowLocale"
                colorScheme="purple"
                isChecked={otherLangs}
                size="md"
                ml={2}
                onChange={() => setOtherLangs(prev => !prev)}
              />
            </Flex>
            <FormControl
              color={'white'}
              isInvalid={errors.slug ? true : false}
            >
              <FormLabel htmlFor="slug">Slug</FormLabel>
              <Input
                id="slug"
                placeholder="axie-infinity"
                {...register('slug', {
                  value: game?.slug || ''
                })}
                disabled={mode === 'edit'}
              />
              <FormErrorMessage>
                {errors.slug && errors.slug.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              color={'white'}
              isInvalid={errors.title ? true : false}
            >
              <FormLabel htmlFor="title">Titulo</FormLabel>
              <Input
                id="title"
                placeholder="Axie Infinity"
                {...register('title', {
                  value: game?.title || ''
                })}
              />
              <FormErrorMessage>
                {errors.title &&
                  errors.title.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              color={'white'}
              isInvalid={errors.linkText ? true : false}
            >
              <FormLabel htmlFor="title">
                Texto del botón (CTA principal)
              </FormLabel>
              <Input
                id="linkText"
                placeholder="Jugar"
                {...register('linkText', {
                  value: game?.linkText || ''
                })}
              />
              <FormErrorMessage>
                {errors.linkText &&
                  errors.linkText.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {otherLangs && (
              <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                <FormControl color={'white'} >
                  <FormLabel htmlFor="title">
                    Texto del botón (CTA principal) en Inglés
                  </FormLabel>
                  <Input
                    id="extraData.en.linkText"
                    placeholder="Play"
                    {...register('extraData.en.linkText', {
                      value: game?.extraData?.en?.linkText || ''
                    })}
                  />
                </FormControl>

                <FormControl color={'white'} >
                  <FormLabel htmlFor="title">
                    Texto del botón (CTA principal) en Portugués
                  </FormLabel>
                  <Input
                    id="extraData.pt.linkText"
                    placeholder="Jogar"
                    {...register('extraData.pt.linkText', {
                      value: game?.extraData?.pt?.linkText || ''
                    })}
                  />
                </FormControl>
              </Flex>
            )}

            <FormControl
              color={'white'}
              isInvalid={errors.link ? true : false}
            >
              <FormLabel htmlFor="title">
                Link del botón (CTA principal)
              </FormLabel>
              <Input
                id="link"
                placeholder="https://axes.io/"
                {...register('link', {
                  value: game?.link || ''
                })}
              />
              <FormErrorMessage>
                {errors.link && errors.link.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              color={'white'}
              isInvalid={errors.token ? true : false}
            >
              <FormLabel htmlFor="title">Token</FormLabel>
              <Input
                id="token"
                placeholder="AXIS"
                {...register('token', {
                  value: game?.token || ''
                })}
              />
              <FormErrorMessage>
                {errors.token && errors.token.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pb={2}
              color={'white'}
              isInvalid={errors.hideTokenMetrics ? true : false} //corregir
            >
              <Checkbox
                id="hideTokenMetrics"
                {...register('hideTokenMetrics', {
                  value: game?.hideTokenMetrics || false
                })}
              >
                Ocultar métricas token
              </Checkbox>
              <FormErrorMessage>
                {errors.status &&
                  errors.status.message?.toString()}
              </FormErrorMessage>
            </FormControl>


            <FormControl
              color={'white'}
              isInvalid={errors.categories ? true : false}
              maxW="600px"
              pt={4}
            >
              <FormLabel htmlFor="categories" mb={0}>
                Categoría/s
              </FormLabel>
              <Flex
                justify="space-between"
                align="end"
                cursor="pointer"
                onClick={onOpen}
              >
                {!selectedCategories?.length ? (
                  <Text
                    color="gray.500"
                    fontWeight="400"
                    fontSize="18px"
                  >
                    Selecciona las categorías del juego
                  </Text>
                ) : (
                  <Box
                    display="inline-flex"
                    flexWrap="wrap"
                    w="100%"
                    gap="1"
                  >
                    {selectedCategories?.map(({ id, name }) => (
                      <Category key={id} name={name} selected />
                    ))}
                  </Box>
                )}
                <Edit color="#CBD5E0" />
              </Flex>

              <CategoryModal
                customMessage="Selecciona las categorías del juego:"
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={handleCategories}
                categoriesOfInterest={selectedCategories}
              />
              <FormErrorMessage>
                {errors.categories &&
                  errors.categories.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <ChooseBlockchains
              blockchains={game?.blockchains}
              setValue={setValue}
            />
            <ChoosePlatforms
              platforms={game?.platforms}
              setValue={setValue}
            />

            <FormControl
              color={'white'}
              isInvalid={errors.description ? true : false}
              pt={5}
            >
              <FormLabel htmlFor="description">
                Descripción resumida ({charCount.es} / 166)
              </FormLabel>
              <Textarea
                id="description"
                placeholder="Un juego sobre..."
                {...register('description', {
                  value: game?.description || ''
                })}
                maxLength={166}
                onChange={e => {
                  setCharCount({ ...charCount, es: e.target.value.length })
                }}
              />
              <FormErrorMessage>
                {errors.description &&
                  errors.description.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {otherLangs && (
              <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                <FormControl
                  color={'white'}
                  pt={5}
                >
                  <FormLabel htmlFor="extraData.en.description">
                    Descripción resumida Inglés ({charCount.en} / 166)
                  </FormLabel>
                  <Textarea
                    id="extraData.en.description"
                    placeholder="Un juego sobre..."
                    {...register('extraData.en.description', {
                      value: game?.extraData?.en?.description || ''
                    })}
                    maxLength={166}
                    onChange={e => {
                      setCharCount({ ...charCount, en: e.target.value.length })
                    }}
                  />
                </FormControl>

                <FormControl
                  color={'white'}
                  pt={5}
                >
                  <FormLabel htmlFor="extraData.pt.description">
                    Descripción resumida Portugués ({charCount.pt} / 166)
                  </FormLabel>
                  <Textarea
                    id="extraData.pt.description"
                    placeholder="Un juego sobre..."
                    {...register('extraData.pt.description', {
                      value: game?.extraData?.pt?.description || ''
                    })}
                    maxLength={166}
                    onChange={e => {
                      setCharCount({ ...charCount, pt: e.target.value.length })
                    }}
                  />
                </FormControl>
              </Flex>
            )}

            <FormControl
              color={'white'}
              isInvalid={errors.longDescription ? true : false}
              pt={8}
            >
              <Box minW="600px" maxW={'600px'} minH={'350px'}>
                <FormLabel>Descripción completa:</FormLabel>
                <ReactQuill
                  theme="snow"
                  value={longDescription.es}
                  onChange={value =>
                    handleLongDescription('es', value)
                  }
                  style={{ height: '250px', color: 'white' }}
                />
              </Box>
              <FormErrorMessage pt={7}>
                {errors.longDescription &&
                  errors.longDescription.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            {otherLangs && (
              <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                <FormControl
                  color={'white'}
                  pt={8}
                >
                  <Box minW="600px" maxW={'600px'} minH={'325px'}>
                    <FormLabel>Descripción completa Inglés:</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={longDescription.en}
                      onChange={value =>
                        handleLongDescription('en', value)
                      }
                      style={{ height: '250px', color: 'white' }}
                    />
                  </Box>
                </FormControl>

                <FormControl
                  color={'white'}
                  pt={8}
                >
                  <Box minW="600px" maxW={'600px'} minH={'325px'}>
                    <FormLabel>Descripción completa Portugués:</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={longDescription.pt}
                      onChange={value =>
                        handleLongDescription('pt', value)
                      }
                      style={{ height: '250px', color: 'white' }}
                    />
                  </Box>
                </FormControl>
              </Flex>
            )}

            <FormControl
              pt={8}
              color={'white'}
              isInvalid={errors.newsroomDescription ? true : false}
            >
              <Checkbox
                id="newsroomDescription"
                {...register('newsroomDescription', {
                  value: game?.newsroomDescription || false
                })}
              >
                Tomar la descripción dede Newsroom <br />(el slug del articulo de NR debe coincidir con el slug del juego)
              </Checkbox>
              <FormErrorMessage>
                {errors.status &&
                  errors.status.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              color={'white'}
              isInvalid={errors.image ? true : false}
              pt={8}
            >
              <FormLabel htmlFor="image">
                Imagen (712px x 400px sugerido)
              </FormLabel>
              <Input
                id="image"
                placeholder="https://cdn.olagg.io/game-assets/axie-infinity.png"
                {...register('image', {
                  value: game?.image || ''
                })}
              />
              <FormErrorMessage>
                {errors.image &&
                  errors.image.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pb={2}
              color={'white'}
              isInvalid={errors.status ? true : false}
            >
              <FormLabel htmlFor="status">Estado</FormLabel>
              <Select
                id="status"
                {...register('status', {
                  value: game?.status || 'Próximamente'
                })}
              >
                {['Próximamente', 'Activo'].map(s => (
                  <Option key={s} value={s}>
                    {s}
                  </Option>
                ))}
              </Select>
              <FormErrorMessage>
                {errors.status &&
                  errors.status.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pb={2}
              color={'white'}
              isInvalid={errors.index ? true : false}
            >
              <FormLabel htmlFor="index">
                Índice de visibilidad (más bajo = mayor
                prioridad)
              </FormLabel>
              {allGames && allGames?.length > 0 && (
                <Select
                  id="index"
                  {...register('index', {
                    value: game?.index || allGames?.length || 0,
                    valueAsNumber: true
                  })}
                >
                  {allGames
                    ?.sort((a, b) => a.index - b.index)
                    .map((g, i) => (
                      <option
                        key={g?.index}
                        value={g?.index}
                        style={{ backgroundColor: '#1a202c' }}
                      >
                        {g.index}. {g?.slug}{' '}
                        {i === 0 && '(Primero)'}
                      </option>
                    ))}
                  <Option
                    key={'new'}
                    value={allGames?.length || 0}
                  >
                    {((allGames?.length &&
                      allGames[allGames.length - 1].index) ||
                      0) + 1}
                    . (Último)
                  </Option>
                </Select>
              )}
              <FormErrorMessage>
                {errors.index &&
                  errors.index.message?.toString()}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              pb={2}
              color={'white'}
              isInvalid={errors.visible ? true : false}
            >
              <Checkbox
                id="visible"
                {...register('visible', {
                  value: game?.visible || false
                })}
              >
                Visible
              </Checkbox>
              <FormErrorMessage>
                {errors.status &&
                  errors.status.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            {/* SOCIAL NETWORK LNKS */}

            <FormControl color={'white'}>
              <FormLabel htmlFor="title">
                Social Network Links
              </FormLabel>
              <Box
                border="1px solid white"
                borderRadius="6px"
                padding="20px"
                marginBottom="20px"
                display="grid"
                gridTemplateColumns="repeat(2, 1fr)"
                gap="20px"
              >
                {baseSocialNetworks &&
                  baseSocialNetworks.map((sn, key) => (
                    <Box>
                      <Text fontWeight={600}>{sn.type}</Text>
                      <Input
                        id={`socialNetwork_${key}`}
                        placeholder="Link"
                        marginBottom="20px"
                        value={
                          socialNetworkLinks?.find(
                            v => v.type == sn.type
                          )?.link || ''
                        }
                        onChange={e => {
                          handleSocialNetworkLinks(
                            e.target.value,
                            SocialLinksType[sn.type]
                          )
                        }}
                      />
                    </Box>
                  ))}
              </Box>
            </FormControl>

            <Box
              minW="600px"
              maxW={'600px'}
              minH={'300px'}
              pb={12}
            >
              <FormLabel>Términos de Servicio </FormLabel>
              <ReactQuill
                theme="snow"
                value={tos.es}
                onChange={handleTOSChange('es')}
                style={{ height: '250px', color: 'white' }}
              />
            </Box>

            {otherLangs && (
              <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                <Box
                  minW="600px"
                  maxW={'600px'}
                  minH={'300px'}
                  pb={12}
                >
                  <FormLabel>Términos de Servicio Inglés</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={tos.en}
                    onChange={handleTOSChange('en')}
                    style={{ height: '250px', color: 'white' }}
                  />
                </Box>

                <Box
                  minW="600px"
                  maxW={'600px'}
                  minH={'300px'}
                  pb={12}
                >
                  <FormLabel>Términos de Servicio Portugués</FormLabel>
                  <ReactQuill
                    theme="snow"
                    value={tos.pt}
                    onChange={handleTOSChange('pt')}
                    style={{ height: '250px', color: 'white' }}
                  />
                </Box>
              </Flex>
            )}

            <Button
              variant={'filledGradient'}
              isLoading={isSubmitting}
              disabled={isSubmitting}
              type="submit"
              w={'full'}
            >
              Enviar
            </Button>
          </VStack>
        </form>
      </VStack>
    </Center>
  )
}

export default GameForm
