import { MarketPlaceItem, MarketPlaceItemType, OlaggModule, PermissionEnum } from "@olagg/db-types";
import { Badge, Box, Table as ChakraTable, Tbody, Td, Th, Thead, Tr, Text, GridItem, Button, Popover, PopoverTrigger, Portal, PopoverArrow, PopoverCloseButton, PopoverBody, PopoverContent, useToast, TableContainer } from '@chakra-ui/react'
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { AdminMarketPlaceEndpoints, fetcher } from "@olagg/api-hooks";
import MarketplaceCoupons from "../../components/Popover/MarketplaceCoupons";
import { slugify } from '../../utils'
import useRoles from '../../features/Roles/hooks/useRoles'

const typeText = (s: string) => {
  if (s == MarketPlaceItemType.LOTTERY) {
    return <Badge>Sorteo</Badge>
  }
  if (s == MarketPlaceItemType.LIMITED) {
    return <Badge>Cupos limitados</Badge>
  }
  if (s == MarketPlaceItemType.UNLIMITED) {
    return <Badge>Cupos ilimitados</Badge>
  }
}

const Table = ({ items }: { items: MarketPlaceItem[] }) => {
  const navigate = useNavigate()
  const toast = useToast()
  const { missingPermission } = useRoles();

  const editItem = (item: MarketPlaceItem) => {
    const path = generatePath(ROUTES.MARKETPLACE.update, { id: item.id });
    navigate(path, { state: { data: item } })
  }
  const deleteItem = async (c: MarketPlaceItem) => {
    fetcher(
      AdminMarketPlaceEndpoints.delete(c.id!)
    ).then(() => {
      toast({
        title: 'Item eliminado',
        colorScheme: 'olaggPink',
        status: 'success',
        description: 'Item eliminado con éxito',
        position: 'bottom-right'
      })
      window.location.reload();
    }).catch(e => {
      toast({
        title: 'Error',
        colorScheme: 'olaggYellow',
        status: 'error',
        description: e.message,
        position: 'bottom-right'
      })
    })
  }

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' bg='gray.800' top='0' zIndex='100'>
        <Tr>
          <Th color="white">ID</Th>
          <Th color="white">Título</Th>
          <Th color="white">Tipo</Th>
          <Th color="white" textAlign='center'>Level</Th>
          {/* <Th color="white" textAlign='center'>Estado</Th> */}
          <Th color="white" textAlign='center'>Cupones</Th>
          <Th color="white" textAlign='center'>URL</Th>
          <Th textAlign='center' color="white">Acción</Th>
        </Tr>
      </Thead>
      <Tbody>
        {items.map(item => (
          <Tr key={item.id}>
            <Td>
              {item?.internalId}
            </Td>
            <Td maxWidth='300px' minWidth='250px' style={{textWrap: 'wrap'}}>
              {item.name}
            </Td>
            <Td maxWidth='200px'>
              <Box display='flex' flexDirection='row' alignItems='center'>
                {typeText(item.type)} {item.type == MarketPlaceItemType.LIMITED &&
                  <Text ml='5px' fontSize='12px'>({item.availableStock} / {item.totalCodes})</Text>
                }
              </Box>
            </Td>
            <Td textAlign='center'>{item.level}</Td>
            <Td textAlign='center'>
              {item.level == 0 && (
                `${item.couponsAvailable} / ${(item.couponsAvailable! + item.couponsTaken!)}`
              )}
            </Td>
            <Td minW='200x' >{`/tienda/${slugify(item?.name || '')}`}</Td>
            <Td textAlign='right'>

              {item?.level == 0 && <MarketplaceCoupons item={item} />}

              <Button
                size={'sm'} padding='0px 8px' onClick={() => { editItem(item) }} colorScheme="green"
                isDisabled={missingPermission(OlaggModule.Item, PermissionEnum.EDIT)}
              >
                Editar
              </Button>

              <Popover>
                <PopoverTrigger>
                  <Button
                    size={'sm'} padding='0px 8px' ml={5} colorScheme="red"
                    isDisabled={missingPermission(OlaggModule.Item, PermissionEnum.DELETE)}
                  >
                    Eliminar
                  </Button>
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Button width={'100%'} colorScheme='blue' onClick={() => {
                        deleteItem(item)
                      }}>
                        Confirmar Eliminar
                      </Button>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>

            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
    </TableContainer>
  )
}

export default Table
