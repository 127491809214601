import OlaggFormSelect from "./OlaggFormSelect";
import OlaGGFormInput from "./OlaggFormInput";
import { useState } from "react";
import { Quest } from "@olagg/db-types";
import { Flex } from "@chakra-ui/react";

const twitterQuestsSubtypesOptions = [
    { value: 'follow', label: 'Follow' },
    { value: 'like', label: 'Like' },
    { value: 'retweet', label: 'Retweet' },
    { value: 'tweet', label: 'Tweet' },
]

const TwitterQuestForm = ({ quest, showLocaleInputs }: { quest: Quest, showLocaleInputs: boolean }) => {
    const [subtype, setSubtype] = useState(quest?.extraData?.subtype || 'follow');

    return (
        <>
            <OlaggFormSelect
                newVersion={true}
                controlName="extraData.subtype"
                label="Subtipo"
                staticOptions={twitterQuestsSubtypesOptions}
                isSearchable={false}
                onChange={(e) => setSubtype(e)}
            />
            {subtype === 'tweet' && (
                <>
                    <OlaGGFormInput
                        controlName="extraData.tweetText"
                        label="Tweet text"
                        required
                    />
                    {showLocaleInputs && (
                        <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                            <OlaGGFormInput
                                controlName="extraData.en.tweetText"
                                label="Tweet text (EN)"
                                required
                            />
                            <OlaGGFormInput
                                controlName="extraData.pt.tweetText"
                                label="Tweet text (PT)"
                                required
                            />
                        </Flex>
                    )}
                    <OlaGGFormInput
                        controlName="extraData.modalButtonText"
                        label="Texto CTA"
                        required
                    />
                    {showLocaleInputs && (
                        <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                            <OlaGGFormInput
                                controlName="extraData.en.modalButtonText"
                                label="Texto CTA (EN)"
                                required
                            />
                            <OlaGGFormInput
                                controlName="extraData.pt.modalButtonText"
                                label="Texto CTA"
                                required
                            />
                        </Flex>
                    )}
                </>

            )}
            {subtype !== 'tweet' && subtype !== 'follow' && (
                <>
                    <OlaGGFormInput
                        controlName="extraData.tweetId"
                        label="Tweet ID"
                        required
                    />
                    {showLocaleInputs && (
                        <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                            <OlaGGFormInput
                                controlName="extraData.en.tweetId"
                                label="Tweet ID (EN)"
                                required
                            />
                            <OlaGGFormInput
                                controlName="extraData.pt.tweetId"
                                label="Tweet ID (PT)"
                                required
                            />
                        </Flex>
                    )
                    }
                </>
            )}
            {subtype === 'follow' && (
                <OlaGGFormInput
                    controlName="extraData.username"
                    label="Username en X"
                    required
                />
            )}
        </>
    )
};

export default TwitterQuestForm;