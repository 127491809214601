import { useToast } from '@chakra-ui/react';
import { useQ } from "./useQ";
import { AdminRouletteEndpoints } from '../endpoints/adminEndpoints';
import { RouletteValue } from "@olagg/db-types";
import { fetcher } from "../fetcher";

export default function useAdminRoulette() {
  const { data, mutate } = useQ<{ rouletteValues: RouletteValue[], meta: {count: number} }>(AdminRouletteEndpoints.all());
  const toast = useToast();

  const update = (id: number, data: Partial<RouletteValue>) => {
    fetcher(AdminRouletteEndpoints.update(id, data))
      .then(() => {
        mutate();
        toast({
          title: 'OK',
          colorScheme: 'olaggGreen',
          status: 'success',
          description: 'Posición actualizada correctamente',
          position: 'bottom-right',
          duration: 2000,
          isClosable: true
        });
      })
      .catch(error => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.error,
          position: 'bottom-right',
          duration: 2000,
          isClosable: true
        });
        console.log(error);
      })
  }

  return {
    values: data?.rouletteValues,
    count: data?.meta?.count,
    update,
  }
}
