import { useState } from 'react';
import { Center, Heading, VStack, Button, Flex, useDisclosure } from '@chakra-ui/react';
import { useAdminInscriptions } from '@olagg/api-hooks';
import { useNavigate, useParams, generatePath, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../../consts/routes';
import { FaPlus, FaDollarSign } from 'react-icons/fa';
import Pagination from '@olagg/ui-kit/Pagination';
import Table from './Table';
import Filter from './Filter';
import { inscriptionSortBy, IUseInscriptions } from '@olagg/api-hooks/interfaces/IUseInscriptions';
import useRoles from '../../../features/Roles/hooks/useRoles';
import { OlaggModule, PermissionEnum } from '@olagg/db-types';
import CreatePaymentsModal from './CreatePaymentsModal';

const TournamentInscriptions = () => {
  const navigate = useNavigate();
  const { isOpen: isModalOpen, onClose: onModalClose, onOpen: onModalOpen } = useDisclosure();
  const { missingPermission } = useRoles();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseInscriptions>({
    tournamentId: Number(params.id!),
    userId: searchParams.get('userId') ? Number(searchParams.get('userId')) : undefined,
    onlyWinners: searchParams.get('onlyWinners') ? Boolean(searchParams.get('onlyWinners')) : undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
    sortBy: searchParams.get('sortBy') as inscriptionSortBy || inscriptionSortBy.POSITION,
    orderBy: searchParams.get('orderBy') as 'asc' | 'desc' || 'asc',
  })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const onSortChange = (fieldName: string) => {
    if (fieldName === filter.sortBy)
      onFilter({ name: 'orderBy', value: filter.orderBy === 'asc' ? 'desc' : 'asc' })
    else
      onFilter({ name: 'sortBy', value: fieldName })
  }

  const { page, limit } = filter

  const { count, inscriptions, mutate } = useAdminInscriptions(filter)

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Participantes
        </Heading>

        <Flex w='full' justifyContent='space-between' alignItems='center'>
          <Filter onFilter={onFilter} />
          <Flex mb='5' mr='10'>
            <Button
              display='flex'
              variant="outlinedGradient"
              onClick={() => onModalOpen()}
              isDisabled={missingPermission(OlaggModule.Payment, PermissionEnum.CREATE)}
              mr={2}
            >
              <FaDollarSign style={{ marginRight: '5px' }} />
              Cargar pagos
            </Button>
            <Button
              display='flex'
              variant="outlinedGradient"
              leftIcon={<FaPlus />}
              onClick={() => navigate(generatePath(ROUTES.TOURNAMENTS.addInscription, { id: params.id }))}
              isDisabled={missingPermission(OlaggModule.Inscription, PermissionEnum.CREATE)}
            >
              Añadir Participantes
            </Button>
          </Flex>
        </Flex>

        <Table
          inscriptions={inscriptions}
          onUpdate={mutate}
          onSortChange={onSortChange}
          filter={filter}
        />
        
        <CreatePaymentsModal
          isOpen={isModalOpen}
          onClose={() => onModalClose()}
          tournamentId={Number(params.id)}
        />

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </VStack >
    </Center >
  )
};

export default TournamentInscriptions;
