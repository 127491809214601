import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    Text,
    Badge
} from "@chakra-ui/react";
import moment from 'moment/moment.js'
import 'moment/min/locales'


const ModalDetail = ({ isOpen, onClose, selectedPayment }: { isOpen: boolean, onClose: () => void, selectedPayment: {} }) => {

    const Status = (props) => {
        switch (props.status) {
            case 'pending':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#FFD600'} fontWeight='500' textTransform={'none'}>Pendiente</Badge>
                break;
            case 'approved':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#38ABFF'} fontWeight='500' textTransform={'none'}>Aprovado</Badge>
                break;
            case 'paid':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#37A169'} fontWeight='500' textTransform={'none'}>Pagado</Badge>
                break;
            case 'canceled':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#FF6838'} fontWeight='500' textTransform={'none'}>Cancelado</Badge>
                break;
            case 'paying':
                return <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'white'} fontWeight='500' textTransform={'none'}>Pagando</Badge>
                break;
            default:
                return <></>
                break
        }
    }

    const typeText = {
        'tournament': 'Torneo',
        'lottery': 'Sorteo',
        'opportunity': 'Oportunidad',
        'other': 'Otro',
        'treasures_game': 'Juego de tesoros'
    }

    return (

        <Modal isOpen={isOpen} onClose={onClose} isCentered size='md'>
            <ModalContent mx='5'>
                <ModalCloseButton />
                <ModalBody textAlign='center' py='8'>
                    <Flex flexDirection={'column'} justifyContent='center' gap='10px'>
                        <Flex fontSize={'16px'} fontWeight='700'>
                            Detalle de pago creado por {selectedPayment?.creator?.name}
                        </Flex>
                        <Flex fontSize={'14px'} fontWeight='400' flexDirection={'column'} gap="10px">
                            <Flex gap='5px'><Text fontWeight={'700'}>ID:</Text> {selectedPayment?.id}</Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Usuario:</Text> {selectedPayment?.user?.name}</Flex>
                            {selectedPayment?.status == 'approved' && <Flex gap='5px'><Text fontWeight={'700'}>Wallet:</Text> {selectedPayment?.address}</Flex>}
                            <Flex gap='5px'><Text fontWeight={'700'}>Tipo:</Text> {typeText[selectedPayment?.paymentParent?.type]}</Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Titulo:</Text> <Text textAlign={'left'}>{selectedPayment?.paymentParent?.title}</Text></Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Fecha creación:</Text> {selectedPayment?.createdAt && moment(selectedPayment?.createdAt).format('DD/MM/YYYY')}</Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Fecha actualización:</Text> {selectedPayment?.updatedAt && moment(selectedPayment?.updatedAt).format('DD/MM/YYYY')}</Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Presupuesto:</Text> {selectedPayment?.budget}</Flex>
                            <Flex gap='5px'><Text fontWeight={'700'}>Descripción:</Text> {selectedPayment?.description}</Flex>
                            {selectedPayment?.address && (<Flex gap='5px'><Text fontWeight={'700'}>Address</Text> {selectedPayment?.address}</Flex>)}
                            {selectedPayment?.link && (<Flex gap='5px'><Text fontWeight={'700'}>Link</Text> {selectedPayment?.link}</Flex>)}
                            {selectedPayment?.reason && (<Flex gap='5px'><Text fontWeight={'700'}>Motivo</Text> {selectedPayment?.reason}</Flex>)}
                            <Flex mt={'10px'}>
                                <Status {...selectedPayment} />
                            </Flex>
                        </Flex>



                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
};

export default ModalDetail;
