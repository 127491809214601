import {
  Quest
} from '@olagg/db-types'
import { AdminQuestEndpoints } from '../endpoints/adminEndpoints'
import { IUseQuestsOwnerId } from '../interfaces/IUseQuestsOwnerId'
import { useQ } from './useQ'

export const useAdminQuestsByOwnerId = ({
  id
}: IUseQuestsOwnerId) =>{
  const response = useQ<{quests: Quest[], meta: {count: number}}>(
    AdminQuestEndpoints.ByOwnerId({id}),
    {
      revalidateOnFocus: true
    }
  )

  return {
    ...response,
    quests: response.data?.quests ?? [],
    count: response.data?.meta.count ?? 0
  }
}