import { Center, VStack, Heading, Spinner, Text, Thead, Tr, Th, Tbody, Td, Box, Table as ChakraTable, Button, Flex } from "@chakra-ui/react"
import { IUseSegments } from "@olagg/api-hooks/interfaces/IUseSegments"
import useAdminSegments from "./useAdminSegments"
import Pagination from "@olagg/ui-kit/Pagination"
import { useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { FaPlus } from 'react-icons/fa';
import { OlaggModule, PermissionEnum } from "@olagg/db-types"
import useRoles from "../../features/Roles/hooks/useRoles"

const Segments = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseSegments>({
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
    name: searchParams.get('name') || ''
  })

  const { limit, page } = filter;

  const { segments, count, isLoading } = useAdminSegments({ page, limit });
  const { missingPermission } = useRoles();

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <Center w={'full'} p={4} >
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Segmentos
        </Heading>

        <Flex justifyContent={'flex-end'} w={'100%'} alignItems={'center'}>
          <Button
            display='flex'
            alignSelf='self-end'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.SEGMENTS.create)}
            mr={2}
            isDisabled={missingPermission(OlaggModule.Notification, PermissionEnum.CREATE)}
          >
            Crear segmento
          </Button>
        </Flex>
      
      {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          segments?.length > 0 ? (
            <ChakraTable
              variant="striped"
              colorScheme="olaggPurple"
              fontSize="18px"
              fontWeight="semibold"
            >
              <Thead>
                <Tr>
                  <Th color="white">ID</Th>
                  <Th color="white">Nombre</Th>
                  <Th color="white">Descripción</Th>
                  <Th color="white">Creación</Th>
                </Tr>
              </Thead>
              <Tbody>
                {segments.map(segment => (
                  <Tr key={segment.id}>
                    <Td>{segment.id}</Td>
                    <Td>{segment.name}</Td>
                    <Td>{segment.description}</Td>
                    <Td>{segment.createdAt?.toLocaleString()}</Td>
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>Todavía no hay ningún segmento</Text>
            </Box>
          )
        )}
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center>
  )
}

export default Segments
