import { Flex, Box, Spinner, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useEffect, useState } from "react";
import SurveyForm from "./components/form";
import List from "./components/List";

const Surveys = () => {

    const [tabIndex, setTabIndex] = useState<number>(0)

    return (
        <>
            <Tabs
                index={tabIndex}
                onChange={setTabIndex}
            >
                <TabList
                    margin='0 20px 0px 20px'
                    borderColor='#343847'
                    position='relative'
                    sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important'
                        },
                        overflowX: 'auto !important',
                        overflowY: 'hidden !important',
                    }}
                >
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Encuestas
                    </Tab>
                    <Tab color="white" whiteSpace='nowrap' paddingBottom='4px' _selected={{ fontWeight: '800', borderBottom: '4px solid white', paddingBottom: '2px' }} fontWeight='600'>
                        Cargar una encuesta
                    </Tab>
                </TabList>

                <TabPanels p='0'>
                    <TabPanel p='0'>
                        <List tabIndex={tabIndex} />
                    </TabPanel>
                    <TabPanel p='0'>
                        <SurveyForm />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
};

export default Surveys;
