import { Center, VStack, Heading, Spinner, useToast } from "@chakra-ui/react";
import { AdminTournamentsEndpoints, fetcher, useAdminTournament } from "@olagg/api-hooks";
import { UpdateTournamentData } from "@olagg/db-types";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import TournamentForm from "./Form";

const EditTournament = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { tournament, mutate } = useAdminTournament(id!);
  const toast = useToast();

  const onSubmit = (tournamentData: UpdateTournamentData) =>
    fetcher(AdminTournamentsEndpoints.update(id!, tournamentData))
      .then(() => {
        toast({
          title: 'Torneo',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Torneo actualizado con éxito`,
          position: 'bottom-right'
        });
        mutate();
        navigate(ROUTES.TOURNAMENTS.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })



  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Torneo
        </Heading>
        {tournament ?
          <TournamentForm onSubmit={onSubmit} tournament={tournament} section={searchParams.get('section')} /> :
          <Spinner>
            Cargando...
          </Spinner>}
      </VStack>
    </Center>
  );
}


export default EditTournament;
