import { Button, useToast } from '@chakra-ui/react'
import { AdminMarketPlaceEndpoints, fetcher } from '@olagg/api-hooks'
import {
  MarketPlaceItem,
  MarketPlaceItemType,
  MarketPlaceStockItem,
  OlaggModule,
  PermissionEnum
} from '@olagg/db-types'
import { useState, useRef, useEffect } from 'react'
import { utils, writeFile } from 'xlsx';
import useRoles from '../../features/Roles/hooks/useRoles';
import { slugify } from '../../utils'

interface IProps {
  item: MarketPlaceItem
}

const MarketplaceCoupons = ({ item }: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const toast = useToast()
  const { missingPermission } = useRoles();

  useEffect(() => {
    if (item.type == MarketPlaceItemType.LIMITED) {
      let q = 0
      if (item.availableStock > 0) {
        q = Number(item.availableStock) - Number(item.couponsAvailable)
      }
    }
  }, [])

  const exportCoupons = async () => {
    setIsLoading(true)
    await fetcher(
      AdminMarketPlaceEndpoints.stocks(item.id!)
    ).then((response) => {
      let jsonData: Partial<MarketPlaceStockItem>[] = []
      let item_title = ""
      if (response?.stocks && response?.stocks.length > 0) {
        response.stocks.map((stock: MarketPlaceStockItem) => {
          item_title = stock.item.name
          let obj: { coupon?: string, userId?: number } = {}
          obj.coupon = stock.coupon
          obj.userId = stock.assignedToId
          jsonData.push(obj)
        })
      }

      if (jsonData.length > 0) {
        item_title = slugify(item_title)
        let fileName = `${item_title}-coupons.xlsx`
        const ws = utils.json_to_sheet(jsonData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFile(wb, fileName);
      }

      setIsLoading(false)
    }).catch(e => {
      toast({
        title: 'Ocurrió un error',
        colorScheme: 'olaggPink',
        description: e.message,
        status: 'error',
        position: 'top'
      })
      setIsLoading(false)
    })
  }

  return (
    <>
      <Button
        size={'sm'} isLoading={isLoading} padding='0px 8px' mr={5}
        colorScheme='whiteAlpha' variant='solid' onClick={() => { exportCoupons() }}
        isDisabled={isLoading || missingPermission(OlaggModule.Item, PermissionEnum.EDIT)}
      >
        Exportar cupones
      </Button>
    </>
  )
}

export default MarketplaceCoupons
