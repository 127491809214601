import { Box, Flex, Image } from '@chakra-ui/react'
import { useAuthStore } from '@olagg/store'
import { FC } from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './Navigation/Navigation'
import OnboardingModal from '@olagg/ui-kit/Onboarding/OnboardingModal'

const Layout: FC = () => {
  const { me } = useAuthStore()

  return (
    <>
      <OnboardingModal />
      <Box
        overflow="hidden"
        position="relative"
        backgroundColor="olaggDark.100"
        h="full"
      >
        <Image
          src="https://olagg-p.s3.amazonaws.com/bg-hero-home-pink-top.png"
          opacity="28%"
          position="absolute"
          w="calc(100vw)"
        />
        <Box position="relative" h="full" w="full">
          <Flex w="full">
            {me && <Navigation />}
            <Box
              w="full"
              ml={me && { base: '0px', xl: '215px' }}
              mt={me && { base: '20%', md: '8%', xl: '6%' }}
            >
              <Outlet />
            </Box>
          </Flex>
        </Box>
      </Box>
    </>
  )
}

export default Layout
