import {
    Flex,
    useToast,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminPaymentsEndpoints,
} from '@olagg/api-hooks'
import OlaggFormInput from "../../../components/Forms/OlaggFormInput";

type FormData = {
    link: number,
}

type PaymentParentAttributes = {
    type: string,
    title: string
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        link: Yup.string().matches(/^0x([A-Fa-f0-9]{64})$/, 'El Hash ID no es válido').required('El Hash ID es requerido'),
    })
    .required()

//    


const ModalConfirm = ({ isOpen, onClose, selectedPayment, refresh }: { isOpen: boolean, onClose: () => void, selectedPayment: {}, refresh: () => void }) => {

    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    const onSubmit = async (data) => {        
        try {
            await fetcher(AdminPaymentsEndpoints.update(`${selectedPayment.id}`, { status: 'paid', link: data.link }))
            toast({
                title: 'Pago confirmado',
                colorScheme: 'olaggPink',
                status: 'success',
                description: '',
                position: 'bottom-right'
            })
            closeModal()
            refresh()
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error',
                colorScheme: 'olaggYellow',
                status: 'error',
                description: error.message,
                position: 'bottom-right'
            })
        }
    }

    const closeModal = () => {
        useFormMethods.reset()
        onClose()
    }

    return (

        <Modal isOpen={isOpen} onClose={closeModal} isCentered size='xl'>
            <ModalContent mx='5'>
                <ModalCloseButton />
                <ModalBody textAlign='center' py='8'>
                    <Flex flexDirection={'column'} justifyContent='center' alignItems={'center'} gap='10px'>
                        <Flex fontSize={'16px'} fontWeight='700'>
                            Confirmar pago
                        </Flex>
                        <Flex fontSize={'14px'} fontWeight='400'>
                            Para confirmar el pago por favor pegar el Hash ID de pago
                        </Flex>

                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
                                <OlaggFormInput
                                    controlName='link'
                                    placeholder='Pegar Hash ID de pago'
                                    customStyles={{
                                        marginTop: '10px',
                                        fontWeight: '700',
                                    }}
                                />

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    type="submit"
                                    w={'full'}
                                >
                                    Continuar
                                </Button>
                            </form>
                        </FormProvider>

                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
};

export default ModalConfirm;
