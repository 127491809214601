import { useState } from 'react';
import { Box, Flex, Heading, useToast, Input, FormControl, FormLabel, Button, FormErrorMessage } from '@chakra-ui/react';
import { useParams, useNavigate, generatePath } from 'react-router-dom';
import { FormTokenAssignmentBatchData, formTokenAssignmentBatchSchema } from '@olagg/validation-schemas/token.schemas';
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { AdminAssignmentEndpoints, fetcher } from '@olagg/api-hooks';
import { ROUTES } from '../../../consts/routes';

const TokenAddWhitelistedUsers = () => {
  const params = useParams();
  const toast = useToast();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { handleSubmit, register, formState: { errors } } = useForm<FormTokenAssignmentBatchData>({
    resolver: yupResolver(formTokenAssignmentBatchSchema),
    mode: 'onChange',
  });

  const onSubmit = (dataForm: FormTokenAssignmentBatchData) => {
    setIsLoading(true);
    const body = new FormData();
    body.append('file', dataForm.file[0]);

    fetcher(AdminAssignmentEndpoints.createBatch(params.id!, body))
      .then((res: any) => {
        toast({
          title: 'Whitelist',
          colorScheme: 'olaggPink',
          status: 'success',
          description: res.message,
          position: 'bottom-right'
        });
        navigate(generatePath(ROUTES.TOKENS.whitelist, { tokenId: params.id }))
      })
      .catch((error: any) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (!params.id) navigate(ROUTES.TOKENS.all);

  return (
    <Flex color="white" w='full' justifyContent='center' px='5'>
      <Flex direction='column' w='full' maxW='800px' mb={4}>
        <Heading textAlign="center" my={2} mb='8'>
          Agregar usuarios a la whitelist
        </Heading>
        <caption>Carga masiva</caption>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
          <FormControl
            id="file"
            isInvalid={errors.file ? true : false}
          >
            <FormLabel>
              Seleccionar archivo .xlsx
            </FormLabel>
            <Input
              type='file'
              {...register('file')}
            />
            <FormErrorMessage>
              {errors.file && errors.file.message?.toString()}
            </FormErrorMessage>
          </FormControl>

          <Box textAlign='left' w='100%' mt='5'>
            <a href={`https://${import.meta.env.VITE_APP_ENV === 'staging' ? 'staging.' : ''}olagg.io/downloads/whitelist.xlsx`}>
              <Button
                variant="filledGradient"
                flex="1"
                size={'sm'} py='5px' mt='5px'
              >
                Descargar archivo de ejemplo
              </Button>
            </a>
          </Box>

          <Button
            variant='filledGradient'
            type='submit'
            mt='10'
            w='full'
            isLoading={isLoading}
          >
            Cargar
          </Button>
        </form>
      </Flex>
    </Flex>
  )
}

export default TokenAddWhitelistedUsers;
