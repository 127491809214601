import { Center, Heading, VStack, Button, Flex, Box, Spinner, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { useAdminRolesPermissions, useAdminRoles } from '@olagg/api-hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../consts/routes';
import { FaPlus } from 'react-icons/fa';
import Table from './Table';
import { useState, useEffect } from 'react';
import { RolePermission } from '@olagg/db-types';
import { IoClose } from 'react-icons/io5';

const RolesPermissions = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>('');
  const [filteredPermissions, setFilteredPermissions] = useState<RolePermission[]>([]);

  const { data: rolesData, isLoading: rolesIsLoading } = useAdminRoles({ page: 1, limit: 100 })
  const roles = rolesData?.roles;
  const { data: permissionsData, isLoading: permissionsIsLoading, updateRolesPermission, createRolesPermission } = useAdminRolesPermissions({ page: 1, limit: 100 })
  const permissions = permissionsData?.permissions;

  useEffect(() => {
    setFilteredPermissions(permissions?.filter(
      permission => permission.moduleName.toLowerCase().includes(filter.toLowerCase())
    ) || []);
  }, [, filter, permissions]);
   
  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Permisos de Roles
        </Heading>

        <Flex w='full' justifyContent='space-between' paddingRight="22px">
          <Button
            display='flex'
            alignSelf='self-start'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.ROLES_PERMISSIONS.create)}
          >
            Añadir Ruta/Permiso
          </Button>
        </Flex>

        <InputGroup
          maxW='250px'
          alignSelf='flex-start'
        >
          <Input
            placeholder='Buscar módulo'
            value={filter}
            onChange={(e) => setFilter(e.target.value)}         
          />
          <InputRightElement onClick={() => setFilter('')} cursor='pointer'>
            {filter !== '' && <IoClose color='white' />}
          </InputRightElement>
        </InputGroup>

        {(rolesIsLoading || permissionsIsLoading) ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          filteredPermissions && <Table
            roles={roles}
            permissions={filteredPermissions}
            createRolesPermission={createRolesPermission}
            updateRolesPermission={updateRolesPermission}
          />
        )}
      </VStack >
    </Center >
  )
};

export default RolesPermissions;
