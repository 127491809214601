import { Center, VStack, Heading, Button, filter, Input, InputGroup, InputRightElement, Flex, Box, Spinner } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, TokenDrop } from "@olagg/db-types";
import Pagination from "@olagg/ui-kit/Pagination";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import useRoles from "../../features/Roles/hooks/useRoles";
import Table from "./Table";
import useAdminTokenDrops from "./useTokenDrops";

const TokenDrops = () => {
    const [tokenDrops, setTokenDrops] = useState<TokenDrop[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const [filter, setFilter] = useState({
        title: searchParams.get('title') || undefined,
        page: Number(searchParams.get('page') || 1),
        limit: Number(searchParams.get('limit') || 10),
        sortByStartDate: searchParams.get('sortByStartDate') as 'asc' | 'desc' || 'desc'
    })
    const { fetchTokenDrops } = useAdminTokenDrops();
    const navigate = useNavigate();
    const { missingPermission } = useRoles();
    const { page, limit, title } = filter

    const onFilter = ({
        name,
        value
    }: { name: string, value: any }) => {
        setFilter({ ...filter, [name]: value })

        if (value) searchParams.set(name, value)
        else searchParams.delete(name)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        setLoading(true);
        fetchTokenDrops({ page, limit, title }).then((response) => {
            setTokenDrops(response.tokenDrops);
        }).finally(() => {
            setLoading(false);
        });
    }, [filter]);

    const filterTitle = (title: string) => setFilter({ ...filter, title });

    return (
        <>
            <Center w={'full'} pb={4}>
                <VStack color={'white'} w="full" >
                    <Heading my={2}>
                        Tokens
                    </Heading>
                    <Flex w='full' justifyContent='space-between' paddingRight="22px" >
                        <InputGroup
                            maxW='250px'
                            alignSelf='flex-start'
                        >
                            <Input
                                placeholder='Buscar token drop'
                                value={filter.title}
                                onChange={(e) => filterTitle(e.target.value)}
                            />
                            <InputRightElement onClick={() => setFilter({ ...filter, title: undefined })} cursor='pointer'>
                                {filter.title !== '' && <IoClose color='white' />}
                            </InputRightElement>
                        </InputGroup>

                        <Button
                            display='flex'
                            alignSelf='self-start'
                            variant="outlinedGradient"
                            leftIcon={<FaPlus />}
                            onClick={() => navigate(ROUTES.TOKEN_DROPS.create)}
                            isDisabled={missingPermission(OlaggModule.TokenDrop, PermissionEnum.CREATE)}
                        >
                            Agregar Item
                        </Button>
                    </Flex>
                </VStack>

            </Center>
            {loading && <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
                <Spinner />
            </Box>}

            {!loading && (
                <Table
                    tokenDrops={tokenDrops}
                />

            )}
            <Pagination
                params={{ limit, page }}
                onClick={page =>
                    onFilter({
                        name: 'page',
                        value: String(page + 1)
                    } as EventTarget & HTMLInputElement)
                }
                onLimitChange={val =>
                    onFilter({
                        name: 'limit',
                        value: val
                    } as EventTarget & HTMLInputElement)
                }
                hideLimitInput
            />
        </>
    )
};

export default TokenDrops;
