import type { InferType } from 'yup';
import * as Yup from 'yup';

export const itemSchema = Yup.object({
    name: Yup.string().required('El título es obligatorio'),
    description: Yup.string().required('Descripción es obligatoria'),
    type: Yup.string().required('Tipo de Item es obligatorio'),
    level: Yup.number(),
    secretWord: Yup.string().nullable(),
    image: Yup.string().nullable(),
    dueDate: Yup.string().optional(),
    visible: Yup.boolean().nullable(),
    callback: Yup.string().nullable(),
    link: Yup.string().nullable(),
    extraData: Yup.object({
        translated: Yup.boolean().nullable(),
    }),
    code: Yup.string().nullable(),
    file: Yup.mixed<File>(),
})

export type ItemData = InferType<typeof itemSchema>;
