const Logo = ({
  height = '55px',
  width = '48px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 48 55`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.21581 7.69035C12.5115 5.37883 16.7109 2.88731 20.9681 0.5C25.2205 2.88257 29.4151 5.36935 33.706 7.68561C36.4414 7.82771 39.1912 7.69509 41.9363 7.74245C40.6408 10.1724 39.0131 12.4413 37.8573 14.9281C37.7754 19.6885 37.8476 24.4536 37.8235 29.2188C33.5904 31.644 29.2706 33.9508 25.1194 36.5039C23.6698 38.7917 22.3599 41.1743 20.9681 43.5C19.7979 41.4774 18.5891 39.4785 17.4285 37.4559C17.1203 36.9681 16.8699 36.3902 16.3112 36.1344C12.2515 33.8229 8.19173 31.5019 4.11272 29.214C4.1079 24.6147 4.12235 20.0153 4.11272 15.416C4.18496 14.7291 3.7419 14.1655 3.43369 13.597C2.27789 11.655 1.11727 9.71293 0 7.74245C2.74021 7.69982 5.48523 7.82772 8.21581 7.69035ZM15.2277 7.75193C19.037 7.72824 22.8463 7.75193 26.6556 7.74245C24.7919 6.6009 22.8608 5.56356 20.9681 4.46938C19.0562 5.5683 17.1251 6.63406 15.2277 7.75193ZM6.02461 11.1718C11.0138 19.6458 15.9645 28.1388 20.9681 36.6033C25.9718 28.1388 30.9273 19.6458 35.9117 11.1718C25.9477 11.1766 15.9886 11.1766 6.02461 11.1718ZM7.59938 20.7306C7.6042 22.8858 7.61383 25.0362 7.59457 27.1915C9.49682 28.3141 11.4328 29.3798 13.3447 30.4835C11.3991 27.2483 9.53053 23.9752 7.59938 20.7306ZM28.6109 30.4977C30.4987 29.3561 32.4491 28.3188 34.3417 27.1915C34.3224 25.041 34.3321 22.8858 34.3369 20.7353C32.4154 23.98 30.5131 27.2388 28.6109 30.4977Z"
        fill={color}
      />
      <path
        d="M8.00391 12.2613C13.0942 12.2234 18.1894 12.2518 23.2797 12.2471C21.5171 15.2502 19.769 18.2675 17.9775 21.2611C16.4075 21.2327 14.8376 21.2421 13.2676 21.2469C11.5195 18.2485 9.74242 15.2644 8.00391 12.2613ZM14.0767 15.5581C14.5727 16.4249 15.0735 17.2917 15.6418 18.1112C16.2053 17.2917 16.7061 16.4296 17.1973 15.5675C16.1571 15.5581 15.1169 15.5533 14.0767 15.5581Z"
        fill={color}
      />
      <path
        d="M24.4788 12.2376C27.6284 12.2566 30.7828 12.2187 33.9323 12.2613C31.7893 15.9702 29.5788 19.6458 27.4406 23.3547C26.0584 23.3831 24.6763 23.3784 23.299 23.3642C23.9298 22.2463 24.604 21.1427 25.2108 20.0106C24.9267 20.0343 24.3536 20.0816 24.0695 20.1053C22.9185 21.7442 22.0565 23.5584 20.9681 25.2352C20.265 24.07 19.5715 22.9 18.9021 21.7158C20.7995 18.5801 22.6296 15.4017 24.4788 12.2376ZM25.6298 17.3675C27.0023 17.7654 27.3346 16.3822 27.8547 15.4917C26.4967 15.1554 26.1307 16.4675 25.6298 17.3675Z"
        fill={color}
      />
      <path
        d="M13.8407 22.1942C15.2084 22.1895 16.5761 22.1895 17.9486 22.1847C18.9744 23.8805 19.9568 25.5952 20.9681 27.3004C21.5605 26.3057 22.148 25.3062 22.7452 24.3115C24.1081 24.321 25.4757 24.3162 26.8434 24.3305C24.8978 27.6793 22.933 31.014 20.9681 34.3534C18.5795 30.3035 16.2053 26.2488 13.8407 22.1942Z"
        fill={color}
      />
    </svg>
  )
}

export { Logo }
export default Logo
