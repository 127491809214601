import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Alert, OlaggModule, PermissionEnum } from "@olagg/db-types";
import { Table as ChakraTable } from "@chakra-ui/react";
import { generatePath, useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { typeOptions } from './constants';
import useRoles from "../../features/Roles/hooks/useRoles";

const Table = ({
  alerts,
  deleteAlert,
}: { 
  alerts: Alert[],
  deleteAlert: (id: number) => Promise<void>,
}) => {
  const navigate = useNavigate();
  const { missingPermission } = useRoles();

  const editAlert = (id: number) => navigate(generatePath(ROUTES.ALERTS.update, { id }))

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white" maxW='200px'>Título</Th>
            <Th color="white">Mensage</Th>
            <Th color="white">Tipo</Th>
            <Th color="white">Expira en</Th>
            <Th color="white">Visible</Th>
            <Th color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {alerts && alerts.map((alert, index) => (
            <Tr
              key={index} _hover={{ color: 'cyan' }}
              onClick={() => editAlert(alert.id)} cursor='pointer'
            >
              <Td>{alert.title}</Td>
              <Td>{alert.message}</Td>
              <Td>{typeOptions.find(val => val.value === alert.type)?.label}</Td>
              <Td>{new Date(alert.expirationDate).toDateString()}</Td>
              <Td>{alert.visible ? 'Si' : 'No'}</Td>
              <Td textAlign='center'>
                <Flex gap='3'>
                  <Button size={'sm'} padding='0px 8px' colorScheme='green'
                    onClick={(e) => { e.stopPropagation(); editAlert(alert.id); }}
                    isDisabled={missingPermission(OlaggModule.Alert, PermissionEnum.EDIT)}
                  >
                    Editar
                  </Button>
                        
                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        onClick={(e) => e.stopPropagation()}
                        isDisabled={missingPermission(OlaggModule.Alert, PermissionEnum.DELETE)}
                      >
                        Eliminar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red'
                            onClick={(e) => { e.stopPropagation(); deleteAlert(alert.id); }}
                          >
                            Confirmar Eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table
