import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Text } from "@chakra-ui/react";
import { User } from "@olagg/db-types";
import { useRolesStore } from "@olagg/store";

const AddRoleModal = ({
  isOpen,
  onClose,
  user,
  onChange,
} : {
  isOpen: boolean,
  onClose: () => void,
  user: User | undefined
  onChange: (role: string, user: User) => void,
}) => {
  const { roles } = useRolesStore();

  const rolesOptions = [
    'Seleccionar Rol',
    ...roles.filter(role => !user?.roles?.includes(role.name)).map(role => role.name)
  ];

  if (!user) return null;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value, user);
    onClose();
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size='xl'>
      <ModalOverlay />
      <ModalContent mx='5'>
        <ModalHeader>
            Agregar Rol a {user.name}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign='center' py='8'>
          <Select onChange={handleChange}>
            {rolesOptions.map(role => (
              <option value={role}>
                {role}
              </option>
            ))}
          </Select>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export { AddRoleModal };
