import Network from '@olagg/web3/dist/domain/entities/vo/network.vo';
import { ClassificationType } from '@olagg/web3/dist/domain/entities/vo/classification.vo';
import MintOwner from '@olagg/web3/dist/domain/entities/vo/mintOwner.vo';
import Section from '@olagg/web3/dist/domain/entities/vo/section.vo';
import TokenType from '@olagg/web3/dist/domain/entities/vo/tokenType.vo';
import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formTokenSchema = Yup.object({
  type: Yup
    .object({
      value: Yup
        .mixed<TokenType>()
        .oneOf(Object.values(TokenType))
        .required('Elige el tipo de token'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige el tipo de token'),
  tokenId: Yup
    .number()
    .typeError('El Id debe ser un número mayor o igual a cero')
    .when('type', {
      is: TokenType.ERC1155,
      then: schema => schema
        .integer()
        .moreThan(-1, 'El Id debe ser mayor o igual a cero')
        .required('El campo Id es obligatorio'),
      otherwise: schema => schema
        .notRequired()
        .transform((_, val) => Number(val) || 0),
    }),
  network: Yup
    .object({
      value: Yup
        .mixed<Network>()
        .oneOf(Object.values(Network))
        .required('Selecciona una blockchain'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Selecciona una blockchain'),
  section: Yup
  .object({
    value: Yup
      .mixed<Section>()
      .oneOf(Object.values(Section))
      .required('Elige una sección'),
    label: Yup
      .string()
      .required('Opcion inválida'),
  })
  .required('Elige una sección'),
  requiredLevel: Yup
    .number()
    .integer()
    .positive('El Level mínimo es 1')
    .typeError('El Level debe ser un numero entero y mayor a 0')
    .transform((_, val) => (val === '' || val === null) ? null : Number(val))
    .nullable(),
  address: Yup
    .string()
    .trim()
    // If we support non-EVM blockchain in the future, we'll need to add their regex
    .matches(/^(0x)?[0-9a-fA-F]{40}$/, 'Introduce una direccion de contrato válida')
    .required('Introduce la dirección del contrato del token'),
  classificationClassificationType: Yup
    .object({
      value: Yup
        .mixed<ClassificationType>()
        .oneOf(Object.values(ClassificationType))
        .required('Elige una clasificación'),      
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige una clasificación'),
  maxTokensPerWallet: Yup
    .number()
    .typeError('La cantidad debe ser un numero entero y mayor a 0')
    .when('type', {
      is: TokenType.ERC1155,
      then: schema => schema
        .integer()
        .moreThan(0, 'La cantidad debe ser mayor a cero')
        .required('La cantidad es obligatoria'),
      otherwise: schema => schema
        .notRequired()
        .transform((_, val) => Number(val) || 1),
    }),
  order: Yup
    .number()
    .typeError('El orden debe ser un numero entero y mayor o igual a 0')
    .required(),
  mintOwner: Yup
    .object({
      value: Yup
        .mixed<MintOwner>()
        .oneOf(Object.values(MintOwner))
        .required('Elige una opción'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige una opción'), 
  mintSite: Yup
    .string()
    .when('mintOwner', {
      is: MintOwner.THIRD_PARTY,
      then: schema => schema
        .url('La URL debe ser valida y comenzar con http:// o https://')
        .optional(),
      otherwise: schema => schema
        .notRequired()
    }),
  mintClaimPhases: Yup
    .boolean()
    .default(false),
  mintSnapshotRequired: Yup
    .boolean()
    .default(false),
  mintRequirements: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  enabledAt: Yup
    .date()
    .default(null)
    .transform((_, val) => (val === '' || val === null) ? null : new Date(val))
    .nullable(),
  gasslessRelayer: Yup
    .string()
    .url('La URL debe ser valida y comenzar con http:// o https://')
    .optional(),
  valuePrice: Yup
    .number()
    .min(0, 'El precio debe ser mayor o igual a 0')
    .typeError('El precio debe ser un numero y mayor o igual a 0')
    .default(0)
    .optional()
    .transform((_, val) => val === '' ? 0 : Number(val)),
  valueCurrency: Yup
    .string()
    .transform((_, val) => (val === '' || val === null) ? null : val)
    .nullable(),
  visible: Yup
    .boolean()
    .default(false)
    .optional(),
  })
.required();

export const formTokenAssignmentBatchSchema = Yup
  .object().shape({
    file: Yup
      .mixed<FileList>()
      .required()
      .test('fileSize', 'Seleccione un archivo', (value) => value && value[0]?.size > 0)
  })
.required()

export type FormTokenData = InferType<typeof formTokenSchema>;
export type FormTokenAssignmentBatchData = InferType<typeof formTokenAssignmentBatchSchema>;
