import type {
  ApplicationStatus,
  ApplicationStatusUpdate,
  ScholarshipApplication
} from '@olagg/db-types'

export const getLatestStatus = ({
  statusHistory
}: ScholarshipApplication & {
  statusHistory: ApplicationStatusUpdate[]
}) => statusHistory[statusHistory.length - 1].status

export const mapScholarshipStatusToSpanish = (
  status: ApplicationStatus
) => {
  switch (status) {
    case 'APPROVED':
      return 'Aprobada'
    case 'REJECTED':
      return 'Rechazada'
    case 'SUSPENDED':
      return 'Suspendida'
    default:
      return 'Pendiente'
  }
}

export const mapHistoryOperationoSpanish = (
  operation: string
) => {
  switch (operation) {
    case 'CREATE':
      return 'Creación'
    case 'REASSIGN':
      return 'Reasignación'
    case 'ASSIGN':
      return 'Asignación'
    case 'UNASSIGN':
      return 'Desasignación'
    case 'SUSPEND_REASSIGN':
      return 'Suspención y reasignación'
    case 'SCHOLAR_FILLED_IN_FIELDS':
      return 'Actualización por parte del becado'
    case 'UPDATE':
      return 'Actualización por parte del ADMIN'

    default:
      return operation
  }
}

export const normalizeDate = (date: Date) =>
  new Date(date).toLocaleString('es-AR')

export const shortenString = ({
  string,
  characters
}: {
  string: string
  characters: number
}) => {
  if (string?.length > characters)
    return string.split('').slice(0, characters).join('') + '...'
  return string
}

export const getObjDiff = ({
  obj1,
  obj2
}: {
  obj1: object
  obj2: object
}) => {
  const diff = Object.keys(obj2).reduce((diff, key) => {
    if (key === 'userRequired' || key === 'blank') {
      // @ts-ignore
      const changes = [...(obj2[key] || [])].filter(
        // @ts-ignore
        item => !(obj1[key] || []).includes(item)
      )

      if (changes.length) {
        return {
          ...diff,
          [key]: changes
        }
      } else {
        return diff
      }
    }

    if ((obj1 as any)[key] === (obj2 as any)[key]) return diff

    return {
      ...diff,
      [key]: (obj2 as any)[key]
    }
  }, {})

  return diff
}

export const toggleAscDesc = (value: 'asc' | 'desc' | '') => {
  if (value === 'asc') return 'desc'
  if (value === 'desc') return ''
  if (value === '') return 'asc'
}

export function convertUTCToLocalISOString(utcDateString: string): string {
  if (!utcDateString) return "";
  if (!utcDateString.endsWith('Z')) utcDateString += 'Z'; // input value must be in UTC
  
  const utcDate = new Date(utcDateString);
  
  // This is the local timezone offset (vs UTC) of the current user (in minutes)
  const timezoneOffsetMinutes = utcDate.getTimezoneOffset();

  // Apply the timezone offset to get the local time
  const localTime = new Date(utcDate.getTime() - timezoneOffsetMinutes * 60000);
 
  // Return the local time in ISO-format string
  return localTime.toISOString();
}

export const toLocalTimezone = (input: any) => {
  return convertUTCToLocalISOString(input).slice(0, 16) as unknown as Date;
}

export const slugify = (str: string) => {
  return String(str)
    .normalize('NFKD') // split accented characters into their base characters and diacritical marks
    .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
    .trim() // trim leading or trailing whitespace
    .toLowerCase() // convert to lowercase
    .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
    .replace(/\s+/g, '-') // replace spaces with hyphens
    .replace(/-+/g, '-'); // remove consecutive hyphens
}

export const getKeyByValue = (enumObject: any, value: string): string | undefined => {
  return Object.keys(enumObject).find(key => enumObject[key as keyof typeof enumObject] === value);
}
