import { Box, Button, Center, Flex, Heading, Spinner, Text, VStack } from "@chakra-ui/react"
import { OlaggModule, PermissionEnum } from '@olagg/db-types';
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

import { FaPlus } from 'react-icons/fa';
import { IUseTreasuresGame } from "@olagg/api-hooks/interfaces/IUseTreasuresGame";
import Pagination from '@olagg/ui-kit/Pagination';
import { ROUTES } from '../../consts/routes';
import Table from "./Table";
import { useMiniGames } from "./hooks/useMiniGames";
import useRoles from '../../features/Roles/hooks/useRoles';

const MiniGames = () => {
  const navigate = useNavigate();
  const { missingPermission } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseTreasuresGame>({
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
  })
  const { page, limit } = filter

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  useEffect(() => {
    all(filter)
  }, [filter])

  const {all, deleteMiniGame, isLoading, miniGames, count} = useMiniGames()

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading>
          Mini Juegos
        </Heading>

        <Flex w='full' alignItems='center' gap='5'>
          <Button
            mt='2' mb='5'
            display='flex'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.MINI_GAMES.create)}
            isDisabled={missingPermission(OlaggModule.MiniGame, PermissionEnum.CREATE)}
          >
            Agregar Mini Juego
          </Button>
        </Flex>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          miniGames?.length > 0 ? (
            <Table miniGames={miniGames} deleteMiniGame={deleteMiniGame} />
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>No hay mini juegos cargados</Text>
            </Box>
          )
        )}

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center>
  )
}

export default MiniGames