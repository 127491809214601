import { useAuthStore } from '@olagg/store'
import { Role, RolePermission, PermissionEnum, OlaggModule } from "@olagg/db-types";
import { useRolesStore } from "@olagg/store";
export type { Role, RolePermission, PermissionEnum };
import { getKeyByValue } from '../../../utils';

export default function useRoles() {
  const { me } = useAuthStore();
  const { roles, permissions } = useRolesStore();

  // Returns all the allowed roles for a given module (to be used with RequireAuth component)
  const rolesForModule = (module: OlaggModule, requiredPermission?: PermissionEnum): string[] => {
    const filteredRoles = permissions?.filter(permission =>
      permission.moduleName.toLowerCase() === getKeyByValue(OlaggModule, module)?.toLowerCase() &&
      permission.permission >= (requiredPermission ?? PermissionEnum.VIEW)
    ).map(permission => permission.role.name.toUpperCase());

    return [...new Set(filteredRoles)];
  }

  // Evaluates allowance of the current user for a given module and a required permission
  const missingPermission = (module: OlaggModule, requiredPermission: PermissionEnum): boolean => {
    let permissionCount = 0;
    me?.roles.forEach(userRole => {
      permissionCount += permissions?.filter(permission => 
        permission.role.name.toUpperCase() === userRole &&
        permission.moduleName.toLowerCase() === getKeyByValue(OlaggModule, module)?.toLowerCase() &&
        permission.permission >= requiredPermission
      ).length;
    });

    return permissionCount === 0;
  }

  // Evaluates allowance of the current user for a given module and a required permission
  const hasPermission = (module: OlaggModule, requiredPermission: PermissionEnum): boolean => {
    return !missingPermission(module, requiredPermission);
  }

  return {
    roles,
    permissions,
    rolesForModule,
    missingPermission,
    hasPermission
  }
}
