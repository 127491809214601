import { Button, Input, Td, Tr } from '@chakra-ui/react';
import { RouletteValue } from '@olagg/db-types';
import { useState } from 'react';

export default function Row({
  value,
  update
}: {
  value: RouletteValue,
  update: (id: number, data: RouletteValue) => void
}) {
  const [rouletteValue, setRouletteValue] = useState<RouletteValue>(value);
  const [dirty, setDirty] = useState<boolean>(false);

  return (
    <Tr>
      <Td>{rouletteValue.position}</Td>
      <Td>
        <Input
          bgColor='whiteAlpha.300' maxW='100' border='2px solid white' value={rouletteValue.value}
          onChange={(e) => {setDirty(true); setRouletteValue((prev) => ({...prev, value: Number(e.target.value) || 0}))}}
        >
        </Input>
      </Td>
      <Td>
        <Input
          bgColor='whiteAlpha.300' maxW='100' border='2px solid white' value={rouletteValue.probability}
          onChange={(e) => {setDirty(true); setRouletteValue((prev) => ({...prev, probability: Number(e.target.value) || 0}))}}
        >
        </Input>
      </Td>
      <Td>
        <Button
          size='sm' padding='0px 8px' colorScheme='green'
          isDisabled={!dirty}
          onClick={() => {setDirty(false); update(rouletteValue.id, rouletteValue)}}
        >
          Actualizar
        </Button>
      </Td>
    </Tr>
  )
}
