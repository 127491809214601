import { Box, Heading, VStack } from "@chakra-ui/react"
import { useAdminRolesPermissions } from "@olagg/api-hooks";
import type { CreateRolePermission } from "@olagg/db-types";
import { useNavigate } from "react-router-dom";
import RolesPermissionsForm, { FormRolesPermissionsData } from "./Form"
import { ROUTES } from "../../../consts/routes";

const CreateRolesPermission = () => {
  const navigate = useNavigate();
  const { createRolesPermission } = useAdminRolesPermissions({ page: 1, limit: 10 });

  const onSubmit = (dataForm: FormRolesPermissionsData) => {
    const rolesData: Partial<CreateRolePermission> = {
      ...dataForm,
      role_id: dataForm.role.value,
      moduleName: dataForm.moduleName.value,
      permission: dataForm.permission.value
    };
    createRolesPermission(rolesData, () => navigate(ROUTES.ROLES_PERMISSIONS.all));
  }

  return (
    <Box w={'full'} pb={4} minH='600px'>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Permiso de Rol
        </Heading>
        <RolesPermissionsForm onSubmit={onSubmit}/>
      </VStack>
    </Box>
  )
}

export default CreateRolesPermission
