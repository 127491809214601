import { Center, VStack, Heading, Box, Spinner, Flex, Switch, Button, Input } from "@chakra-ui/react"
import { MarketPlaceItem, MarketPlaceItemType } from "@olagg/db-types"
import { ItemData, itemSchema } from "@olagg/validation-schemas"
import { useCallback, useEffect, useState } from "react"
import OlaggForm from "../../components/Forms/OlaggForm"
import OlaggFormInput from "../../components/Forms/OlaggFormInput"
import OlaggFormSelect from "../../components/Forms/OlaggFormSelect"
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import moment from "moment";

const typeText = (s: string) => {
    if (s == MarketPlaceItemType.LOTTERY) {
        return 'Sorteo'
    }
    if (s == MarketPlaceItemType.LIMITED) {
        return 'Cupos limitados'
    }
    if (s == MarketPlaceItemType.UNLIMITED) {
        return 'Cupos ilimitados'
    }
}

const ItemForm = ({ item, onSubmit }: { item?: MarketPlaceItem, onSubmit: (e: FormData) => Promise<void> }) => {
    const [selectedType, setSelectedType] = useState(MarketPlaceItemType.LIMITED)
    const [otherLangs, setOtherLangs] = useState<boolean>(item?.extraData?.translated || false)
    const [isLoading, setIsLoading] = useState(false)
    const [toggleStockFile, setToggleStockFile] = useState(item?.type == MarketPlaceItemType.LIMITED)
    const [selectedLevel, setSelectedLevel] = useState<string | number | null>(null)
    const setType = (value: string) => {
        setSelectedType(value as MarketPlaceItemType)
        setToggleStockFile(value == MarketPlaceItemType.LIMITED)
    }

    const useFormMethods = useForm<ItemData>({
        resolver: yupResolver(itemSchema),
        mode: 'onChange',
        defaultValues: item
    });

    const typeOptions = () => Object.values(MarketPlaceItemType).map(s => ({
        value: s,
        label: typeText(s)
    }))

    const initRequest = useCallback(async () => {
        if (item?.dueDate) {
            let dueDate = moment.utc(item.dueDate).format('YYYY-MM-DD')
            useFormMethods.setValue('dueDate', dueDate)
        }
    }, [])

    useEffect(() => { initRequest() }, [])

    const handleFileChange = (e: any) => useFormMethods.setValue('file', e.target.files);

    const handleSubmit = async (data: ItemData) => {
        setIsLoading(true)
        const formData = new FormData()
        for (const key in data) {
            if (key == 'extraData') {
                for (const lang in data.extraData) {
                    // @ts-ignore
                    for (const subKey in data.extraData[lang]) {
                        // @ts-ignore
                        formData.append(`item[extraData][${lang}][${subKey}]`, data.extraData[lang][subKey])
                    }
                }
            } else {
                // @ts-ignore
                formData.append(`item[${key}]`, data[key])
            }
        }
        // @ts-ignore
        if (data.file?.[0]) formData.append('file', data.file[0]);
        if (data.code) formData.append('code', data.code)
        if (otherLangs) formData.append('item[extraData][translated]', 'true')
        onSubmit(formData).finally(() => setIsLoading(false))
    }

    return (
        <Center w={'full'} pb={4}>
            <VStack color={'white'} w={'full'}>
                <Heading my={2}>
                    {item ? 'Editar' : 'Crear'} Item de la Tienda
                </Heading>

                {isLoading ? (
                    <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
                        <Spinner />
                    </Box>
                ) : (
                    <OlaggForm
                        useFormMethods={useFormMethods}
                        onSubmit={handleSubmit}
                        style={{ width: '100%' }}
                    >
                        <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                            <Flex
                                w="full"
                                justify="start"
                                align="center"
                                style={{ marginTop: '30px' }}
                            >
                                <label
                                    htmlFor="enableShowLocale"
                                    style={{ cursor: 'pointer' }}
                                >
                                    Otros idiomas
                                </label>
                                <Switch
                                    id="enableShowLocale"
                                    colorScheme="purple"
                                    isChecked={otherLangs}
                                    size="md"
                                    ml={2}
                                    onChange={() => setOtherLangs(prev => !prev)}
                                />
                            </Flex>
                            <OlaggFormInput
                                controlName='name'
                                label="Título"
                            />
                            {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                                <OlaggFormInput
                                    label='Título en Inglés'
                                    controlName='extraData.en.name'
                                />

                                <OlaggFormInput
                                    label='Título en Portugués'
                                    controlName='extraData.pt.name'
                                />
                            </Flex>}

                            <OlaggFormSelect
                                controlName='type'
                                label='Tipo'
                                staticOptions={typeOptions()}
                                onChange={setType}
                                newVersion
                            />

                            {selectedType === MarketPlaceItemType.UNLIMITED && (
                                <OlaggFormInput
                                    label='Código'
                                    controlName='code'
                                />
                            )}

                            <OlaggFormInput
                                controlName='description'
                                label='Descripción / Instrucciones de canj'
                            />

                            {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                                <OlaggFormInput
                                    label='Descripción en Inglés'
                                    controlName='extraData.en.description'
                                />

                                <OlaggFormInput
                                    label='Descripción en Portugués'
                                    controlName='extraData.pt.description'
                                />
                            </Flex>}

                            <OlaggFormInput
                                controlName='level'
                                label='Level'
                                onChange={(e) => {
                                    let val = e.target.value == '' ? null : e.target.value
                                    setSelectedLevel(val)
                                }}
                                required
                            />

                            {selectedLevel != null && selectedLevel != 0 && (
                                <OlaggFormInput
                                    controlName='secretWord'
                                    label='Palabra secreta'
                                />
                            )}

                            <OlaggFormInput
                                controlName='image'
                                label='Link de la imagen'
                                required
                            />

                            {otherLangs && <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'} mb={4} >
                                <OlaggFormInput
                                    label='Link de la imagen en Inglés'
                                    controlName='extraData.en.image'
                                />
                                <OlaggFormInput
                                    label='Link de la imagen en Portugués'
                                    controlName='extraData.pt.image'
                                />
                            </Flex>}

                            <OlaggFormInput
                                controlName='callback'
                                label='Link "Más info" (opcional)'
                            />

                            <OlaggFormInput
                                controlName='dueDate'
                                label='Fecha de vencimiento (Opcional)'
                            />

                            <OlaggFormInput
                                controlName='link'
                                label='Link de canje (opcional - Ej.: https://www.google.com)'
                            />

                            <OlaggFormInput
                                inputType='checkbox'
                                controlName='visible'
                                label='Visible'
                            />

                            <Button
                                variant={'filledGradient'}
                                isLoading={isLoading}
                                disabled={isLoading}
                                type="submit"
                                w={'full'}
                                style={{
                                    marginTop: '30px'
                                }}
                            >
                                Enviar
                            </Button>
                        </VStack>
                        {toggleStockFile && (
                            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                                <Box textAlign='left' w='100%' mt={4}>
                                    <Input
                                        name="file"
                                        type="file"
                                        id="file"
                                        onChange={handleFileChange}
                                    />
                                </Box>

                                <Box textAlign='left' w='100%'>
                                    <a href='https://olagg.io/downloads/codigos.xlsx'>
                                        <Button
                                            variant="filledGradient"
                                            flex="1"
                                            size={'sm'} py='5px' mt='5px'
                                        >
                                            Descargar archivo de ejemplo
                                        </Button>
                                    </a>
                                </Box>

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                >
                                    Subir códigos
                                </Button>
                            </VStack>
                        )}
                    </OlaggForm>
                )}
            </VStack>
        </Center>
    )
}

export default ItemForm;
