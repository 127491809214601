import { VStack, Box, Button } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { TokenDrop } from '@olagg/db-types';

import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import OlaggForm from '../../components/Forms/OlaggForm';
import OlaggFormInput from '../../components/Forms/OlaggFormInput';

export const formTokenDropSchema = Yup.object({
    slug: Yup.string().required(),
    title: Yup.string().required('Elige el título'),
    instructions: Yup.string().required('Elige la descripción'),
    releaseDate: Yup.date().required("Elige la fecha de lanzamiento"),
    dueDate: Yup.date().optional(),
    quantity: Yup.number().required('Elige la cantidad'),
    minimumXp: Yup.number().optional(),
    xpClaimedFrom: Yup.date().optional(),
    extraBonus: Yup.number().optional(),
    maxTokensPerUser: Yup.number().optional(),
});

export type FormTokenDropData = Yup.InferType<typeof formTokenDropSchema>;

const Form = ({ tokenDrop, onSubmit }: { tokenDrop?: TokenDrop, onSubmit: (dataForm: FormTokenDropData) => Promise<any> }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const useFormMethods = useForm<FormTokenDropData>({
        resolver: yupResolver(formTokenDropSchema),
        mode: 'onChange',
        defaultValues: tokenDrop
    });

    const handleSubmit = () => {
        setLoading(true);
        const data = useFormMethods.getValues();

        onSubmit(data).then(() => {
            setLoading(false);
        })
    }

    const errors = useFormMethods.formState.errors;
    return (
        <OlaggForm
            useFormMethods={useFormMethods}
            onSubmit={handleSubmit}
            style={{
                width: '100%'
            }}
        >
            <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
                <Box w='full'>
                    {errors && Object.keys(errors).length > 0 && (
                        <>
                            <Box
                                color='red.500'
                                textAlign='left'
                                fontSize='sm'
                                fontWeight='bold'
                                mb='10px'
                            >
                                Por favor, revisa los campos
                            </Box>
                            {Object.keys(errors).map((key) => {
                                return (
                                    <Box
                                        color='red.500'
                                        textAlign='left'
                                        fontSize='sm'
                                        fontWeight='bold'
                                        mb='10px'
                                    >

                                        {
                                            // @ts-ignore
                                            errors[key].message
                                        }
                                    </Box>
                                )
                            })}
                        </>
                    )}
                    <OlaggFormInput
                        label='Slug'
                        controlName='slug'
                        placeholder='Slug'
                    />
                    <OlaggFormInput
                        label='Título'
                        controlName='title'
                        placeholder='Título'
                    />
                    <OlaggFormInput
                        inputType='textarea'
                        label='Instrucciones'
                        controlName='instructions'
                    />
                    <OlaggFormInput
                        label='Fecha de lazamiento'
                        controlName='releaseDate'
                        description={`Fecha a partir de la cual puede reclamar | Zona horaria: ${currentTimeZone}`}
                        inputType='datetime'
                        datePickerProps={{ backgroundColor: 'white' }}
                        required={false}
                    />
                    <OlaggFormInput
                        label='Fecha de expiración'
                        controlName='dueDate'
                        description={`Fecha a partir de la cual ya no se puede reclamar | Zona horaria: ${currentTimeZone}`}
                        inputType='datetime'
                        datePickerProps={{ backgroundColor: 'white' }}
                        required={false}
                    />
                    <OlaggFormInput
                        inputType='number'
                        label='Cantidad a distribuir'
                        controlName='quantity'
                    />
                    <OlaggFormInput
                        inputType='number'
                        label='XP minimos para ser elegible'
                        controlName='minimumXp'
                    />
                    <OlaggFormInput
                        inputType='number'
                        label='Bono extra en OLA Tokens por cada insignia OLA'
                        controlName='extraBonus'
                    />
                    <OlaggFormInput
                        inputType='number'
                        label='Cantidad máxima de tokens por usuario'
                        controlName='maxTokensPerUser'
                    />
                    <Button
                        variant={'filledGradient'}
                        isLoading={loading}
                        disabled={loading}
                        type="submit"
                        w={'full'}
                        style={{
                            marginTop: '30px'
                        }}
                    >
                        Guardar
                    </Button>
                </Box>
            </VStack>
        </OlaggForm>
    )
};

export default Form;