import { FC } from 'react'
import { useToast } from '@chakra-ui/react'
import { fetcher, AdminMarketPlaceEndpoints } from '@olagg/api-hooks'
import { MarketPlaceItem } from '@olagg/db-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import 'moment/min/locales'
import { ItemData } from '@olagg/validation-schemas'
import ItemForm from './Form'

const MarketplaceCreate: FC = () => {
  const navigate = useNavigate()
  const { state } = useLocation();
  const item = state.data as MarketPlaceItem
  const toast = useToast()

  const onSubmit = (data: FormData) =>
    fetcher(AdminMarketPlaceEndpoints.update(item.id!, data))
      .then(() => {
        toast({
          title: 'Actualizado!',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Item actualizado con éxito',
          position: 'bottom-right'
        })
        navigate(ROUTES.MARKETPLACE.all)
      })
      .catch(e => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: e.errors,
          position: 'bottom-right'
        })
      })

  return (
    <ItemForm item={item} onSubmit={onSubmit} />
  )
}

export default MarketplaceCreate
