import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formOwnerSchema = Yup.object({
  name: Yup
    .string()
    .required('El nombre es requerido')
    .min(3, 'El nombre debe ser de mínimo 3 caracteres')
    .max(100, 'El nombre debe ser de máximo 64 caracteres'),
  site: Yup.string().nullable(),
  gameId: Yup
    .number()
    .nullable()
    .optional(),
  discordId: Yup.string().nullable(),
  discordUsername: Yup.string().nullable(),
  twitterId: Yup.string().nullable(),
  twitterUsername: Yup.string().nullable(),
  order: Yup
    .number()
    .optional(),
  isPartner: Yup.boolean().optional().nullable(),
  urlPath: Yup.string().optional().nullable(),
  title: Yup.string().optional().nullable(),
  logoUrl: Yup.string().optional().nullable(),
  mainColor: Yup.string().optional().nullable(),
  darkMode: Yup.boolean().optional().nullable(),
  instructions: Yup.string().optional().nullable(),
  essentialQuests: Yup.array().optional().nullable(),
  extraData: Yup.object({
    en: Yup.object({
      title: Yup.string().optional().nullable(),
      instructions: Yup.string().optional().nullable(),
    }),
    pt: Yup.object({
      title: Yup.string().optional().nullable(),
      instructions: Yup.string().optional().nullable(),
    }),
    bgImage: Yup.string().optional().nullable()
  })
    .optional()
    .nullable(),
})


export type OwnerForm = InferType<typeof formOwnerSchema>
