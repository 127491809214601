import { AdminTreasuresGameEndpoints, fetcher } from "@olagg/api-hooks";
import { Center, Heading, VStack, useToast } from "@chakra-ui/react"
import { useNavigate, useSearchParams } from "react-router-dom";

import MiniGameForm from "./Form";
import { ROUTES } from "../../consts/routes";
import { TreasuresGame } from '@olagg/db-types'

const CreateMiniGame = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const onSubmit = (treasuresGameData: Partial<TreasuresGame>) =>
    fetcher(AdminTreasuresGameEndpoints.create(treasuresGameData))
      .then(() => {
        toast({
          title: 'Mini Juego',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Mini juego creado con éxito`,
          position: 'bottom-right'
        });
        navigate(ROUTES.MINI_GAMES.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Mini Juego
        </Heading>
        <MiniGameForm onSubmit={onSubmit} miniGame={undefined} />
      </VStack>
    </Center>
  )
}

export default CreateMiniGame