import { Box, Center, Heading } from "@chakra-ui/react";
import { useAdminUserContents } from "@olagg/api-hooks";
import { IUseUserContent } from "@olagg/api-hooks/interfaces/IUserUserContent";
import Pagination from "@olagg/ui-kit/Pagination";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import UserContentTable from "../components/Tables/UserContentTable";

const UserContent = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseUserContent>({
    title: searchParams.get('query') || '',
    gameId: searchParams.get('gameId') || '',
    page: Number(searchParams.get('offset') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const { page, limit } = filter

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { userContents, count, isLoading, mutate } = useAdminUserContents(filter)

  return (
    <>
      <Box color="white" flexDirection="column">
        <Heading textAlign="center" my={2}>
          Contenido de usuario
        </Heading>

        <Center display="block" px={0}>
          <Box overflow="auto" mt={4} w="75vw" h="65vh">
            <UserContentTable
              userContents={userContents}
              isLoading={isLoading}
              error={false}
              mutate={mutate}
            />
          </Box>
          <Pagination
            params={{ limit, page, count }}
            onClick={page =>
              onFilter({
                name: 'page',
                value: page + 1
              })
            }
            onLimitChange={val =>
              onFilter({
                name: 'limit',
                value: val
              })
            }
            hideLimitInput
          />
        </Center>
      </Box>
    </>
  )
};

export default UserContent;
