import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box } from '@chakra-ui/react'
import OlaggForm from '../../../components/Forms/OlaggForm'
import { RolePermission, PermissionEnum, OlaggModule } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import type { InferType } from 'yup';
import * as Yup from 'yup';
import OlaggFormSelect from '../../../components/Forms/OlaggFormSelect'
import { useAdminRoles } from '@olagg/api-hooks'

export const formRolesPermissionsSchema = Yup.object({
  role: Yup
    .object({
      value: Yup
        .number()
        .required('Elige el rol'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige un rol'),
  moduleName: Yup
    .object({
      value: Yup
        .string()
        .required('Elige el módulo'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige un módulo'),
  permission: Yup
    .object({
      value: Yup
        .number()
        .required('Elige el permiso'),
      label: Yup
        .string()
        .required('Opcion inválida'),
    })
    .required('Elige un permiso'),
});

interface RolesPermissionsFormProps {
  permission?: RolePermission;
  onSubmit: (dataForm: FormRolesPermissionsData) => void;
};

export type FormRolesPermissionsData = InferType<typeof formRolesPermissionsSchema>;

const modulesOptions = Object.entries(OlaggModule).map(entry => ({
  value: entry[0],
  label: entry[1]
}));

const permissionsOptions = Object.entries(PermissionEnum).splice(0, Object.entries(PermissionEnum).length/2).map(entry => ({
  value: entry[0],
  label: entry[1]
}));

const RolesPermissionsForm: FC<RolesPermissionsFormProps> = ({ permission, onSubmit }) => {
  const { data } = useAdminRoles({ page: 1, limit: 100 });
  const roles = data?.roles;

  const useFormMethods = useForm<FormRolesPermissionsData>({
    resolver: yupResolver(formRolesPermissionsSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (permission) {
      const dataForm: Partial<FormRolesPermissionsData> = {
        ...permission,
        role: {
          value: permission.role.id,
          label: permission.role.name
        },
        moduleName: {
          value: permission.moduleName,
          label: modulesOptions.find(m => m.value = permission.moduleName.toString())?.label.toString() || ''
        },
        permission: {
          value: permission.permission,
          label: permissionsOptions.find(p => p.value = permission.permission.toString())?.label.toString() || ''
        }
      }

      useFormMethods.reset(dataForm);
    }
  }, [permission]);

  const rolesOptions = roles?.map(role => ({
    value: role.id,
    label: role.name
  })) || [{
    value: 0,
    label: 'NONE'
  }];

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        <Box w='full'>
          <OlaggFormSelect
            label='Módulo'
            controlName='moduleName'
            required={true}
            isSearchable={false}
            staticOptions={modulesOptions}
          />

          <OlaggFormSelect
            label='Rol'
            controlName='role'
            required={true}
            isSearchable={false}
            staticOptions={rolesOptions}
          />

          <OlaggFormSelect
            label='Permiso'
            controlName='permission'
            required={true}
            isSearchable={false}
            staticOptions={permissionsOptions}
          />
        </Box>

        <Button
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default RolesPermissionsForm;
