import { Box, Flex } from '@chakra-ui/react'
import { useAdminOwnerQuests } from '@olagg/api-hooks'
import { IUseQuests } from '@olagg/api-hooks/interfaces/IUseQuests'
import { QUEST_TYPES } from '@olagg/db-types'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import OlaggForm from '../Forms/OlaggForm'
import OlaGGFormInput from '../Forms/OlaggFormInput'
import OlaggFormSelect from '../Forms/OlaggFormSelect'

interface IProps {
  onFilter: (value: any) => void
  filter: IUseQuests
}

const QuestFilter = ({ onFilter, filter }: IProps) => {
  const useFormMethods = useForm<IUseQuests>({
    mode: 'onChange',
  })

  const { owners } = useAdminOwnerQuests({ page: 1, limit: 100 })

  const ownersOptions = owners.map(owner => ({
    value: owner.id,
    label: owner.name
  }))

  const visibleOptions = [
    { label: 'Todas', value: '' },
    { label: 'Visibles', value: 'true' },
    { label: 'No visibles', value: 'false' },
  ]
  
  const typesOptions = [{
    label: 'Todos',
    value: ''
  }, ...Object.entries(QUEST_TYPES).map(([key, type]) => ({
    label: type.toUpperCase(),
    value: key.toLowerCase()
  }))]
  
  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onFilter}
    >
      <Flex mx={5} justifyContent={"space-between"} w="100%" gap='2'>
        <OlaGGFormInput
          controlName='title'
          placeholder='Título'
          inputType='search'
          onChange={(e) => onFilter(e.target)}
        />
        <Box zIndex='1000' w='full'>
          <OlaggFormSelect
            controlName="visible"
            placeholder="Ver"
            staticOptions={visibleOptions}
            isSearchable={false}
            onChange={newValue => onFilter({ name: 'visible', value: newValue?.value })}
          />
        </Box>
        <Box zIndex='1000' w='full'>
          <OlaggFormSelect
            controlName='type'
            placeholder='Tipo'
            staticOptions={typesOptions}
            onChange={newValue => onFilter({ name: 'type', value: newValue?.value })}
          />
        </Box>
        <Box zIndex='1000' w='full'>
          <OlaggFormSelect
            controlName='owner_id'
            placeholder='Owner'
            isSearchable
            staticOptions={ownersOptions}
            onChange={newValue => onFilter({ name: 'owner_id', value: newValue?.value })}
          />
        </Box>
      </Flex>
    </OlaggForm>
  )
}

export default QuestFilter
