import { Flex, VStack, Heading, useToast, Spinner, InputGroup, Input, InputLeftElement, FormControl, FormLabel, RadioGroup, Button, Box, Text, Stack, Radio } from "@chakra-ui/react";
import { OlaggModule, PermissionEnum } from "@olagg/db-types";
import { useCallback, useEffect, useState } from "react";
import useRoles from "../../../features/Roles/hooks/useRoles";
import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminPaymentsEndpoints,
    AdminUserEndpoints,
    AdminTournamentsEndpoints,
    AdminMarketPlaceEndpoints,
    OpportunitiesEndpoints,
    AdminOwnersEndpoints
} from '@olagg/api-hooks'
import { BsSearch } from "react-icons/bs";
import { FaChevronRight, FaTrash } from "react-icons/fa";
import OlaggFormInput from "../../../components/Forms/OlaggFormInput";
import OlaggFormSelect from "../../../components/Forms/OlaggFormSelect";

type FormData = {
    userId: number,
    token: string,
    quantity: number,
    network: string,
    budget: string,
    description: string,
    paymentParentAttributes: PaymentParentAttributes
}

type PaymentParentAttributes = {
    type: string,
    title: string
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        userId: Yup.number().required('El usuario es obligatorio'),
        token: Yup.string().required('El token es obligatorio'),
        quantity: Yup.number().required('La cantidad es obligatoria').typeError('Ingresa un número válido'),
        network: Yup.string().required('Network es obligatorio'),
        budget: Yup.string().required('El presupuesto es obligatorio'),
        description: Yup.string().required('La descripción es obligatorio'),
        paymentParentAttributes: Yup
            .object({
                type: Yup
                    .string()
                    .required('Elige un tipo'),
                title: Yup
                    .string()
                    .required('El título es obligatorio'),
            })
            .required('Elige un tipo'),

    })
    .required()

const PaymentsForm = () => {
    const { missingPermission } = useRoles();
    const toast = useToast()

    const [isLoading, setIsLoading] = useState(false)
    const [showResults, setShowResults] = useState(false)
    const [filteredUsers, setFilteredUsers] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const [showResultsParent, setShowResultsParent] = useState(false)
    const [filteredParent, setFilteredParent] = useState([])
    const [selectedParent, setSelectedParent] = useState(null)

    const [showResultsBudget, setShowResultsBudget] = useState(false)
    const [filteredBudget, setFilteredBudget] = useState([])
    const [selectedBudget, setSelectedBudget] = useState(null)

    const [typeValue, setTypeValue] = useState('other')
    const [opportunities, setOpportunities] = useState([])

    const getUsers = useCallback(async () => {
        setIsLoading(true)
        await fetcher(
            AdminUserEndpoints
                .all({ page: 0, limit: 10 })
        ).then((response) => {
            const { users } = response
            setFilteredUsers(users)
            setTypeValue('other')
            useFormMethods.setValue('paymentParentAttributes.type', 'other')
            useFormMethods.setValue('network', 'polygon')
        })

        setIsLoading(false)
    }, [])

    useEffect(() => {
        getUsers();
    }, [])

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    const onSubmit = (dataForm) => {
        let payload = { payment: dataForm }

        if (payload?.payment.paymentParentAttributes?.type == 'opportunity') {
            let oport = opportunities.filter(l => l?.name == payload?.payment.paymentParentAttributes?.title)
            if (oport.length > 0) {
                payload.payment.parentId = oport[0].id
                payload.payment.parentType = 'Opportunity'
            }
        }

        setIsLoading(true)
        fetcher(AdminPaymentsEndpoints.new(payload))
            .then(() => {
                toast({
                    title: 'Pago generado',
                    colorScheme: 'olaggPink',
                    status: 'success',
                    description: '',
                    position: 'bottom-right'
                })
                //LIMPAR FORM O IR AL HISTORIAL DE PAGOS
                useFormMethods.reset()
                setTypeValue('other')
                useFormMethods.setValue('paymentParentAttributes.type', 'other')
                useFormMethods.setValue('paymentParentAttributes.title', undefined)
                useFormMethods.setValue('parentId', undefined)
                useFormMethods.setValue('parentType', undefined)
                setSelectedUser(null)
                setIsLoading(false)
                useFormMethods.setValue('network', 'polygon')
                useFormMethods.setValue('budget', null)
                setSelectedParent(null)
                setSelectedBudget(null)
            })
            .catch(e => {
                toast({
                    title: 'Error',
                    colorScheme: 'olaggYellow',
                    status: 'error',
                    description: e?.error?.message,
                    position: 'bottom-right'
                })
                setIsLoading(false)
            })
    }

    const addUser = (user: any) => {
        useFormMethods.setValue('userId', user.id)
        useFormMethods.trigger('userId')
        setSelectedUser(user)
    }

    const addBudget = (data: any) => {
        useFormMethods.setValue('budget', data.name)
        useFormMethods.trigger('budget')
        setSelectedBudget(data)
    }

    const addParent = (parent: any) => {
        useFormMethods.setValue('parentId', parent.id)
        if (typeValue == 'tournament') {
            useFormMethods.setValue('parentType', 'Tournament')
            useFormMethods.setValue('paymentParentAttributes.title', parent.title)
        }
        if (typeValue == 'lottery') {
            useFormMethods.setValue('parentType', 'Item')
            useFormMethods.setValue('paymentParentAttributes.title', parent.name)
        }

        useFormMethods.trigger('parentId')
        useFormMethods.trigger('parentType')
        useFormMethods.trigger('paymentParentAttributes.title')

        setSelectedParent(parent)
    }

    const removeUser = () => {
        useFormMethods.setValue('userId', undefined)
        useFormMethods.trigger('userId')
        setSelectedUser(null)
    }

    const removeBudget = () => {
        useFormMethods.setValue('budget', undefined)
        useFormMethods.trigger('budget')
        setSelectedBudget(null)
    }

    const removeParent = () => {
        useFormMethods.setValue('parentId', undefined)
        useFormMethods.setValue('parentType', undefined)
        useFormMethods.setValue('paymentParentAttributes.title', undefined)

        useFormMethods.trigger('parentType')
        useFormMethods.trigger('parentId')
        useFormMethods.trigger('paymentParentAttributes.title')

        setSelectedParent(null)
    }

    const handleSearchUser = async (e: any) => {
        await fetcher(
            AdminUserEndpoints
                .all({ page: 0, limit: 10, query: e.target.value })
        ).then((response) => {
            const { users } = response
            setFilteredUsers(users)
        })
    }

    const handleSearchBudget = async (e: any) => {
        await fetcher(
            AdminOwnersEndpoints
                .all({ page: 0, limit: 5, name: e.target.value })
        ).then((response) => {
            const { owners } = response
            setFilteredBudget(owners)
        })
    }

    const handleSearchParent = async (e: any) => {
        if (typeValue == 'tournament') {
            await fetcher(
                AdminTournamentsEndpoints
                    .all({ page: 0, limit: 20, title: e.target.value })
            ).then((response) => {
                const { tournaments } = response
                setFilteredParent(tournaments)
            })
        }

        if (typeValue == 'lottery') {
            await fetcher(
                AdminMarketPlaceEndpoints.all({ type: 'lottery', page: 0, limit: 20, name: e.target.value })
            ).then((response) => {
                const { items } = response
                setFilteredParent(items)
            })
        }
    }

    const selectTypeValue = async (e: string) => {
        setTypeValue(e)
        useFormMethods.setValue('paymentParentAttributes.type', e)

        useFormMethods.setValue('paymentParentAttributes.title', undefined)
        useFormMethods.setValue('paymentParentAttributes.date', undefined)
        useFormMethods.setValue('parentType', undefined)
        useFormMethods.setValue('parentId', undefined)

        if (e == 'opportunity' && opportunities.length == 0) {
            await fetcher(
                OpportunitiesEndpoints
                    .all()
            ).then((response) => {
                const { opportunities } = response
                setOpportunities(opportunities)
            })
        }

        if (e == 'lottery' || e == 'tournament') {
            setFilteredParent([])
            setSelectedParent(null)
        }
    }

    const networksArr = ['polygon', 'ronin', 'ethereum']

    return (
        <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
            <VStack color={'white'} w={'full'} maxW={'375px'}>
                <Flex flexDirection="column" w={'full'}>
                    <Heading fontSize={'30px'}>Pago de tokens</Heading>
                    <Flex fontSize={'14px'}>Completar para realizar pago</Flex>
                </Flex>

                <Flex w={'full'} flexDirection='column'>

                    <Flex position={'relative'} zIndex='1' flexDirection={'column'} mt={'20px'}>
                        <InputGroup>
                            <Input
                                onFocus={() => setShowResults(true)}
                                onBlur={() => {
                                    setTimeout(() => setShowResults(false), 250)
                                }}
                                fontWeight={600}
                                placeholder="Buscar por nombre, email o id"
                                name="query"
                                autoComplete="off"
                                onChange={handleSearchUser}
                            />
                            <InputLeftElement
                                children={<BsSearch color="white" />}
                            />
                            {showResults && (
                                <Flex
                                    id="userSearchResults"
                                    sx={{
                                        width: '100%',
                                        maxHeight: '300px',
                                        backgroundColor: 'white',
                                        borderRadius: '10px',
                                        position: 'absolute',
                                        top: '42px',
                                        zIndex: 1,
                                        flexDirection: 'column',
                                        overflowY: 'auto'
                                    }}>

                                    {filteredUsers.length > 0 && filteredUsers.map((u, i) => (
                                        <Flex sx={{
                                            '&:hover': {
                                                backgroundColor: '#ccc'
                                            }
                                        }}
                                            key={i} color={'black'} p='6px 10px' borderBottom='1px solid #ccc' alignItems={'center'} onClick={() => addUser(u)} cursor='pointer'>
                                            <Flex w='full' flexDirection={'column'}>
                                                <Flex w='full' fontWeight={'bold'}>
                                                    <Flex>{u?.name}</Flex>
                                                </Flex>
                                                <Flex gap={'10px'}>
                                                    <Flex fontWeight={'bold'}>ID: {u?.id}</Flex>
                                                    <Flex>{u?.email}</Flex>
                                                </Flex>
                                            </Flex>
                                            <Flex>
                                                <FaChevronRight color="black" />
                                            </Flex>
                                        </Flex>
                                    ))}

                                </Flex>
                            )}
                        </InputGroup>
                        {selectedUser && (
                            <>
                                <FormLabel marginTop={'20px'} fontWeight={600} htmlFor="user">Usuario</FormLabel>
                                <Flex sx={{
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    color: 'black',
                                    padding: '6px 10px',
                                    borderBottom: '1px solid #ccc',
                                    alignItems: 'center',
                                    marginTop: '0px',
                                    border: '2px solid purple'
                                }}
                                >
                                    <Flex w='full' flexDirection={'column'}>
                                        <Flex w='full' fontWeight={'bold'}>
                                            <Flex>{selectedUser?.name}</Flex>
                                        </Flex>
                                        <Flex gap={'10px'}>
                                            <Flex fontWeight={'bold'}>ID: {selectedUser?.id}</Flex>
                                            <Flex>{selectedUser?.email}</Flex>
                                        </Flex>
                                    </Flex>
                                    <Flex p='5px' onClick={removeUser} cursor='pointer'>
                                        <FaTrash color="black" />
                                    </Flex>
                                </Flex>
                            </>
                        )}

                        {useFormMethods.formState.errors.userId && (
                            <Flex color='red.500' my='3px' fontSize={'14px'}>
                                {useFormMethods.formState.errors.userId.message?.toString()}
                            </Flex>
                        )}
                    </Flex>

                    <Flex position={'relative'} zIndex='0' mt={'20px'}>
                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>

                                <Text fontSize={'18px'} fontWeight='700'>Token</Text>

                                <OlaggFormInput
                                    controlName='token'
                                    placeholder='Token'
                                    customStyles={{
                                        fontWeight: '700',
                                        marginBottom: 0
                                    }}
                                />
                                <OlaggFormInput
                                    controlName='quantity'
                                    placeholder='Cantidad'
                                    customStyles={{
                                        fontWeight: '700',
                                        marginBottom: 0
                                    }}
                                />
                                <OlaggFormSelect
                                    controlName={'network'}
                                    staticOptions={networksArr?.map(t => ({
                                        value: t,
                                        label: t
                                    }))}
                                    required={true}
                                    newVersion
                                />

                                <Text fontSize={'18px'} mt='10px' fontWeight='700'>Elegí una opción</Text>

                                <RadioGroup onChange={(e) => selectTypeValue(e)} value={typeValue} marginTop='10px'>
                                    <Stack direction='column'>
                                        <Radio value='tournament'>Torneo</Radio>
                                        {typeValue == 'tournament' && (
                                            <Flex position={'relative'} zIndex='2' flexDirection={'column'} py='12px'>
                                                {useFormMethods.formState.errors.paymentParentAttributes?.title && (
                                                    <Flex color='red.500' mt='-13px' mb='5px' fontSize={'14px'}>
                                                        {useFormMethods.formState.errors.paymentParentAttributes?.title.message?.toString()}
                                                    </Flex>
                                                )}
                                                <InputGroup>
                                                    <Input
                                                        onFocus={() => setShowResultsParent(true)}
                                                        onBlur={() => {
                                                            setTimeout(() => setShowResultsParent(false), 250)
                                                        }}
                                                        fontWeight={600}
                                                        placeholder="Buscar por título"
                                                        name="queryTitle"
                                                        autoComplete="off"
                                                        onChange={handleSearchParent}
                                                    />
                                                    <InputLeftElement
                                                        children={<BsSearch color="white" />}
                                                    />
                                                    {showResultsParent && (
                                                        <Flex
                                                            id="parentSearchResults"
                                                            sx={{
                                                                width: '100%',
                                                                maxHeight: '300px',
                                                                backgroundColor: 'white',
                                                                borderRadius: '10px',
                                                                position: 'absolute',
                                                                top: '42px',
                                                                zIndex: 1,
                                                                flexDirection: 'column',
                                                                overflowY: 'auto',
                                                            }}>

                                                            {filteredParent.length > 0 && filteredParent.map((p, i) => (
                                                                <Flex sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: '#ccc'
                                                                    }
                                                                }}
                                                                    key={i} color={'black'} p='6px 10px' borderBottom='1px solid #ccc' alignItems={'center'} onClick={() => addParent(p)} cursor='pointer'>
                                                                    <Flex w='full' flexDirection={'column'}>
                                                                        <Flex w='full' fontWeight={'bold'}>
                                                                            <Flex>{p?.title}</Flex>
                                                                        </Flex>
                                                                        <Flex gap={'10px'}>
                                                                            <Flex fontWeight={'bold'}>ID: {p?.id}</Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex>
                                                                        <FaChevronRight color="black" />
                                                                    </Flex>
                                                                </Flex>
                                                            ))}

                                                        </Flex>
                                                    )}
                                                </InputGroup>
                                                {selectedParent && (
                                                    <>
                                                        <Flex sx={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '10px',
                                                            color: 'black',
                                                            padding: '6px 10px',
                                                            borderBottom: '1px solid #ccc',
                                                            alignItems: 'center',
                                                            marginTop: '12px',
                                                            border: '2px solid purple'
                                                        }}
                                                        >
                                                            <Flex w='full' flexDirection={'column'}>
                                                                <Flex w='full' fontWeight={'bold'}>
                                                                    <Flex>{selectedParent?.title}</Flex>
                                                                </Flex>
                                                                <Flex gap={'10px'}>
                                                                    <Flex fontWeight={'bold'}>ID: {selectedParent?.id}</Flex>
                                                                </Flex>
                                                            </Flex>
                                                            <Flex p='5px' onClick={removeParent} cursor='pointer'>
                                                                <FaTrash color="black" />
                                                            </Flex>
                                                        </Flex>
                                                    </>
                                                )}
                                            </Flex>
                                        )}
                                        <Radio value='opportunity'>Oportunidad</Radio>
                                        {typeValue == 'opportunity' && (
                                            <Flex direction={'column'} zIndex="2">
                                                <OlaggFormSelect
                                                    controlName={'paymentParentAttributes.title'}
                                                    staticOptions={opportunities?.map(t => ({
                                                        value: t?.name,
                                                        label: t?.name
                                                    }))}
                                                    required={true}
                                                    newVersion
                                                />
                                                {useFormMethods.formState.errors.paymentParentAttributes?.title && (
                                                    <Flex color='red.500' mt='-13px' mb='5px' fontSize={'14px'}>
                                                        {useFormMethods.formState.errors.paymentParentAttributes?.title.message?.toString()}
                                                    </Flex>
                                                )}
                                            </Flex>
                                        )}

                                        <Radio value='lottery'>Sorteo</Radio>
                                        {typeValue == 'lottery' && (
                                            <Flex position={'relative'} zIndex='2' flexDirection={'column'} py='12px'>
                                                {useFormMethods.formState.errors.paymentParentAttributes?.title && (
                                                    <Flex color='red.500' mt='-13px' mb='5px' fontSize={'14px'}>
                                                        {useFormMethods.formState.errors.paymentParentAttributes?.title.message?.toString()}
                                                    </Flex>
                                                )}
                                                <InputGroup>
                                                    <Input
                                                        onFocus={() => setShowResultsParent(true)}
                                                        onBlur={() => {
                                                            setTimeout(() => setShowResultsParent(false), 250)
                                                        }}
                                                        fontWeight={600}
                                                        placeholder="Buscar por título"
                                                        name="queryTitle"
                                                        autoComplete="off"
                                                        onChange={handleSearchParent}
                                                    />
                                                    <InputLeftElement
                                                        children={<BsSearch color="white" />}
                                                    />
                                                    {showResultsParent && (
                                                        <Flex
                                                            id="parentSearchResults"
                                                            sx={{
                                                                width: '100%',
                                                                maxHeight: '300px',
                                                                backgroundColor: 'white',
                                                                borderRadius: '10px',
                                                                position: 'absolute',
                                                                top: '42px',
                                                                zIndex: 1,
                                                                flexDirection: 'column',
                                                                overflowY: 'auto',
                                                            }}>

                                                            {filteredParent.length > 0 && filteredParent.map((p, i) => (
                                                                <Flex sx={{
                                                                    '&:hover': {
                                                                        backgroundColor: '#ccc'
                                                                    }
                                                                }}
                                                                    key={i} color={'black'} p='6px 10px' borderBottom='1px solid #ccc' alignItems={'center'} onClick={() => addParent(p)} cursor='pointer'>
                                                                    <Flex w='full' flexDirection={'column'}>
                                                                        <Flex w='full' fontWeight={'bold'}>
                                                                            <Flex>{p?.name}</Flex>
                                                                        </Flex>
                                                                    </Flex>
                                                                    <Flex>
                                                                        <FaChevronRight color="black" />
                                                                    </Flex>
                                                                </Flex>
                                                            ))}

                                                        </Flex>
                                                    )}
                                                </InputGroup>
                                                {selectedParent && (
                                                    <>
                                                        <Flex sx={{
                                                            backgroundColor: 'white',
                                                            borderRadius: '10px',
                                                            color: 'black',
                                                            padding: '6px 10px',
                                                            borderBottom: '1px solid #ccc',
                                                            alignItems: 'center',
                                                            marginTop: '12px',
                                                            border: '2px solid purple'
                                                        }}
                                                        >
                                                            <Flex w='full' flexDirection={'column'}>
                                                                <Flex w='full' fontWeight={'bold'}>
                                                                    <Flex>{selectedParent?.name}</Flex>
                                                                </Flex>
                                                            </Flex>
                                                            <Flex p='5px' onClick={removeParent} cursor='pointer'>
                                                                <FaTrash color="black" />
                                                            </Flex>
                                                        </Flex>
                                                    </>
                                                )}
                                            </Flex>
                                        )}
                                        <Radio value='other'>Otro</Radio>
                                        {typeValue == 'other' && (
                                            <Flex direction={'column'}>
                                                <OlaggFormInput
                                                    controlName={'paymentParentAttributes.title'}
                                                    placeholder='Título'
                                                    customStyles={useFormMethods.formState.errors.paymentParentAttributes?.title ? {
                                                        fontWeight: '700',
                                                        borderColor: '#E53E3E',
                                                        boxShadow: '0 0 0 1px #E53E3E',
                                                    } : {
                                                        fontWeight: '700'
                                                    }}
                                                />
                                                {useFormMethods.formState.errors.paymentParentAttributes?.title && (
                                                    <Flex color='red.500' mt='-13px' mb='5px' fontSize={'14px'}>
                                                        {useFormMethods.formState.errors.paymentParentAttributes?.title.message?.toString()}
                                                    </Flex>
                                                )}
                                            </Flex>
                                        )}
                                    </Stack>
                                </RadioGroup>


                                <Text fontSize={'18px'} mt='20px' fontWeight='700'>Presupuesto (Si es de OLA o partner)</Text>

                                <Flex direction={'column'}>
                                    <Flex position={'relative'} zIndex='1' flexDirection={'column'} py='12px'>
                                        {useFormMethods.formState.errors.budget && (
                                            <Flex color='red.500' mt='-13px' mb='5px' fontSize={'14px'}>
                                                {useFormMethods.formState.errors.budget.message?.toString()}
                                            </Flex>
                                        )}
                                        <InputGroup>
                                            <Input
                                                onFocus={() => setShowResultsBudget(true)}
                                                onBlur={() => {
                                                    setTimeout(() => setShowResultsBudget(false), 250)
                                                }}
                                                fontWeight={600}
                                                placeholder="Buscar por nombre"
                                                name="queryName"
                                                autoComplete="off"
                                                onChange={handleSearchBudget}
                                            />
                                            <InputLeftElement
                                                children={<BsSearch color="white" />}
                                            />
                                            {showResultsBudget && (
                                                <Flex
                                                    id="budgetSearchResults"
                                                    sx={{
                                                        width: '100%',
                                                        maxHeight: '300px',
                                                        backgroundColor: 'white',
                                                        borderRadius: '10px',
                                                        position: 'absolute',
                                                        top: '42px',
                                                        zIndex: 1,
                                                        flexDirection: 'column',
                                                        overflowY: 'auto',
                                                    }}>

                                                    {filteredBudget.length > 0 && filteredBudget.map((p, i) => (
                                                        <Flex sx={{
                                                            '&:hover': {
                                                                backgroundColor: '#ccc'
                                                            }
                                                        }}
                                                            key={i} color={'black'} p='6px 10px' borderBottom='1px solid #ccc' alignItems={'center'} onClick={() => addBudget(p)} cursor='pointer'>
                                                            <Flex w='full' flexDirection={'column'}>
                                                                <Flex w='full' fontWeight={'bold'}>
                                                                    <Flex>{p?.name}</Flex>
                                                                </Flex>
                                                            </Flex>
                                                            <Flex>
                                                                <FaChevronRight color="black" />
                                                            </Flex>
                                                        </Flex>
                                                    ))}

                                                </Flex>
                                            )}
                                        </InputGroup>
                                        {selectedBudget && (
                                            <>
                                                <Flex sx={{
                                                    backgroundColor: 'white',
                                                    borderRadius: '10px',
                                                    color: 'black',
                                                    padding: '6px 10px',
                                                    borderBottom: '1px solid #ccc',
                                                    alignItems: 'center',
                                                    marginTop: '12px',
                                                    border: '2px solid purple'
                                                }}
                                                >
                                                    <Flex w='full' flexDirection={'column'}>
                                                        <Flex w='full' fontWeight={'bold'}>
                                                            <Flex>{selectedBudget?.name}</Flex>
                                                        </Flex>
                                                    </Flex>
                                                    <Flex p='5px' onClick={removeBudget} cursor='pointer'>
                                                        <FaTrash color="black" />
                                                    </Flex>
                                                </Flex>
                                            </>
                                        )}
                                    </Flex>

                                </Flex>

                                <Text fontSize={'18px'} mt='20px' fontWeight='700'>Descripción del pago</Text>
                                <Text fontSize={'12px'} fontWeight='400'>*En el caso de torneos indicar nombre / fecha en la descripción</Text>

                                <OlaggFormInput
                                    inputType="textarea"
                                    controlName='description'
                                    placeholder='Descripción del pago'
                                    customStyles={{
                                        marginTop: '10px',
                                        fontWeight: '700'
                                    }}
                                />

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '30px'
                                    }}
                                    isDisabled={isLoading || missingPermission(OlaggModule.Payment, PermissionEnum.CREATE)}
                                >
                                    Cargar pago
                                </Button>
                            </form>
                        </FormProvider>
                    </Flex>
                </Flex>
            </VStack >
        </Flex >
    )
};

export default PaymentsForm;
