import {
  Button,
  Center,
  Flex,
  useToast,
  VStack,
  Heading,
  Switch,
  Text,
  Box
} from '@chakra-ui/react'
import { QUEST_TYPES, Quest, OlaggModule, PermissionEnum } from '@olagg/db-types'
import {
  fetcher,
  AdminQuestEndpoints,
  useAdminQuests,
  useAdminOwnerQuests
} from '@olagg/api-hooks'
import {
  CreateQuestInput,
  createQuestSchema,
  UpdateQuestInput,
  updateQuestSchema
} from '@olagg/validation-schemas'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import OlaggForm from './OlaggForm'
import OlaGGFormInput from './OlaggFormInput'
import OlaggFormSelect from './OlaggFormSelect'
import OlaggFormInput from './OlaggFormInput'
import { ROUTES } from '../../consts/routes'
import OlaggMarkdown from '@olagg/ui-kit/OlaggMarkdown'
import useRoles from '../../features/Roles/hooks/useRoles'
import TwitterQuestForm from './TwitterQuestForm'

interface IProps {
  quest?: Quest
  mode: 'edit' | 'create'
  mutate: () => void
}

const QuestForm: FC<IProps> = ({ mode, quest, mutate }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [instructions, setInstructions] = useState<string>('');
  const [selectedType, setSelectedType] = useState<string>('');
  const [showLocaleInputs, setShowLocaleInputs] = useState<boolean>(false);
  const isCreate = mode === 'create'
  const isEdit = mode === 'edit'
  const heading = isCreate ? 'Creando nuevo Quest' : 'Editar Quest'
  const [afterVerifyToggle, setAfterVerifyToggle] = useState(false)

  const useFormMethods = useForm<CreateQuestInput>({
    resolver: yupResolver(isCreate ? createQuestSchema : updateQuestSchema),
    mode: 'onChange',
    defaultValues: { ...quest, dependsOn: quest?.dependsOn?.[0] || '' },
  })

  const [isEditable, setIsEditable] = useState(
    isEdit ? false : true
  )
  const [questsTitle, setQuestsTitle] = useState()

  const {
    quests
  } = useAdminQuests({
    title: questsTitle,
    page: 1,
    limit: 100
  })

  const { owners, asyncOwnerOptions } = useAdminOwnerQuests({ page: 1, limit: 10 });
  const staticOwnerOptions = owners?.map(owner => ({
    value: owner.id,
    label: owner.name
  }));

  const { missingPermission } = useRoles();

  const { isSubmitting } = useFormMethods.formState

  const questTypesOptions = [
    QUEST_TYPES.AUTOMATIC,
    QUEST_TYPES.MANUAL,
    QUEST_TYPES.ONE_CLICK,
    QUEST_TYPES.SECRET_WORD,
    QUEST_TYPES.ADMIN_COMPLETION,
    QUEST_TYPES.TWITTER
  ].map(name => ({ value: name, label: name }))

  const topicsOptions = [
    ['quest.discord.linked', 'Cuando vincula su cuenta de Discord'],
    ['quest.google.linked', 'Cuando vincula su cuenta de Google'],
    ['quest.twitter.linked', 'Cuando vincula su cuenta de Twitter'],
    ['quest.newsroom.linked', 'Cuando vincula su cuenta de NewsRoom'],
    ['quest.wallet.linked', 'Cuando vincula su wallet'],
    ['user.account.updated', 'Cuando actualiza su cuenta'],
    ['quest.discord.followed', 'Cuando sigue el servidor de Discord'],
    ['quest.oneclick.clicked', 'Cuando hace click en el botón de OneClick'],
    ['user.account.login', 'Cuando inicia sesión'],
    ['user.referrer.linked', 'Cuando crea su cuenta con un referido'],
    ['quest.code.match', 'Cuando ingresa la palabra secreta correcta'],
    ['user.email.verified', 'Cuando verifica su email'],
    ['user.passkey.registered', 'Cuando registra su passkey'],
    ['user.deposit-wallet.created', 'Cuando registra su wallet de depósito'],
    ['user.name.set', 'Cuando establece su username'],
    ['user.opportunities.set', 'Cuando se definen los intereses de Oportunidades en los que desea contribuir el usuario.'],
    ['user.avatar.updated', 'Cuando actualiza su avatar'],
    ['user.username.updated', 'Cuando actualiza su username'],
    ['user.tweet.retweet', 'Cuando retweetea un tweet'],
    ['user.tweet.like', 'Cuando le da like a un tweet'],
    ['user.tweet.follow', 'Cuando sigue una cuenta de Twitter'],
    ['user.tweet.tweet', 'Cuando twittea algo'],
  ].map(name => ({ value: name[0], label: name[1] }))

  const onSubmit = (data: CreateQuestInput | UpdateQuestInput) => {
    const body: any = {
      ...data,
      visible: data.visible || false,
      recursive: data.recursive || false,
      ownerId: data.ownerId,
      dependsOn: [data.dependsOn || ''],
      trigger: data.trigger,
      type: data.type as QUEST_TYPES,
      extraData: {
        ...data.extraData,
        subtype: data.extraData?.subtype,
      }
    }

    if (isCreate) {
      fetcher(
        AdminQuestEndpoints.create(body)
      )
        .then(() => onSuccess('Quest creado correctamente'))
        .catch(e => onError(e))
    }

    if (isEdit) {
      fetcher(AdminQuestEndpoints.update(quest!.id!, body))
        .then(() =>
          onSuccess(
            'Quest editado correctamente',
            'El Quest fue editado con éxito'
          )
        )
        .catch(e => onError(e))
    }
  }

  const onSuccess = async (title: string, body?: string) => {
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: body || 'El Quest fue creado con éxito',
      position: 'bottom-right'
    })
    if (mode === 'create') {
      resetForm()
      navigate(ROUTES.QUESTS.all)
    }

    if (mode === 'edit' && quest?.id) {
      mutate();
    }
  }

  const onError = (e: { message: string } | string) => {
    const message = typeof e === 'string' ? e : e.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  const checkType = (value: string) => {
    setSelectedType(value)
    if (value == QUEST_TYPES.ONE_CLICK) {
      setAfterVerifyToggle(true)
    } else {
      setAfterVerifyToggle(false)
    }

    if (value == QUEST_TYPES.SECRET_WORD) {
      useFormMethods.setValue('trigger', 'quest.code.match')
      useFormMethods.setValue('secretWord', quest?.secretWord)
    }
  }

  useEffect(() => {
    if (quest) checkType(quest.type)
  }, [quest])

  const resetForm = () => useFormMethods.reset()

  const questsOptions = quests.map(quest => ({ value: quest.id, label: quest.title }))

  return (
    <Center w={'full'}>
      <VStack color={'white'}>
        <Heading my={2}>{heading}</Heading>
        <Flex flexDirection={'row'} w='full' justifyContent={'flex-start'}>
          <Flex
            w="full"
            justify="start"
            align="center"
            style={{ marginTop: '30px' }}
          >
            <label
              htmlFor="enableShowLocale"
              style={{ cursor: 'pointer' }}
            >
              Otros idiomas
            </label>
            <Switch
              id="enableShowLocale"
              colorScheme="purple"
              size="md"
              ml={2}
              onChange={() => setShowLocaleInputs(prev => !prev)}
            />
          </Flex>
          {isEdit && (

            <Flex
              w="full"
              justify="end"
              align="center"
              style={{ marginTop: '30px' }}
            >
              <label
                htmlFor="enableEdition"
                style={{ cursor: 'pointer' }}
              >
                Habilitar edición
              </label>
              <Switch
                id="enableEdition"
                colorScheme="purple"
                size="md"
                ml={2}
                onChange={() => setIsEditable(prev => !prev)}
                isDisabled={missingPermission(OlaggModule.Quest, PermissionEnum.EDIT)}
              />
            </Flex>
          )}
        </Flex>

        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
          <fieldset disabled={!(isEditable || isCreate)}>
            <VStack
              w={{ base: '70vw', lg: '50vw' }}
              mt={2}
              mb={10}
            >
              <OlaGGFormInput
                label='Título'
                controlName='title'
                placeholder='Ej: Vincula tu cuenta de Discord'
                required={true}
              />

              {showLocaleInputs && (
                <>
                  <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                    <OlaGGFormInput
                      label='Título Inglés'
                      controlName='extraData.en.title'
                    />
                    <OlaGGFormInput
                      label='Título Portugés'
                      controlName='extraData.pt.title'
                    />
                  </Flex>
                  <br />
                </>
              )}

              <OlaGGFormInput
                label='Texto del botón'
                controlName='buttonText'
                placeholder='Ej: Vincula Discord'
                required={true}
              />

              {showLocaleInputs && (
                <>
                  <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                    <OlaGGFormInput
                      label='Texto del botón Inglés'
                      controlName='extraData.en.buttonText'
                    />
                    <OlaGGFormInput
                      label='Texto del botón Portugés'
                      controlName='extraData.pt.buttonText'
                    />
                  </Flex>
                  <br />
                </>
              )}

              <OlaGGFormInput
                label='Link del botón'
                controlName='action'
                placeholder='Ej: /mi-perfil/datos'
                required={true}
              />
              <OlaGGFormInput
                label='Link de la imagen de portada'
                description='712px x 400px sugerido'
                controlName='banner'
                placeholder='https://cdn.olagg.io/game-assets/axie-infinity.png'
                required={false}
              />
              <OlaGGFormInput
                label='Link del icono'
                description='48px x 48px sugerido'
                controlName='icon'
                placeholder='https://cdn.olagg.io/game-assets/axie-infinity.png'
                required={true}
              />
              <OlaggFormSelect
                newVersion={true}
                label='Tipo de Quest'
                controlName='type'
                required={true}
                isSearchable={false}
                staticOptions={questTypesOptions}
                onChange={value => checkType(value)}
              />
              {afterVerifyToggle &&
                <OlaGGFormInput
                  label='Link a donde redirigir luego de verificar la Misión (opcional)'
                  controlName='callback'
                  placeholder='Ej: https://olagg.io/landing/faqs'
                />
              }

              {selectedType === QUEST_TYPES.SECRET_WORD && (
                <>
                  <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                    <OlaGGFormInput
                      label='Palabra secreta'
                      controlName='secretWord'
                      description='Ingresa la palabra secreta para completar la misión'
                      placeholder='Ej: OlaSecret'
                      required={selectedType === QUEST_TYPES.SECRET_WORD}
                    />
                    <OlaggFormInput
                      label='Instrucciones'
                      controlName='secretWordInstructions'
                      description='Escribe las instrucciones para la palabra secreta (puedes usar texto con Markdown)'
                      inputType='textarea'
                      required={false}
                      onChange={(e) => setInstructions(e.target.value)}
                    />
                    <OlaggFormInput
                      label='Cantidad de intentos'
                      controlName='secretWordMaxAttempts'
                      description='Máxima cantidad de intentos (ingresa 0 para cantidad ilimitada)'
                      required={false}
                    />

                    {showLocaleInputs && (
                      <>
                        <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                          <OlaggFormInput
                            label='Instrucciones (Inglés)'
                            controlName='extraData.en.secretWordInstructions'
                            description='Escribe en Inglés las instrucciones para la palabra secreta (puedes usar texto con Markdown)'
                            inputType='textarea'
                            required={false}
                          />
                          <OlaggFormInput
                            label='Instrucciones (Portugués)'
                            controlName='extraData.pt.secretWordInstructions'
                            description='Escribe en Portugués las instrucciones para la palabra secreta (puedes usar texto con Markdown)'
                            inputType='textarea'
                            required={false}
                          />
                        </Flex>
                        <br />
                      </>
                    )}


                    <Text lineHeight='normal' fontWeight={600} mb='2'>
                      Instrucciones (Vista previa)
                    </Text>
                    <Box w='full' border='1px solid white' minH='10' borderRadius='5' p='3' mb={'20px'}>
                      <OlaggMarkdown>
                        {instructions}
                      </OlaggMarkdown>
                    </Box>
                  </Flex>
                  <br />
                </>
              )}

              {selectedType === QUEST_TYPES.TWITTER && <TwitterQuestForm quest={quest!} showLocaleInputs={showLocaleInputs} />}

              <OlaGGFormInput
                label='Descripción'
                controlName='description'
                placeholder='Ej: Vincula tu cuenta de Discord para poder participar en los torneos'
              />

              {showLocaleInputs && (
                <>
                  <Flex direction='column' w='full' bgColor='#20173d' p='8' borderRadius={'12px'}>
                    <OlaGGFormInput
                      label='Descripción Inglés'
                      controlName='extraData.en.description'
                    />
                    <OlaGGFormInput
                      label='Descripción Portugés'
                      controlName='extraData.pt.description'
                    />
                  </Flex>
                  <br />
                </>
              )}

              <OlaggFormSelect
                newVersion={true}
                label='Depende de'
                controlName='dependsOn'
                isSearchable
                required={false}
                onInputChange={setQuestsTitle}
                staticOptions={questsOptions}
              />
              <OlaGGFormInput
                label='Score'
                controlName='score'
                placeholder='Ej: 100'
                required={true}
              />
              <OlaGGFormInput
                label='Multiplicador'
                controlName='multiplier'
                placeholder='Ej: 1'
                inputType='number'
                required={true}
              />
              <OlaggFormSelect
                newVersion={true}
                label='Evento que completa la Quest'
                controlName='trigger'
                required={quest?.type && [QUEST_TYPES.AUTOMATIC, QUEST_TYPES.MANUAL].includes(quest.type)}
                isSearchable={false}
                staticOptions={topicsOptions}
              />

              <OlaggFormSelect
                newVersion={true}
                label='Vincular con Owner'
                controlName='ownerId'
                required={true}
                isSearchable={true}
                staticOptions={staticOwnerOptions}
                asyncOptions={asyncOwnerOptions}
              />

              <OlaggFormInput
                label='Orden'
                controlName='order'
                required={true}
              />

              <OlaggFormInput
                label='Es recursiva?'
                controlName='recursive'
                inputType='switch'
                required={false}
                chakraStyles={{ colorScheme: 'red' }}
              />

              <OlaggFormInput
                label='Mostrar?'
                controlName='visible'
                inputType='switch'
                required={false}
                chakraStyles={{ colorScheme: 'red' }}
              />

              {isEditable && (
                <Flex w="full" style={{ marginTop: '20px' }}>
                  <Button
                    variant="filledGradient"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    type="submit"
                    flex="1"
                  >
                    {isCreate ? 'Crear Quest' : 'Guardar cambios'}
                  </Button>
                  {isEdit && (
                    <Button
                      ml={2}
                      variant="outlinedGradient"
                      onClick={() => navigate(-1)}
                      flex="1"
                    >
                      Descartar Cambios
                    </Button>
                  )}
                </Flex>
              )}
            </VStack>
          </fieldset>
        </OlaggForm>
      </VStack>
    </Center>
  )
}

export default QuestForm
