import ReferFriend from '@olagg/ui-kit/svg/ReferFriend'
import { Joystick, StarOutlined } from '@olagg/ui-kit'
import { LuThumbsUp } from "react-icons/lu";

export const iconsOptions = [
  {
    value: 'star',
    label: <StarOutlined color='#718096' />
  },
  {
    value: 'thumbs', 
    label: <LuThumbsUp color='#718096' />
  },
  {
    value: 'joystick', 
    label: <Joystick color='#718096' />
  },
  {
    value: 'people', 
    label: <ReferFriend color='#718096' />
  },
]
