import { FC, useState } from 'react';
import {
  Center,
  Heading,
  VStack,
  Button,
  Box,
  Text,
  Flex,
  Spinner,
} from '@chakra-ui/react';
import { useAdminAlerts } from '@olagg/api-hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import { FaPlus } from 'react-icons/fa';
import Pagination from '@olagg/ui-kit/Pagination';
import AlertsTable from './Table';
import { IUseAlerts } from '@olagg/api-hooks/interfaces/IUseAlerts';
import useRoles from '../../features/Roles/hooks/useRoles';
import { OlaggModule, PermissionEnum } from '@olagg/db-types';

const Alerts: FC = () => {
  const navigate = useNavigate();
  const { missingPermission } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseAlerts>({
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
  })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { page, limit } = filter;
  const { data, deleteAlert, isLoading } = useAdminAlerts(filter);
  const alerts = data?.alerts;
  const count = data?.meta.count;

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Alertas
        </Heading>

        <Flex w='full' alignItems='center' gap='5'>
          <Button
            mt='2' mb='5'
            display='flex'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.ALERTS.create)}
            isDisabled={missingPermission(OlaggModule.Alert, PermissionEnum.CREATE)}
          >
            Agregar Alerta
          </Button>
        </Flex>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          alerts && alerts?.length > 0 ? (
            <AlertsTable 
              alerts={alerts}
              deleteAlert={deleteAlert}
            />
          ) : (
            <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
              <Text>No hay ninguna alerta aún</Text>
            </Box>
          )
        )}

        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center >
  )
};

export default Alerts;
