import { AdminTreasuresGameEndpoints } from "@olagg/api-hooks"
import { TreasuresGame } from "@olagg/db-types"
import { useQ } from "@olagg/api-hooks"

export const useMiniGame = (id: string) => {
  const response = useQ<{ treasureGame: TreasuresGame }>(AdminTreasuresGameEndpoints.find(id), {
    revalidateOnFocus: true
  })

  return {
    ...response,
    miniGame: response.data?.treasuresGame ?? null
  }
}