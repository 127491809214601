import { FC, useEffect } from 'react'
import { Mumbai, Polygon } from "@thirdweb-dev/chains";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams
} from 'react-router-dom'
import { OlaggModule, PermissionEnum } from '@olagg/db-types'
import {
  ThirdwebProvider,
  en,
  es,
  metamaskWallet,
  walletConnect
} from '@thirdweb-dev/react'

import Alerts from './pages/Alerts'
import CreateAlert from './pages/Alerts/Create'
import CreateMiniGame from './pages/MiniGames/Create';
import CreateNotification from './pages/Notification/CreateNotification'
import CreateRelevantAction from './pages/RelevantActions/Create'
import CreateRole from './pages/Permissions/Roles/Create'
import CreateRolesPermission from './pages/Permissions/RolesPermissions/Create'
import CreateSegment from './pages/Notification/CreateSegment'
import CreateTokenDrops from './pages/TokenDrops/Create'
import CreateTournament from './pages/Tournaments/Create'
import Credentials from './pages/Credentials'
import EditMiniGame from './pages/MiniGames/Edit';
import EditTournament from './pages/Tournaments/Edit'
import Error from './pages/Error'
import Games from './pages/Games'
import GamesContent from './pages/GameContent'
import GamesContentCreate from './pages/GameContent/create'
import GamesContentEdit from './pages/GameContent/edit'
import Home from './pages/Home'
import Layout from './components/Layout'
import { Login } from '@olagg/ui-kit'
import Maintenance from '@olagg/ui-kit/Maintenance'
import ManageCredentials from './pages/ManageCredentials'
import ManageGame from './pages/ManageGame'
import ManageQuest from './pages/ManageQuest'
import ManageQuestOwner from './pages/ManageQuestOwner'
import ManageUser from './pages/ManageUser'
import ManageUserContent from './pages/ManageUserContent'
import Marketplace from './pages/Marketplace'
import MarketplaceCreate from './pages/Marketplace/create'
import MarketplaceEdit from './pages/Marketplace/edit'
import MiniGames from './pages/MiniGames';
import Notifications from './pages/Notification/Notifications'
import Payments from './pages/Payments/Payments'
import QuestBatchComplete from './pages/QuestBatchComplete'
import QuestOwners from './pages/QuestOwners'
import Quests from './pages/Quests'
import { ROUTES } from './consts/routes'
import RelevantActions from './pages/RelevantActions'
import RequireAuth from './components/RequireAuth'
import Roles from './pages/Permissions/Roles'
import RolesPermissions from './pages/Permissions/RolesPermissions'
import Roulette from './pages/Roulette'
import Scholarships from './pages/Scholarships'
import Segments from './pages/Notification/Segments'
import TokenAddWhitelistedUser from './pages/Tokens/TokenAddWhitelistedUser'
import TokenAddWhitelistedUsers from './pages/Tokens/Assignments/CreateBatch'
import TokenAssignments from './pages/Tokens/Assignments'
import TokenDrops from './pages/TokenDrops/TokenDrops'
import TokenForm from './pages/Tokens/TokenForm'
import Tokens from './pages/Tokens'
import TournamentAddInscription from './pages/Tournaments/Inscriptions/Create'
import TournamentInscriptions from './pages/Tournaments/Inscriptions/Inscriptions'
import Tournaments from './pages/Tournaments'
import UpdateAlert from './pages/Alerts/Edit'
import UpdateRelevantAction from './pages/RelevantActions/Edit'
import UpdateRole from './pages/Permissions/Roles/Edit'
import UpdateTokenDrop from './pages/TokenDrops/Update'
import UserContent from './pages/UserContent'
import UserPermissions from './pages/Permissions'
import Users from './pages/Users'
import { useAuthStore } from '@olagg/store'
import useRoles from './features/Roles/hooks/useRoles';
import { useRolesStore } from '@olagg/store'
import Surveys from './pages/Surveys/Surveys'
import SurveysEdit from './pages/Surveys/SurveysEdit'

const App: FC = () => {
  const { me, loggedIn, getMe } = useAuthStore()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { roles, initRoles, initPermissions } = useRolesStore();
  const { rolesForModule } = useRoles();
  const referrerId =
    searchParams.get('referrerId') ||
    localStorage.getItem('referrerId') ||
    ''

  if (searchParams.get('referrerId'))
    localStorage.setItem('referrerId', referrerId)

  const utm_path =
    searchParams.get('utm_path') ||
    localStorage.getItem('utm_path') ||
    ''

  if (searchParams.get('utm_path'))
    localStorage.setItem('utm_path', utm_path)

  useEffect(() => {
    document.body.style.backgroundColor = `#0C0324`
    getMe()
  }, [, pathname])

  useEffect(() => {
    const c = setTimeout(() => {
      if (me) {
        localStorage.removeItem('utm_path')

        if (utm_path) {
          navigate(utm_path)
        }
      }
    }, 5)

    return () => clearTimeout(c)
  }, [me])

  const olaChain = (import.meta.env.VITE_OLAGG_CHAIN === 'Polygon' ? Polygon : Mumbai);
  const browserLanguage: string = navigator.language.slice(0, 2);
  const locale = browserLanguage === 'es' ? es() : en();

  useEffect(() => {
    initRoles();
    initPermissions();
  }, []);

  const {
    LOGIN,
    HOME,
    SCHOLARSHIP_APPLICATIONS,
    GAMES,
    CREDENTIALS,
    USERS,
    OWNERS,
    QUESTS,
    USER_CONTENT,
    NOTIFICATIONS,
    SEGMENTS,
    MARKETPLACE,
    TOURNAMENTS,
    TOKENS,
    RELEVANT_ACTIONS,
    ALERTS,
    USER_PERMISSIONS,
    ROLES,
    ROLES_PERMISSIONS,
    ROULETTE,
    TOKEN_DROPS,
    PAYMENTS,
    MINI_GAMES,
    SURVEYS
  } = ROUTES

  return import.meta.env.VITE_MAINTENANCE === 'TRUE' ? (
    <Maintenance />
  ) : (
    <ThirdwebProvider
      activeChain={olaChain}
      supportedChains={[olaChain]}
      clientId={import.meta.env.VITE_THIRDWEB_CLIENT_ID}
      locale={locale}
    >
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              loggedIn ? <Navigate to={HOME} /> : <Login
                onClickLogin={provider => {
                  localStorage.setItem(
                    'provider',
                    provider
                  )
                }}
                referrerId={referrerId}
                redirect={utm_path}
              />
            }
          />
          <Route
            path={LOGIN}
            element={!loggedIn ? <Login
              onClickLogin={provider => {
                localStorage.setItem(
                  'provider',
                  provider
                )
              }}
              referrerId={referrerId}
              redirect={utm_path}
            /> : <Navigate to={'/'} />}
          />
          <Route
            path={HOME}
            element={
              <RequireAuth
                roles={roles
                  .map(role => role.name.toUpperCase())
                  .filter(role => role !== 'USER')
                }
                me={me}
                loggedIn={loggedIn}
              >
                <Home />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game)}
                me={me}
                loggedIn={loggedIn}
              >
                <Games />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageGame mode="create" />
              </RequireAuth>
            }
          />
          <Route
            path={SCHOLARSHIP_APPLICATIONS}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Scholarship)}
                me={me}
                loggedIn={loggedIn}
              >
                <Scholarships />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Credential)}
                me={me}
                loggedIn={loggedIn}
              >
                <Credentials />
              </RequireAuth>
            }
          />
          <Route
            path={USERS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.User)}
                me={me}
                loggedIn={loggedIn}
              >
                <Users />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageGame mode="edit" />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.content}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <GamesContent />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.content_create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <GamesContentCreate />
              </RequireAuth>
            }
          />
          <Route
            path={GAMES.content_update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Game, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <GamesContentEdit />
              </RequireAuth>
            }
          />
          <Route
            path={MARKETPLACE.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Item)}
                me={me}
                loggedIn={loggedIn}
              >
                <Marketplace />
              </RequireAuth>
            }
          />
          <Route
            path={MARKETPLACE.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Item, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <MarketplaceCreate />
              </RequireAuth>
            }
          />
          <Route
            path={MARKETPLACE.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Item, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <MarketplaceEdit />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Token)}
                me={me}
                loggedIn={loggedIn}
              >
                <Tokens />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Token, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenForm mode='create' />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Token, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenForm mode='edit' />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.whitelist}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenAssignment)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenAssignments />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.addWhitelistedUser}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenAssignment, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenAddWhitelistedUser />
              </RequireAuth>
            }
          />
          <Route
            path={TOKENS.addWhitelistedUsers}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenAssignment, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenAddWhitelistedUsers />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Credential, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageCredentials mode="create" />
              </RequireAuth>
            }
          />
          <Route
            path={CREDENTIALS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Credential, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageCredentials mode="edit" />
              </RequireAuth>
            }
          />
          <Route
            path={USERS.show}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.User, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageUser />
              </RequireAuth>
            }
          />
          <Route
            path={OWNERS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Owner)}
                me={me}
                loggedIn={loggedIn}
              >
                <QuestOwners />
              </RequireAuth>
            }
          />
          <Route
            path={OWNERS.show}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Owner, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageQuestOwner mode="edit" />
              </RequireAuth>
            }
          />
          <Route
            path={OWNERS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Owner, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageQuestOwner mode="create" />
              </RequireAuth>
            }
          />
          <Route
            path={QUESTS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Quest)}
                me={me}
                loggedIn={loggedIn}
              >
                <Quests />
              </RequireAuth>
            }
          />
          <Route
            path={QUESTS.show}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Quest, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageQuest mode="edit" />
              </RequireAuth>
            }
          />
          <Route
            path={QUESTS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Quest, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageQuest mode="create" />
              </RequireAuth>
            }
          />
          <Route
            path={QUESTS.batch_complete}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Quest, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <QuestBatchComplete />
              </RequireAuth>
            }
          />
          <Route
            path={USER_CONTENT.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.UserContent)}
                me={me}
                loggedIn={loggedIn}
              >
                <UserContent />
              </RequireAuth>
            }
          />
          <Route
            path={USER_CONTENT.show}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.UserContent, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <ManageUserContent mode='edit' />
              </RequireAuth>
            }
          />
          <Route
            path={NOTIFICATIONS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Notification)}
                me={me}
                loggedIn={loggedIn}
              >
                <Notifications />
              </RequireAuth>
            }
          />
          <Route
            path={NOTIFICATIONS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Notification, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateNotification />
              </RequireAuth>
            }
          />
          <Route
            path={SEGMENTS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Notification, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateSegment />
              </RequireAuth>
            }
          />
          <Route
            path={SEGMENTS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Notification)}
                me={me}
                loggedIn={loggedIn}
              >
                <Segments />
              </RequireAuth>
            }
          />
          <Route path="*" element={<Error />} />
          <Route
            path={TOURNAMENTS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Tournament)}
                me={me}
                loggedIn={loggedIn}
              >
                <Tournaments />
              </RequireAuth>
            }
          />
          <Route
            path={TOURNAMENTS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Tournament, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateTournament />
              </RequireAuth>
            }
          />
          <Route
            path={TOURNAMENTS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Tournament, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <EditTournament />
              </RequireAuth>
            }
          />
          <Route
            path={TOURNAMENTS.inscriptions}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Inscription)}
                me={me}
                loggedIn={loggedIn}
              >
                <TournamentInscriptions />
              </RequireAuth>
            }
          />
          <Route
            path={TOURNAMENTS.addInscription}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Inscription, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <TournamentAddInscription />
              </RequireAuth>
            }
          />
          <Route
            path={RELEVANT_ACTIONS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.RelevantAction)}
                me={me}
                loggedIn={loggedIn}
              >
                <RelevantActions />
              </RequireAuth>
            }
          />
          <Route
            path={RELEVANT_ACTIONS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.RelevantAction, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateRelevantAction />
              </RequireAuth>
            }
          />
          <Route
            path={RELEVANT_ACTIONS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.RelevantAction, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <UpdateRelevantAction />
              </RequireAuth>
            }
          />
          <Route
            path={ALERTS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Alert)}
                me={me}
                loggedIn={loggedIn}
              >
                <Alerts />
              </RequireAuth>
            }
          />
          <Route
            path={ALERTS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Alert, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateAlert />
              </RequireAuth>
            }
          />
          <Route
            path={ALERTS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Alert, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <UpdateAlert />
              </RequireAuth>
            }
          />
          <Route
            path={USER_PERMISSIONS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role)}
                me={me}
                loggedIn={loggedIn}
              >
                <UserPermissions />
              </RequireAuth>
            }
          />
          <Route
            path={ROLES.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role)}
                me={me}
                loggedIn={loggedIn}
              >
                <Roles />
              </RequireAuth>
            }
          />
          <Route
            path={ROLES.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateRole />
              </RequireAuth>
            }
          />
          <Route
            path={ROLES.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <UpdateRole />
              </RequireAuth>
            }
          />
          <Route
            path={ROLES_PERMISSIONS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role)}
                me={me}
                loggedIn={loggedIn}
              >
                <RolesPermissions />
              </RequireAuth>
            }
          />
          <Route
            path={ROLES_PERMISSIONS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Role, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateRolesPermission />
              </RequireAuth>
            }
          />
          <Route
            path={ROULETTE.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.RouletteValue, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <Roulette />
              </RequireAuth>
            }
          />
          <Route
            path={TOKEN_DROPS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenDrop, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <TokenDrops />
              </RequireAuth>
            }
          />
          <Route
            path={TOKEN_DROPS.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenDrop, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateTokenDrops />
              </RequireAuth>
            }
          />
          <Route
            path={TOKEN_DROPS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.TokenDrop, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <UpdateTokenDrop />
              </RequireAuth>
            }
          />
          <Route
            path={PAYMENTS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Payment, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <Payments />
              </RequireAuth>
            }
          />
          <Route
            path={MINI_GAMES.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.MiniGame)}
                me={me}
                loggedIn={loggedIn}
              >
                <MiniGames />
              </RequireAuth>
            }
          />
          <Route
            path={MINI_GAMES.create}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.MiniGame, PermissionEnum.CREATE)}
                me={me}
                loggedIn={loggedIn}
              >
                <CreateMiniGame />
              </RequireAuth>
            }
          />
          <Route
            path={MINI_GAMES.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.MiniGame, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <EditMiniGame />
              </RequireAuth>
            }
          />
          <Route
            path={SURVEYS.all}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Survey, PermissionEnum.VIEW)}
                me={me}
                loggedIn={loggedIn}
              >
                <Surveys />
              </RequireAuth>
            }
          />
          <Route
            path={SURVEYS.update}
            element={
              <RequireAuth
                roles={rolesForModule(OlaggModule.Survey, PermissionEnum.EDIT)}
                me={me}
                loggedIn={loggedIn}
              >
                <SurveysEdit />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </ThirdwebProvider >
  )
}

export default App
