import { Center, Text } from '@chakra-ui/react'
import { useAdminUserContent } from '@olagg/api-hooks'
import { useParams } from 'react-router-dom'
import UserContentForm from '../components/Forms/UserContentForm'

interface IProps {
  mode: 'edit' | 'create'
}

// TODO: Use the param to get the mode. By default will be 'view'
const ManageUserContent = ({ mode }: IProps) => {
  const params = useParams()

  const {
    userContent,
    error,
    isLoading,
    mutate
  } = useAdminUserContent(params?.id || '')

  if (userContent) return <UserContentForm mode={mode} userContent={userContent} mutate={mutate} />

  return (
    <Center h="80vh" color="white">
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (error || !userContent) && (
        <Text>User Content not found</Text>
      )}
    </Center>
  )
}

export default ManageUserContent