import {
    Flex,
    useToast,
    Spinner,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { useForm, Controller, FormProvider } from 'react-hook-form'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    fetcher,
    AdminPaymentsEndpoints,
} from '@olagg/api-hooks'
import OlaggFormInput from "../../../components/Forms/OlaggFormInput";

type FormData = {
    reason: number,
}

type PaymentParentAttributes = {
    type: string,
    title: string
}

const schema: Yup.Schema<FormData> = Yup
    .object({
        reason: Yup.string().required('El motivo es obligatorio'),
    })
    .required()


const ModalCancel = ({ isOpen, onClose, selectedPayment, refresh }: { isOpen: boolean, onClose: () => void, selectedPayment: {}, refresh: () => void }) => {

    const toast = useToast()
    const [isLoading, setIsLoading] = useState(false)

    const useFormMethods = useForm<FormData>({
        resolver: yupResolver(schema),
        mode: 'onChange',
    });

    const errors = useFormMethods.formState.errors;

    const onSubmit = async (data) => {
        try {
            // await fetcher(AdminPaymentsEndpoints.update(1, { status: 'pending', address: null, reason: null, link: null }))
            await fetcher(AdminPaymentsEndpoints.update(`${selectedPayment.id}`, { status: 'canceled', reason: data.reason }))
            toast({
                title: 'Pago cancelado',
                colorScheme: 'olaggPink',
                status: 'success',
                description: '',
                position: 'bottom-right'
            })
            useFormMethods.reset()
            closeModal()
            refresh()
        } catch (error) {
            console.log(error);
            toast({
                title: 'Error',
                colorScheme: 'olaggYellow',
                status: 'error',
                description: error.message,
                position: 'bottom-right'
            })
        }
    }

    const closeModal = () => {
        useFormMethods.reset()
        onClose()
    }

    return (

        <Modal isOpen={isOpen} onClose={closeModal} isCentered size='xl'>
            <ModalContent mx='5'>
                <ModalCloseButton />
                <ModalBody textAlign='center' py='8'>
                    <Flex flexDirection={'column'} justifyContent='center' alignItems={'center'} gap='10px'>
                        <Flex fontSize={'16px'} fontWeight='700'>
                            ¿Estás seguro que querés cancelar el pago?
                        </Flex>
                        <Flex fontSize={'14px'} fontWeight='400'>
                            {selectedPayment?.token} {selectedPayment?.quantity} a usuario <Text ml='5px' fontWeight={'700'}>{selectedPayment?.user?.name}</Text>
                        </Flex>

                        <FormProvider {...useFormMethods}>
                            <form onSubmit={useFormMethods.handleSubmit(onSubmit)} style={{ width: '100%' }}>
                                <OlaggFormInput
                                    inputType="textarea"
                                    controlName='reason'
                                    placeholder='Motivo'
                                    customStyles={{
                                        marginTop: '10px',
                                        fontWeight: '700',
                                        minHeight: '200px'
                                    }}
                                />

                                <Button
                                    variant={'filledGradient'}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    type="submit"
                                    w={'full'}
                                >
                                    Sí, cancelar pago
                                </Button>

                                <Button
                                    variant='outline'
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    type="submit"
                                    w={'full'}
                                    style={{
                                        marginTop: '10px',
                                        color: 'black',
                                        borderColor: 'black'
                                    }}
                                    onClick={closeModal}
                                >
                                    Volver
                                </Button>

                            </form>
                        </FormProvider>

                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
};

export default ModalCancel;
