import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box } from '@chakra-ui/react'
import OlaggForm from '../../../components/Forms/OlaggForm'
import OlaggFormInput from '../../../components/Forms/OlaggFormInput'
import { Role } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formRolesSchema = Yup.object({
  name: Yup
    .string()
    .required('Introduce el nombre del rol'),
  description: Yup
    .string()
    .optional()
});

interface RolesFormProps {
  role?: Role;
  onSubmit: (dataForm: FormRolesData) => void;
};

export type FormRolesData = InferType<typeof formRolesSchema>;

const RolesForm: FC<RolesFormProps> = ({ role, onSubmit }) => {
  const useFormMethods = useForm<FormRolesData>({
    resolver: yupResolver(formRolesSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (role) {
      const dataForm: FormRolesData = {
        ...role
      }

      useFormMethods.reset(dataForm);
    }
  }, [role]);

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        <Box w='full'>
          <OlaggFormInput
            label='Nombre'
            controlName='name'
            required={true}
          />

          <OlaggFormInput
            label='Descripción'
            controlName='description'
            required={false}
          />
        </Box>

        <Button
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default RolesForm;
