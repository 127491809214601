import { Role } from "@olagg/db-types"
import { AdminRolesEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { useToast } from "@chakra-ui/react"
import { IUseRoles } from "../interfaces/IUseRoles"
import { useQ } from "./useQ"
import { useState } from "react"
import { useRolesStore } from "@olagg/store"

export const useAdminRoles = (filter: IUseRoles) => {
  const toast = useToast();
  const { initRoles } = useRolesStore();
  const [role, setRole] = useState<Role>();

  const response = useQ<{roles: Role[], meta: {count: number}}>(AdminRolesEndpoints.roles(filter));

  const success_toast = (description: string) => {
    toast({
      title: 'Rol',
      colorScheme: 'olaggPink',
      status: 'success',
      description,
      position: 'bottom-right',
      isClosable: true
    });
  }
  
  const error_toast = (error: any) => {
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: error.errors,
      position: 'bottom-right',
      isClosable: true
    });
  }

  const createRole = async (data: Partial<Role>, callback?: (newRole: Role) => void) => {
    return await fetcher(AdminRolesEndpoints.createRole(data))
      .then((newRole) => {
        success_toast('Rol creado con éxito');
        initRoles();
        response.mutate();
        callback !== undefined && callback(newRole);
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const findRole = (id: number) => {
    fetcher(AdminRolesEndpoints.findRole(id))
      .then(res => setRole(res))
  }
  
  const updateRole = async (id: number, data: Partial<Role>, callback?: (newRole: Role) => void) => {
    return await fetcher(AdminRolesEndpoints.updateRole(id, data))
      .then((newRole) => {
        success_toast('Rol actualizado con éxito');
        initRoles();
        response.mutate();
        callback !== undefined && callback(newRole);
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const deleteRole = async (id: number) => (
    fetcher(AdminRolesEndpoints.deleteRole(id))
      .then(() => {
        success_toast('Rol eliminado con éxito');
        initRoles();
        response.mutate();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  )

  return {
    ...response,
    role,
    createRole,
    findRole,
    updateRole,
    deleteRole,
  }
}
