import { FC, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Box, Button, Center, Flex, Heading } from '@chakra-ui/react'
import { useAdminQuests } from '@olagg/api-hooks'
import QuestTable from '../components/Tables/QuestTable'
import { IUseQuests } from '@olagg/api-hooks/interfaces/IUseQuests'
import { ROUTES } from '../consts/routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '@olagg/ui-kit/Pagination'
import QuestFilter from '../components/Filters/QuestFilter'

const Quest: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState<IUseQuests>({
    title: searchParams.get('title') || undefined,
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10),
    visible: (searchParams.get('visible')?.toLowerCase() as 'true' | 'false') || undefined,
    type: searchParams.get('type') || undefined,
    owner_id: searchParams.get('owner_id') || undefined,
  })

  const { title, page, limit, visible, type, owner_id } = filter

  const {
    quests,
    count,
    error,
    isLoading: isLoadingQuest
  } = useAdminQuests({ title, page, limit, visible, type, owner_id })

  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({
      ...filter,
      [name]: value,
      ...(name !== 'page' && { page: 1 })
    })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)

    if (name !== 'page') searchParams.set('page', '1')

    setSearchParams(searchParams)
  }

  return (
    <Box color="white" flexDirection="column">
      <Heading textAlign="center" my={2}>
        Quest
      </Heading>

      <Center display="block" px={8}>
        <Flex alignItems='center' w='full'>
          <Button
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.QUESTS.create)}
            mb='3'
          >
            Crear Quest
          </Button>
          <QuestFilter onFilter={onFilter} filter={filter} />
        </Flex>
        <Box overflow="auto" mt={4} w="75vw" h="60vh">
          <QuestTable
            quests={quests}
            filter={filter}
            onFilter={onFilter}
            isLoading={isLoadingQuest}
            error={!!error}
          />
        </Box>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Center>
    </Box>
  )
}

export default Quest
