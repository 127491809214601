import { FC, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Box, Button, Center, Flex, Heading } from '@chakra-ui/react'
import { useAdminOwnerQuests } from '@olagg/api-hooks'
import QuestOwnersTable from '../components/Tables/QuestOwnersTable'
import { IUseQuestOwners } from '@olagg/api-hooks/interfaces/IUseQuestOwners'
import { ROUTES } from '../consts/routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Pagination from '@olagg/ui-kit/Pagination'
import QuestOwnerFilter from '../components/Filters/QuestOwnersFilter'
import useRoles from '../features/Roles/hooks/useRoles'
import { OlaggModule, PermissionEnum } from '@olagg/db-types'

const QuestOwners: FC = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { missingPermission } = useRoles();

  const [filter, setFilter] = useState<IUseQuestOwners>({
    name: searchParams.get('title') || undefined,
    page: Number(searchParams.get('page') || 0),
    limit: Number(searchParams.get('limit') || 10)
  })

  const { page, limit, name } = filter

  const {
    owners,
    count,
    error,
    isLoading: isLoadingQuestOwners
  } = useAdminOwnerQuests({ page, limit, name })


  const onFilter = ({
    name,
    value
  }: { name: string, value: any }) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  return (
    <Box color="white" flexDirection="column">
      <Heading textAlign="center" my={2}>
        Owners
      </Heading>

      <Center display="block" px={8}>
        <Flex>
          <QuestOwnerFilter onFilter={onFilter} />
          <Button
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.OWNERS.create)}
            isDisabled={missingPermission(OlaggModule.Owner, PermissionEnum.CREATE)}
          >
            Crear Owner
          </Button>
        </Flex>
        <Box overflow="auto" mt={4} w="75vw" h="60vh">
          <QuestOwnersTable
            questOwners={owners}
            filter={filter}
            onFilter={onFilter}
            isLoading={isLoadingQuestOwners}
            error={!!error}
          />
        </Box>
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: page + 1
            })
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            })
          }
          hideLimitInput
        />
      </Center>
    </Box>
  )
}

export default QuestOwners
