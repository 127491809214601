import { AdminTreasuresGameEndpoints, fetcher } from "@olagg/api-hooks";
import { Center, Heading, Spinner, VStack, useToast } from "@chakra-ui/react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import MiniGameForm from "./Form";
import { ROUTES } from "../../consts/routes";
import { TreasuresGame } from '@olagg/db-types'
import { useMiniGame } from "./hooks/useMiniGame";
import { useMiniGames } from "./hooks/useMiniGames";

const EditMiniGame = () => {
  const { id } = useParams<{ id: string }>();
  const toast = useToast();
  const navigate = useNavigate();
  const {miniGame, mutate} = useMiniGame(id!)

  const onSubmit = (treasuresGameData: Partial<TreasuresGame>) =>
    fetcher(AdminTreasuresGameEndpoints.update(id!, treasuresGameData))
      .then(() => {
        toast({
          title: 'Mini Juego',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Mini juego editado con éxito`,
          position: 'bottom-right'
        });
        mutate();
        navigate(ROUTES.MINI_GAMES.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Mini Juego
        </Heading>
        {miniGame ? 
          <MiniGameForm onSubmit={onSubmit} miniGame={miniGame} /> :
          <Spinner>
            Cargando...
          </Spinner>}
      </VStack>
    </Center>
  )
}

export default EditMiniGame