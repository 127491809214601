import { Center, Heading } from '@chakra-ui/react'
import { ColouredLogoWithText } from '@olagg/ui-kit/svg/ColouredLogoWithText'

const Home = () => {
  return (
    <Center color='white' flexDirection='column'>
      <Heading textAlign='center' my='5'>
        Team
      </Heading>

      <Center mt='2' w='full' h='65vh'>
        <ColouredLogoWithText />      
      </Center>
    </Center>
  )
}

export default Home
