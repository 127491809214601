import { Alert } from "@olagg/db-types"
import { AdminAlertsEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { useToast } from "@chakra-ui/react"
import { IUseAlerts } from "../interfaces/IUseAlerts"
import { useQ } from "./useQ"
import { useState } from "react"

export const useAdminAlerts = (filter: IUseAlerts) => {
  const toast = useToast();
  const [alert, setAlert] = useState<Alert>();

  const response = useQ<{alerts: Alert[], meta: {count: number}}>(AdminAlertsEndpoints.all(filter));

  const success_toast = (description: string) => {
    toast({
      title: 'Alertas',
      colorScheme: 'olaggPink',
      status: 'success',
      description,
      position: 'bottom-right'
    });
  }
  
  const error_toast = (error: any) => {
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: error.errors,
      position: 'bottom-right'
    });
  }

  const createAlert = async (data: Partial<Alert>, callback: () => void) => {
    return await fetcher(AdminAlertsEndpoints.create(data))
      .then(() => {
        success_toast('Alerta creada con éxito');
        response.mutate();
        callback();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const updateAlert = async (id: number, data: Partial<Alert>, callback: () => void) => {
    return await fetcher(AdminAlertsEndpoints.update(id, data))
      .then(() => {
        success_toast('Alerta actualizada con éxito');
        response.mutate();
        callback();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const deleteAlert = async (id: number) => (
    fetcher(AdminAlertsEndpoints.delete(id))
      .then(() => {
        success_toast('Alerta eliminada con éxito');
        response.mutate();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  )

  const find = (id: number) => {
    fetcher(AdminAlertsEndpoints.find(id))
      .then(res => setAlert(res.alert))
  }

  return {
    ...response,
    alert,
    find,
    createAlert,
    updateAlert,
    deleteAlert,
  }
}
