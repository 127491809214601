import type { Game } from '@olagg/db-types'
import { AdminGameEndpoints } from '../endpoints/adminEndpoints'
import { fetcher } from '../fetcher'
import { useQ } from './useQ'

export const useAdminGames = ({
  title,
  page,
  limit
}: {
  title?: string
  page: number
  limit: number
}) =>{
  const response = useQ<{games: Game[], meta: {count: number}}>(
    AdminGameEndpoints.all({title, page, limit}), { revalidateOnFocus: false }
  )

  // On demand game search for use with searchable dropdowns
  const asyncGameOptions = async (input: string) => {
    return new Promise(async (resolve) => {
      const { games } = await fetcher<{ games: Game[], meta: { count: number } }>(AdminGameEndpoints.all({title: input, page: 1, limit: 50}))

      const gameOptions = games?.map(game => ({
        value: game.id, label: game.title
      }));

      resolve(gameOptions);
    });  
  }

  return {
    ...response,
    games: response.data?.games ?? [],
    count: response.data?.meta.count ?? 0,
    asyncGameOptions,
  }
}
