import { RelevantAction } from "@olagg/db-types"
import { AdminRelevantActionsEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { useToast } from "@chakra-ui/react"
import { IUseRelevantActions } from "../interfaces/IUseRelevantActions"
import { useQ } from "./useQ"
import { useState } from "react"

export const useAdminRelevantActions = (filter: IUseRelevantActions) => {
  const toast = useToast();
  const [relevantAction, setRelevantAction] = useState<RelevantAction>();

  const response = useQ<{relevantActions: RelevantAction[], meta: {count: number}}>(AdminRelevantActionsEndpoints.all(filter));

  const success_toast = (description: string) => {
    toast({
      title: 'Acción Relevante',
      colorScheme: 'olaggPink',
      status: 'success',
      description,
      position: 'bottom-right'
    });
  }
  
  const error_toast = (error: any) => {
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: error.errors,
      position: 'bottom-right'
    });
  }

  const createRelevantAction = async (data: Partial<RelevantAction>, callback: () => void) => {
    return await fetcher(AdminRelevantActionsEndpoints.create(data))
      .then(() => {
        success_toast('Acción relevante creada con éxito');
        response.mutate();
        callback();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const updateRelevantAction = async (id: number, data: Partial<RelevantAction>, callback: () => void) => {
    return await fetcher(AdminRelevantActionsEndpoints.update(id, data))
      .then(() => {
        success_toast('Acción relevante actualizada con éxito');
        response.mutate();
        callback();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const deleteRelevantAction = async (id: number) => (
    fetcher(AdminRelevantActionsEndpoints.delete(id))
      .then(() => {
        success_toast('Acción relevante eliminada con éxito');
        response.mutate();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  )

  const find = (id: number) => {
    fetcher(AdminRelevantActionsEndpoints.find(id))
      .then(res => setRelevantAction(res.relevantAction))
  }

  return {
    ...response,
    relevantAction,
    find,
    createRelevantAction,
    updateRelevantAction,
    deleteRelevantAction,
  }
}
