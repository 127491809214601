import { Center, Text } from '@chakra-ui/react'
import { useAdminQuestOwner } from '@olagg/api-hooks'
import { useParams } from 'react-router-dom'
import QuestOwnerForm from '../components/Forms/QuestOwnerForm'

interface IProps {
  mode: 'edit' | 'create'
}

// TODO: Use the param to get the mode. By default will be 'view'
const ManageQuestOwner = ({ mode }: IProps) => {
  const params = useParams()

  const {
    owner,
    error,
    isLoading,
    mutate
  } = useAdminQuestOwner(params?.id || '')

  if (owner || mode === 'create') return <QuestOwnerForm mode={mode} owner={owner} mutate={mutate} />

  return (
    <Center h="80vh" color="white">
      {isLoading && <Text>Loading...</Text>}
      {!isLoading && (error || !owner) && (
        <Text>Owner not found</Text>
      )}
    </Center>
  )
}

export default ManageQuestOwner
