import { FC, useState } from 'react';
import {
  Center,
  Heading,
  VStack,
  Table as ChakraTable,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Button,
  Box,
  Text,
  Spinner,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Portal,
  useToast,
  Flex,
  Tooltip,
} from '@chakra-ui/react';
import { fetcher, AdminTokensEndpoints, useAdminTokens } from '@olagg/api-hooks';
import { TokenData } from '@olagg/web3/dist/domain/entities/token.entity'
import { useNavigate, useSearchParams, generatePath } from 'react-router-dom';
import { ROUTES } from '../../consts/routes';
import { FaPlus, FaRegClone } from 'react-icons/fa';
import { IUseTokens } from '@olagg/api-hooks/interfaces/IUseTokens';
import Pagination from '@olagg/ui-kit/Pagination';
import { OlaggModule, PermissionEnum } from '@olagg/db-types';
import useRoles from '../../features/Roles/hooks/useRoles';

const addressStyle = {
  '&:hover': {
    background: 'none',
    color: 'gray.500'
  }
}

const copyToClipboard = (text: string) => () => {
  navigator.clipboard.writeText(text)
}

const Tokens: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { missingPermission } = useRoles();
  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = useState<IUseTokens>({
    name: searchParams.get('name') || '',
    page: Number(searchParams.get('page') || 1),
    limit: Number(searchParams.get('limit') || 10)
  })

  const onFilter = ({
    name,
    value
  }: EventTarget & HTMLInputElement) => {
    setFilter({ ...filter, [name]: value })

    if (value) searchParams.set(name, value)
    else searchParams.delete(name)
    setSearchParams(searchParams)
  }

  const { page, limit } = filter

  const { count, tokens, isLoading, mutate } = useAdminTokens({ page, limit })

  const editToken = (token: TokenData) => {
    let path = generatePath(ROUTES.TOKENS.update, { id: token.id });
    navigate(path, { state: { data: token } })
  }

  const editWhitelist = (token: TokenData) => {
    let path = generatePath(ROUTES.TOKENS.whitelist, { tokenId: token.id });
    navigate(path, { state: { data: token } })
  }

  const deleteToken = (tokenId: string) => {
    fetcher(AdminTokensEndpoints.delete(tokenId))
      .then(() => {
        mutate();
        toast({
          title: 'Token eliminado',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Token eliminado con éxito',
          position: 'bottom-right'
        });
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} >
        <Heading my={2}>
          Tokens
        </Heading>

        <Button
          display='flex'
          alignSelf='self-start'
          variant="outlinedGradient"
          leftIcon={<FaPlus />}
          onClick={() => navigate(ROUTES.TOKENS.create)}
          isDisabled={missingPermission(OlaggModule.Token, PermissionEnum.CREATE)}
        >
          Agregar Token
        </Button>

        {isLoading ?
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
          :
          <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh">
            <ChakraTable
              variant="striped"
              colorScheme="olaggPurple"
              fontSize="18px"
            >
              <Thead>
                <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
                  <Th color="white">Imagen</Th>
                  <Th color="white">Nombre</Th>
                  <Th color="white">Blockchain</Th>
                  <Th color="white">Address</Th>
                  <Th color="white">Tipo</Th>
                  <Th color="white" textAlign='center'>Token Id</Th>
                  <Th color="white">Clasificación</Th>
                  <Th color="white">Sección</Th>
                  <Th color="white" textAlign='center'>Level</Th>
                  <Th textAlign='center' color="white">Acción</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tokens.map((token, index) => (
                  <Tr key={index}>
                    <Td>
                      <img src={token.image} alt={token.name} width='50px' />
                    </Td>
                    <Td>{token.name}</Td>
                    <Td maxWidth='100px'>{token.network.toUpperCase()}</Td>
                    <Td>
                      <Tooltip label={token.address}>
                        {`${token.address.slice(0, 5)}...${token.address.slice(-5)}`}
                      </Tooltip>
                      <Button
                        minW='1rem'
                        height='1rem'
                        ml='0.4rem' p='0'
                        background={'none'}
                        sx={addressStyle}
                        onClick={copyToClipboard(token.address)}
                      >
                        <FaRegClone color="inherit" />
                      </Button>
                    </Td>
                    <Td>{token.type.toUpperCase()}</Td>
                    <Td textAlign='center'>{token.tokenId}</Td>
                    <Td>
                      {token.classification.icon} {token.classification.classificationType.toUpperCase()}
                    </Td>
                    <Td>{token.section.toUpperCase()}</Td>
                    <Td textAlign='center'>{token.requiredLevel}</Td>
                    <Td textAlign='center'>
                      <Flex gap='3'>
                        <Button size={'sm'} padding='0px 8px' colorScheme='green'
                          onClick={() => editToken(token)}
                          isDisabled={missingPermission(OlaggModule.Token, PermissionEnum.EDIT)}
                        >
                          Editar
                        </Button>

                        {token.mint?.snapshotRequired && (
                          <Button size={'sm'} padding='0px 8px' colorScheme='blue'
                            onClick={() => editWhitelist(token)}
                            isDisabled={missingPermission(OlaggModule.TokenAssignment, PermissionEnum.VIEW)}
                          >
                            Whitelist
                          </Button>
                        )}

                        <Popover>
                          <PopoverTrigger>
                            <Button
                              size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                              isDisabled={missingPermission(OlaggModule.Token, PermissionEnum.DELETE)}
                            >
                              Eliminar
                            </Button>
                          </PopoverTrigger>
                          <Portal>
                            <PopoverContent>
                              <PopoverArrow />
                              <PopoverCloseButton />
                              <PopoverBody>
                                <Button width={'100%'} colorScheme='red' onClick={() => deleteToken(token.id!)}
                                >
                                  Confirmar Eliminar
                                </Button>
                              </PopoverBody>
                            </PopoverContent>
                          </Portal>
                        </Popover>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </ChakraTable>
          </TableContainer>
        }
        <Pagination
          params={{ limit, page, count }}
          onClick={page =>
            onFilter({
              name: 'page',
              value: String(page + 1)
            } as EventTarget & HTMLInputElement)
          }
          onLimitChange={val =>
            onFilter({
              name: 'limit',
              value: val
            } as EventTarget & HTMLInputElement)
          }
          hideLimitInput
        />
      </VStack>
    </Center>
  )
};

export default Tokens;
