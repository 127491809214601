import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, TreasuresGame } from "@olagg/db-types";
import {ROUTES, Routes} from '../../consts/routes'
import { Link as ReactLink, generatePath, useNavigate } from "react-router-dom";

import { Table as ChakraTable } from "@chakra-ui/react";
import useRoles from "../../features/Roles/hooks/useRoles";

const Table = ({miniGames, deleteMiniGame}:{miniGames: TreasuresGame[], deleteMiniGame: (id: string) => void }) => {
  const { missingPermission } = useRoles();
  const navigate = useNavigate()

  const disableDeleteMiniGame = (miniGame: TreasuresGame) => {
    if (missingPermission(OlaggModule.MiniGame, PermissionEnum.DELETE)) {
      return true;
    }
  
    if (miniGame.status === 'active') {
      return true;
    }
  
    return false;
  };

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white" maxW='200px'>Título</Th>
            <Th color="white">Slug</Th>
            <Th color="white">Id Organizador</Th>
            
            <Th color="white">Fecha de Inicio</Th>
            <Th color="white">Fecha de Fin</Th>
            <Th color="white">Estado</Th>
            <Th color="white" textAlign='center'>Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {miniGames && miniGames.map((miniGame, index) => (
            <Tr key={index} _hover={{ color: 'cyan' }}>
              <Td>{miniGame.title}</Td>
              <Td>{miniGame.slug}</Td>
              <Td>{miniGame.organizer?.name}</Td>
              <Td>{miniGame.startTime && new Date(miniGame.startTime).toDateString()}</Td>
              <Td>{miniGame.endTime && new Date(miniGame.endTime).toDateString()}</Td>
              {/* <Td>{`/torneos/${slugify(tournament.title)}`}</Td> */}
              <Td>{miniGame.status}</Td>
              <Td textAlign='center'>
                <Flex gap='3'>
                  <Button as={ReactLink} size={'sm'} padding='0px 8px' colorScheme='green'
                    isDisabled={missingPermission(OlaggModule.MiniGame, PermissionEnum.EDIT)}
                    to={generatePath(ROUTES.MINI_GAMES.update, { id: miniGame.id })}
                  >
                    Editar
                  </Button>

                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        isDisabled={disableDeleteMiniGame(miniGame)}
                      >
                        Eliminar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red'
                            onClick={() => deleteMiniGame(miniGame.id!)}
                          >
                            Confirmar Eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table