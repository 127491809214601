import { fetcher, AdminPaymentsEndpoints } from "@olagg/api-hooks";
import { useState } from "react";

const usePayments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentWinnersStatusses, setTournamentWinnersStatusses] = useState([]);
  const [tournamentWinnersErrors, setTournamentWinnersErrors] = useState(0);

  const createPaymentsForTournamentWinners = async (tournamentId: number, network: string) => {
    setIsLoading(true);
    try {
      const response = await fetcher(AdminPaymentsEndpoints.createPaymentsForTournamentWinners(tournamentId, network));
      setTournamentWinnersStatusses(response.payment_statuses);
      setTournamentWinnersErrors(response.payment_errors);
    } catch (error: any) {
      console.error(error.error);
      setTournamentWinnersStatusses(error.error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetTournamentWinnersStatusses = () => {
    setTournamentWinnersStatusses([]);
    setTournamentWinnersErrors(0);
  };

  return {
    isLoading,
    tournamentWinnersStatusses,
    tournamentWinnersErrors,
    createPaymentsForTournamentWinners,
    resetTournamentWinnersStatusses
  }
}

export default usePayments;
