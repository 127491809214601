import { Center, Heading, VStack, Button, Flex, Box, Spinner } from '@chakra-ui/react';
import { useAdminRoles } from '@olagg/api-hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../consts/routes';
import { FaPlus } from 'react-icons/fa';
import Table from './Table';

const Roles = () => {
  const navigate = useNavigate();
  const { data, isLoading, deleteRole } = useAdminRoles({ page: 1, limit: 100 })
  const roles = data?.roles;

  return (
    <Center w='full' pb={4}>
      <VStack color={'white'} w='full'>
        <Heading my={2}>
          Roles
        </Heading>

        <Flex w='full' justifyContent='space-between' paddingRight="22px">
          <Button
            display='flex'
            alignSelf='self-start'
            variant="outlinedGradient"
            leftIcon={<FaPlus />}
            onClick={() => navigate(ROUTES.ROLES.create)}
          >
            Añadir Rol
          </Button>
        </Flex>

        {isLoading ? (
          <Box height='300px' display='flex' justifyContent='center' alignItems='center' fontWeight='600' flexDirection='column'>
            <Spinner />
          </Box>
        ) : (
          roles && <Table roles={roles} deleteRole={deleteRole} />
        )}
      </VStack >
    </Center >
  )
};

export default Roles;
