import {
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import { useNavigate, NavLink, useLocation } from 'react-router-dom'
import { ISidebarRoute } from '../Navigation/routes'
import useRoles from '../../features/Roles/hooks/useRoles'
import { PermissionEnum } from '@olagg/db-types'
import { ROUTES } from '../../consts/routes'

interface IProps {
  routes: ISidebarRoute[]
  logo: ReactElement
}

const SidebarContent = ({ routes, logo }: IProps) => {
  let { pathname } = useLocation()
  pathname = `/${pathname.split('/')[1]}`
  const { missingPermission } = useRoles();
  const navigate = useNavigate();

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName: string) =>
    routeName.includes(pathname) ? 'active' : ''

  const createLinks = (routes: ISidebarRoute[]) => {
    const activeBg = useColorModeValue(
      'olaggDark.100',
      'gray.700'
    )
    const inactiveColor = useColorModeValue(
      'gray.400',
      'gray.400'
    )
    const hoverColor = useColorModeValue(
      'olaggDark.100',
      'olaggDark.100'
    )

    return routes.map(({ path, name, icon, module }: ISidebarRoute) => {
      return (
        <NavLink to={path} key={name}>
          {activeRoute(path) === 'active' ? (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg={activeBg}
              mb={{ xl: '12px' }}
              mx={{ xl: 'auto' }}
              ps={{ sm: '10px', xl: '16px' }}
              py="12px"
              borderRadius="15px"
              w="100%"
              _focus={{ boxShadow: '2xl' }}
              _hover={{ bg: hoverColor }}
            >
              <Flex alignItems='center'>
                {icon}
                <Text color="white" fontSize="sm" ml={5} whiteSpace='break-spaces' textAlign='left'>
                  {name}
                </Text>
              </Flex>
            </Button>
          ) : (
            <Button
              boxSize="initial"
              justifyContent="flex-start"
              alignItems="center"
              bg="transparent"
              _hover={{ bg: hoverColor }}
              mb={{ xl: '12px' }}
              mx={{ xl: 'auto' }}
              py="12px"
              ps={{ sm: '10px', xl: '16px' }}
              borderRadius="15px"
              w="100%"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'red'
              }}
              _focus={{ boxShadow: 'none' }}
              isDisabled={missingPermission(module, PermissionEnum.VIEW)}
            >
              <Flex alignItems='center'>
                {icon}
                <Text color={inactiveColor} ml={5} fontSize="sm"  whiteSpace='break-spaces' textAlign='left'>
                  {name}
                </Text>
              </Flex>
            </Button>
          )}
        </NavLink>
      )
    })
  }

  return (
    <>
      <Box pt="22px" mb="12px">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mb="10px"
          onClick={() => navigate(ROUTES.HOME)}
          cursor='pointer'
        >
          {logo}
        </Box>
        <Divider />
      </Box>
      <Box>{createLinks(routes)}</Box>
    </>
  )
}

export default SidebarContent
