import { RolePermission, CreateRolePermission } from "@olagg/db-types"
import { AdminRolesEndpoints } from "../endpoints/adminEndpoints"
import { fetcher } from "../fetcher"
import { useToast } from "@chakra-ui/react"
import { IUseRolesPermissions } from "../interfaces/IUseRolesPermissions"
import { useQ } from "./useQ"
import { useState } from "react"
import { useRolesStore } from "@olagg/store"

export const useAdminRolesPermissions = (filter: IUseRolesPermissions) => {
  const toast = useToast();
  const { initPermissions } = useRolesStore();
  const [rolesPermission, setRolesPermission] = useState<RolePermission>();

  const response = useQ<{permissions: RolePermission[], meta: {count: number}}>(AdminRolesEndpoints.rolesPermissions(filter));

  const success_toast = (description: string) => {
    toast({
      title: 'Permisos de Rol',
      colorScheme: 'olaggPink',
      status: 'success',
      description,
      position: 'bottom-right',
      isClosable: true
    });
  }
  
  const error_toast = (error: any) => {
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: error.errors,
      position: 'bottom-right',
      isClosable: true
    });
  }

  const createRolesPermission = async (data: Partial<CreateRolePermission>, callback?: (newPermission: RolePermission) => void) => {
    return await fetcher(AdminRolesEndpoints.createRolesPermission(data))
      .then((newPermission) => {
        success_toast('Permiso creado con éxito');
        initPermissions();
        response.mutate();
        callback !== undefined && callback(newPermission);
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const findRolesPermission = (id: number) => {
    fetcher(AdminRolesEndpoints.findRolesPermission(id))
      .then(res => setRolesPermission(res))
  }
  
  const updateRolesPermission = async (id: number, data: Partial<RolePermission>, callback?: (updatedPermission: RolePermission) => void) => {
    return await fetcher(AdminRolesEndpoints.updateRolesPermission(id, data))
      .then((updatedPermission) => {
        success_toast('Permiso actualizado con éxito');
        initPermissions();
        response.mutate();
        callback !== undefined && callback(updatedPermission);
      })
      .catch((error: any) => {
        error_toast(error);
      })
  }

  const deleteRolesPermission = async (id: number) => (
    fetcher(AdminRolesEndpoints.deleteRolesPermission(id))
      .then(() => {
        success_toast('Permiso eliminado con éxito');
        initPermissions();
        response.mutate();
      })
      .catch((error: any) => {
        error_toast(error);
      })
  )

  return {
    ...response,
    rolesPermission,
    createRolesPermission,
    findRolesPermission,
    updateRolesPermission,
    deleteRolesPermission,
  }
}
