import {
    Flex,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    Text,
    Badge
} from "@chakra-ui/react";
import moment from 'moment/moment.js'
import 'moment/min/locales'
import OlaggMarkdown from "@olagg/ui-kit/OlaggMarkdown";


const ModalDetail = ({ isOpen, onClose, selectedSurvey }: { isOpen: boolean, onClose: () => void, selectedSurvey: {} }) => {

    const Status = (props) => {
        let start = props.start_date

        let end = props.end_date
        let targetDate = moment();
        let isWithinRange = targetDate.isBetween(start, end, undefined, '[]');
        switch (props.visible) {
            case true:
                return (
                    <Flex gap='8px'>
                        <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#37A169'} fontWeight='500' textTransform={'none'}>Visible</Badge>
                        {isWithinRange && <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'85px'} color='black' bgColor={'#38ABFF'} fontWeight='500' textTransform={'none'}>En progreso</Badge>}
                    </Flex>
                )
                break;
            case false:
                return (
                    <Flex gap='8px'>
                        <Badge borderRadius={'20px'} textAlign='center' fontSize={'14px'} py={'2px'} width={'75px'} color='black' bgColor={'#E53E3E'} fontWeight='500' textTransform={'none'}>NO Visible</Badge>
                    </Flex>
                )
                break;
            default:
                return <></>
                break
        }
    }

    return (

        <Modal isOpen={isOpen} onClose={onClose} isCentered size='3xl'>
            <ModalContent mx='5'>
                <ModalCloseButton />
                <ModalBody textAlign='center' py='8'>
                    <Flex flexDirection={'column'} justifyContent='center' gap='10px'>
                        <Flex fontSize={'20px'} fontWeight='700'>
                            {selectedSurvey?.title}
                        </Flex>
                        <Flex maxH={'600px'} overflowY='auto'>
                            <Flex fontSize={'14px'} fontWeight='400' flexDirection={'column'} gap="10px">
                                <Flex gap='5px' flexDirection={'column'} gap='6px'>
                                    <Text fontWeight={'700'} textAlign='left'>Imagen de Portada</Text>
                                    <img src={selectedSurvey?.cover_image} />
                                </Flex>
                                <Flex gap='5px' flexDirection={'column'} gap='6px'>
                                    <Text fontWeight={'700'} textAlign='left'>Imagen de Fondo</Text>
                                    <img src={selectedSurvey?.background_image} />
                                </Flex>
                                
                                <Flex gap='5px'><Text fontWeight={'700'}>ID:</Text> {selectedSurvey?.id}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Tema:</Text> {selectedSurvey?.theme}</Flex>
                                <Flex gap='5px' flexDirection={'column'} gap='6px'>
                                    <Text fontWeight={'700'} textAlign='left'>Descripción Markdown:</Text>
                                    <OlaggMarkdown>
                                    {selectedSurvey?.description}
                                    </OlaggMarkdown>
                                </Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>OG Title:</Text> {selectedSurvey?.og?.title}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>OG Description:</Text> {selectedSurvey?.og?.description}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Slug:</Text> {selectedSurvey?.slug}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Fecha/Hora Inicio:</Text> {selectedSurvey?.start_date && moment(selectedSurvey?.start_date).format('DD/MM/YYYY HH:mm:ss')}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Fecha/Hora Fin:</Text> {selectedSurvey?.end_date && moment(selectedSurvey?.end_date).format('DD/MM/YYYY HH:mm:ss')}</Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>XP:</Text> {selectedSurvey?.score}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Typeform ID:</Text> {selectedSurvey?.typeform_id}</Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Requiere Login:</Text> {selectedSurvey?.require_login ? 'Si' : 'No'}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Requiere Discord:</Text> {selectedSurvey?.require_discord ? 'Si' : 'No'}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Requiere Wallet:</Text> {selectedSurvey?.require_wallet ? 'Si' : 'No'}</Flex>
                                <Flex gap='5px'><Text fontWeight={'700'}>Requiere Deposit Wallet:</Text> {selectedSurvey?.require_deposit_wallet ? 'Si' : 'No'}</Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Disparar Notificación In-App cuando inicie la encuesta</Text> {selectedSurvey?.notify ? 'Si' : 'No'} </Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Urgente</Text> {selectedSurvey?.urgent ? 'Si' : 'No'} </Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Mostrar Estadísticas de Resultados al responder</Text> {selectedSurvey?.show_statistics_response ? 'Si' : 'No'} </Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Mostrar Estadísticas de Resultados al finalizar</Text> {selectedSurvey?.show_statistics_end ? 'Si' : 'No'} </Flex>

                                <Flex gap='5px'><Text fontWeight={'700'}>Mostrar estadisticas publicamente</Text> {selectedSurvey?.public_statistics ? 'Si' : 'No'} </Flex>

                            </Flex>
                        </Flex>
                        <Flex mt={'10px'}>
                            <Status {...selectedSurvey} />
                        </Flex>
                    </Flex>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
};
export default ModalDetail;
