import { AdminTournamentsEndpoints } from "@olagg/api-hooks"
import { AdminTreasuresGameEndpoints } from "@olagg/api-hooks"
import { IUseTreasuresGame } from "@olagg/api-hooks/interfaces/IUseTreasuresGame"
import { TreasuresGame } from "@olagg/db-types"
import { fetcher } from "@olagg/api-hooks"
import { useQ } from "@olagg/api-hooks"
import { useState } from "react"
import { useToast } from "@chakra-ui/react"

export const useMiniGames = () => {
  const toast = useToast()
  const [miniGames, setMiniGames] = useState<TreasuresGame[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  
  const all = async (filter: IUseTreasuresGame) => {
    setIsLoading(true)
    fetcher(AdminTreasuresGameEndpoints.index(filter))
      .then(response => {
        setCount(response.meta.count)
        setMiniGames(response.treasuresGames)
        setIsLoading(false)
      })
  }

  const deleteMiniGame = async (id: string) => {
    fetcher(AdminTreasuresGameEndpoints.destroy(id))
      .then(() => {
        toast({
          title: 'Mini Juegos',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Mini Juego eliminado con éxito',
          position: 'bottom-right'
        });
        window.location.reload()
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })
  }

  return {
    all,
    deleteMiniGame,
    isLoading,
    miniGames,
    count
  }
}