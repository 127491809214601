import { Flex, Text } from '@chakra-ui/react'
import { GameCategory } from '@olagg/db-types'
import CategoryICon from '../GameCategory/CategoryIcon'

interface IProps {
  selected: boolean
  name: string
  category?: GameCategory
  selectCategory?: (value: GameCategory) => void
}

const Category = ({
  category,
  name,
  selected,
  selectCategory
}: IProps) => {
  const bg = selected ? '#3F2091' : 'gray.200'
  const color = selected ? 'white' : '#3F2091'
  return (
    <Flex
      bgColor={bg}
      color="white"
      p={'4px 10px'}
      borderRadius="4px"
      w="fit-content"
      gap="1"
      align="center"
      cursor="pointer"
      onClick={() =>
        selectCategory && category && selectCategory(category)
      }
    >
      <CategoryICon name={name} selected={selected} />
      <Text fontSize="15px" fontWeight="600" color={color}>
        {name}
      </Text>
    </Flex>
  )
}

export default Category
