import {
  Button,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { IUseQuestOwners } from '@olagg/api-hooks/interfaces/IUseQuestOwners'
import type {
  QuestOwner
} from '@olagg/db-types'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'

interface IProps {
  questOwners?: (QuestOwner)[]
  onFilter: (value: any) => void
  filter: IUseQuestOwners
  isLoading: boolean
  error: boolean
}

const QuestOwnersTable = ({
  questOwners = []
}: IProps) => {

  return (
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' top='0' bg='gray.800' zIndex='100'>
        <Tr>
          <Th color="white">Id</Th>
          <Th color="white">Nombre</Th>
          <Th color="white">Partner</Th>
          <Th color="white">Sitio web</Th>
          <Th color="white">Juego</Th>
          <Th color="white">Discord Id</Th>
          <Th color="white">Discord User</Th>
          <Th color="white">Twitter Id</Th>
          <Th color="white">Twitter User</Th>
          <Th color="white">Acciones</Th>
        </Tr>
      </Thead>
      <Tbody>
        {questOwners?.map(questOwner => (
          <Tr key={questOwner.id}>
            <Td>{questOwner.id}</Td>
            <Td>{questOwner.name}</Td>
            <Td>{questOwner.isPartner ? 'Si' : 'No'}</Td>
            <Td>{questOwner.site}</Td>
            <Td>{questOwner.game?.title}</Td>
            <Td>{questOwner.discordId}</Td>
            <Td>{questOwner.discordUsername}</Td>
            <Td>{questOwner.twitterId}</Td>
            <Td>{questOwner.twitterUsername}</Td>
            <Td>
              <Link to={`${ROUTES.OWNERS.all}/${questOwner.id}`}>
                <Button colorScheme="green" ml='5px' size={'sm'} py='5px' mt='5px'>Ver</Button>
              </Link>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>
  )
}

export default QuestOwnersTable
