import {
  Center,
  Flex,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { AdminUserEndpoints, fetcher } from '@olagg/api-hooks'
import type { User } from '@olagg/db-types'
import { useState } from 'react'
import { IoClose, IoAddOutline } from 'react-icons/io5'
import { AddRoleModal } from './AddRoleModal'

interface IProps {
  users?: User[],
  onUpdate?: () => void
}

const PermissionsTable = ({
  users = [],
  onUpdate = () => {}
}: IProps) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [userToUpdate, setUserToUpdate] = useState<User>();

  if (!users.length) {
    return <Center h="full">No hay resultados</Center>
  }

  const handleAddRole = (user: User) => {
    setUserToUpdate(user);
    onOpen();
  }

  const addRole = async (roleToAdd: string, user: User) => {
    const updatedRoles = [...user.roles, roleToAdd];
    await fetcher(AdminUserEndpoints.update(`${user.id}`, { roles: updatedRoles }))
      .then(() => toast({
        title: `Agregar rol`,
        description: 'Rol agregado correctamente',
        status: 'success',
        position: 'bottom-right',
        duration: 2000,
        isClosable: true  
      }))
      .catch(() => toast({
        title: `Agregar rol`,
        description: 'Error al intentar agregar rol',
        status: 'error',
        position: 'bottom-right',
        duration: 2000,
        isClosable: true  
      }));
    onUpdate();
  }

  const removeRole = async (roleToRemove: string, user: User) => {
    const updatedRoles = user.roles.filter(role => role !== roleToRemove);
    await fetcher(AdminUserEndpoints.update(`${user.id}`, { roles: updatedRoles }))
      .then(() => toast({
        title: `Eliminar rol`,
        description: 'Rol borrado correctamente',
        status: 'success',
        position: 'bottom-right',
        duration: 2000,
        isClosable: true  
      }))
      .catch(() => toast({
        title: `Eliminar rol`,
        description: 'Error al intentar borrar rol',
        status: 'error',
        position: 'bottom-right',
        duration: 2000,
        isClosable: true  
      }));
    onUpdate();
  }

  return (
    <>
    <ChakraTable variant="striped" colorScheme="olaggPurple">
      <Thead position='sticky' top='0' bg='gray.800' zIndex='100'>
        <Tr>
          <Th color="white">Id</Th>
          <Th color="white">User</Th>
          <Th color="white">Email</Th>
          <Th color="white">Roles</Th>
        </Tr>
      </Thead>

      <Tbody>
        {users?.map(user => (
          <Tr key={user.id}>
            <Td>{user?.id}</Td>
            <Td>{user?.name}</Td>
            <Td>{user?.email}</Td>
            <Td display='flex' gap='2' alignItems='center'>
              {user?.roles?.map((role, index) => (
                <Flex key={index} borderRadius='10' bgColor='cyan.800' p='2' border='2px solid #aaa' gap='2'>
                  {role}
                  <Tooltip fontSize='1rem' label='Eliminar Rol' shouldWrapChildren={true}> 
                    <IoClose cursor='pointer' onClick={() => removeRole(role, user)}/>
                  </Tooltip>
                </Flex>
              ))}
              <Flex borderRadius='10' bgColor='green.800' p='2' border='2px solid #aaa' gap='2'>
                <Tooltip fontSize='1rem' label='Agregar Rol' shouldWrapChildren={true}> 
                  <IoAddOutline cursor='pointer' onClick={() => handleAddRole(user)} />
                </Tooltip>
              </Flex>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </ChakraTable>

    <AddRoleModal 
      isOpen={isOpen}
      onClose={onClose}
      user={userToUpdate}
      onChange={addRole}
    />
    </>
  )
}

export default PermissionsTable
