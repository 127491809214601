import { FC } from 'react';
import { FormControl, FormLabel, FormErrorMessage, Text } from '@chakra-ui/react';
import { AsyncSelect, ChakraStylesConfig } from 'chakra-react-select';
import { Controller, useFormContext } from 'react-hook-form';

/**
 * Customized select component for use in OlaGG forms
 *
 * Implements React Hook Forms and validations
 *
 * @param {string} controlName - Name of the select control, as used by React Hook Form
 * @param {string} label - Label to be displayed above the select control
 * @param {string} description - Optional - Description to be displayed below the label
 * @param {Array<any>} staticOptions - Array of strings or {value, label, ...others} with the possible options to be displayed in the select control
 * @param {function} asyncOptions - Optional - Async function to be called when the select control value changes, to fetch the options to be displayed in the select control
 * @param {boolean} isSearchable - Optional - Whether the select control should be searchable, defaults to false
 * @param {boolean} visible - Optional - Whether the select control should be visible, defaults to true
 * @param {boolean} required - Optional - Whether the select control is required, defaults to {visible}
 * @param {string} placeholder - Optional - Placeholder text to be displayed in the select control
 * @param {function} onChange - Optional - Callback function to be called when the select control value changes, receives the new value
 *
 * @example Simple select, static options, not searchable
 *
 * const methods = useForm();
 *
 * const onSubmit = (dataForm: any) => {
 *   console.log(dataForm);
 * };
 *
 * <OlaggForm methods={methods} onSubmit={onSubmit} style={{width: '100%'}}>
 *   <OlaggFormSelect
 *     label='Country'
 *     controlName='country'
 *     description='Choose your country'
 *     staticOptions={[{value: 'arg', label: 'Argentina'}, {value: 'chi', label: 'Chile'}, {value: 'per', label: 'Peru'}]}
 *     required={true}
 *   />
 *
 *   <Button type='submit'>Submit</Button>
 * </OlaggForm>
 *
 * @example Searchable select, with async options
 *
 * const [staticOptions, setStaticOptions] = useState();
 *
 * useEffect(() => {
 *   fetcher(UserEndpoints.top3())
 *   .then(response => setStaticOptions(response.users.map(user => ({
 *     value: user.id,
 *     label: user.name,
 *   }))))
 * }, []);
 *
 * const asyncOptionsFunction = async (input: string) => {
 *   return new Promise((resolve) => {
 *     fetcher(UserEndpoints.paginatedUsers({
 *       query: input,
 *       offset: 1,
 *       limit:15,
 *     }))
 *     .then(response => {
 *       resolve(response.users.map(user => ({
 *         value: user.id,
 *         label: user.name,
 *       })));
 *     })
 *   }
 * };
 *
 * const methods = useForm();
 *
 * const onSubmit = (dataForm: any) => {
 *   console.log(dataForm);
 * };
 *
 * <OlaggForm methods={methods} onSubmit={onSubmit} style={{width: '100%'}}>
 *   <OlaggFormSelect
 *     label='User'
 *     controlName='user'
 *     description='Choose the user'
 *     isSearchable={true}
 *     staticOptions={staticOptions}
 *     asyncOptions={asyncOptionsFunction}
 *   />
 *
 *  <Button type='submit'>Submit</Button>
 * </OlaggForm>
 */

interface IOlaggFormSelectProps {
  controlName: string,
  label?: string,
  description?: string,
  staticOptions: Array<any>,
  asyncOptions?: (inputValue: string) => Promise<any>,
  isSearchable?: boolean,
  visible?: boolean,
  required?: boolean,
  placeholder?: string,
  onChange?: (value: any) => void,
  onInputChange?: (value: any) => void,
  multiple?: boolean,
  newVersion?: boolean
};

const OlaggFormSelect: FC<IOlaggFormSelectProps> = ({
  controlName,
  label,
  description,
  staticOptions,
  asyncOptions,
  isSearchable = false,
  visible = true,
  required = visible,
  placeholder = 'Select...',
  onChange = (value) => { },
  onInputChange = (value) => { },
  multiple = false,
  newVersion = false
}) => {
  const { control, formState: { errors } } = useFormContext();

  const searchSelectStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
    }),
    container: (provided, state) => ({
      ...provided,
      cursor: isSearchable ? 'text' : 'default',
      mt: '2',
      mb: '5',
    }),
    menuList: (provided, state) => ({
      ...provided,
      backgroundColor: 'black',
    }),
    option: (provided, { isSelected, isFocused }) => ({
      ...provided,
      backgroundColor: isSelected ?
        'gray.800' :
        isFocused ?
          'gray.700' :
          'black',
    }),
  };

  if (asyncOptions && !isSearchable)
    console.warn('OlaggFormSelect should be searchable to benefit from using the asyncOptions function. Using staticOptions');

  const defaultAsyncOptions = async (input: string) => {
    return new Promise((resolve) => {
      resolve(staticOptions
        .filter(option => option.label.toLowerCase().includes(input.toLowerCase()))
        .map(option => typeof option === 'string' ? { value: option, label: option } : option)
      )
    });
  };

  const getSelectedValues = (field: any) => {
    if (!multiple) return staticOptions.find(option => option.value === field.value);
    if (multiple) {
      const { value } = field
      let selected: [] = []
      Object.keys(value).map(f => {
        let findSel = staticOptions.filter(option => option.value === value[f].value)
        if (findSel.length > 0) selected.push(findSel[0])
      })

      return selected
    }
  }

  return (
    <FormControl
      id={controlName}
      isRequired={required}
      isInvalid={!!errors[controlName]}
      display={visible ? 'initial' : 'none'}
    >
      {label && <FormLabel m='0' lineHeight='normal' fontWeight={600}>{label}</FormLabel>}
      {description && (<Text color='gray.400' fontSize='sm'>{description}</Text>)}
      <FormErrorMessage>
        {errors.controlName && errors.controlName?.message?.toString()}
      </FormErrorMessage>

      {newVersion &&
        <Controller
          name={controlName}
          control={control}
          render={({ field }) => {
            const selectedValue = getSelectedValues(field)
            return (<AsyncSelect
              isMulti={multiple}
              placeholder={placeholder}
              chakraStyles={searchSelectStyles}
              useBasicStyles={true}
              defaultOptions={staticOptions}
              loadOptions={asyncOptions || defaultAsyncOptions}
              isClearable={true}
              isSearchable={isSearchable}
              {...field}
              onChange={({ value }) => { field.onChange(value); onChange(value); }}
              onInputChange={(e) => { field.onBlur(); onInputChange(e); }}
              getOptionValue={(option) => option.value}
              value={selectedValue}
            />)
          }}
        />
      }
      {!newVersion &&
        <Controller
          name={controlName}
          control={control}
          render={({ field }) => {
            const selectedValue = getSelectedValues(field)
            return <AsyncSelect
              isMulti={multiple}
              placeholder={placeholder}
              chakraStyles={searchSelectStyles}
              useBasicStyles={true}
              defaultOptions={staticOptions.map(option => typeof option === 'string' ? { value: option, label: option } : option)}
              loadOptions={asyncOptions || defaultAsyncOptions}
              isClearable={true}
              isSearchable={isSearchable}
              {...field}
              value={selectedValue}
              onChange={(e) => { field.onChange(e); onChange(e); }}
            />
          }}
        />
      }
    </FormControl>
  )
};

export default OlaggFormSelect;
