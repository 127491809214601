export const StarOutlined = ({
  height = '24px',
  width = '24px',
  color = 'white',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.51603 13.323L3.02603 19.775C2.97989 19.9704 2.99374 20.1751 3.06577 20.3625C3.1378 20.5499 3.26467 20.7112 3.4298 20.8253C3.59492 20.9395 3.79064 21.0012 3.99138 21.0024C4.19212 21.0036 4.38855 20.9442 4.55503 20.832L10 17.202L15.445 20.832C15.6154 20.9451 15.8162 21.0033 16.0207 20.9988C16.2251 20.9944 16.4232 20.9274 16.5884 20.8069C16.7536 20.6865 16.878 20.5183 16.9448 20.3251C17.0116 20.1318 17.0176 19.9228 16.962 19.726L15.133 13.326L19.669 9.244C19.8143 9.11319 19.9181 8.94266 19.9674 8.75351C20.0168 8.56436 20.0097 8.36488 19.9469 8.17974C19.8841 7.99461 19.7685 7.83193 19.6142 7.71183C19.46 7.59173 19.2739 7.51947 19.079 7.504L13.378 7.05L10.911 1.589C10.8324 1.41316 10.7045 1.26384 10.5428 1.15906C10.3812 1.05429 10.1927 0.998535 10 0.998535C9.80739 0.998535 9.61887 1.05429 9.45722 1.15906C9.29556 1.26384 9.16769 1.41316 9.08903 1.589L6.62203 7.05L0.921026 7.503C0.729482 7.51818 0.546364 7.58821 0.393581 7.70473C0.240798 7.82125 0.124819 7.97932 0.0595194 8.16003C-0.00578038 8.34074 -0.0176359 8.53644 0.0253712 8.72371C0.0683784 8.91098 0.164427 9.08189 0.302026 9.216L4.51603 13.323ZM7.36903 8.997C7.54752 8.98294 7.71895 8.92112 7.86533 8.81802C8.01171 8.71491 8.12766 8.57433 8.20103 8.411L10 4.43L11.799 8.411C11.8724 8.57433 11.9883 8.71491 12.1347 8.81802C12.2811 8.92112 12.4525 8.98294 12.631 8.997L16.603 9.312L13.332 12.256C13.048 12.512 12.935 12.906 13.039 13.274L14.292 17.659L10.556 15.168C10.392 15.0579 10.199 14.9992 10.0015 14.9992C9.80403 14.9992 9.61101 15.0579 9.44703 15.168L5.54303 17.771L6.59303 13.225C6.63153 13.0578 6.62636 12.8835 6.57802 12.7189C6.52969 12.5543 6.43981 12.4048 6.31703 12.285L3.27903 9.323L7.36903 8.997Z"
        fill={color}
      />
    </svg>
  )
}

export default StarOutlined
