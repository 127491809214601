import { useState } from "react"
import { AdminCredentialsEndpoints, AdminScholarshipEndpoints, fetcher } from ".."
import { IUseCredentials } from "../interfaces/IUseCredentials"

const useAdminCredentials = () => {
  const [credentials, setCredentials] = useState([])
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const getAll = (data: IUseCredentials) => {
    setIsLoading(true)
    fetcher(AdminCredentialsEndpoints.all(data))
      .then((res) => {
        setCredentials(res.credentials)
        setCount(res.meta.count)
      })
      .catch(() => {
        setCredentials([])
        setCount(0)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    credentials,
    count,
    isLoading,
    getAll
  }
}

export default useAdminCredentials
