import { Center, Text, VStack, Heading, FormControl, Input, FormLabel, FormErrorMessage, Flex, Button, useToast, Box } from '@chakra-ui/react'
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import * as Yup from 'yup';
import type { InferType } from 'yup';

import {
  fetcher,
  AdminPaymentsEndpoints
} from '@olagg/api-hooks'
import { yupResolver } from '@hookform/resolvers/yup'

const batchCreate = () => {
  const params = useParams()
  const id = params?.id

  const toast = useToast()

  const BatchSchema = Yup.object({
    file: Yup.mixed()
  })

  type BatchComplete = InferType<typeof BatchSchema>

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors }
  } = useForm<BatchComplete>({
    resolver: yupResolver(BatchSchema),
    mode: 'onChange'
  })

  const onSubmit = (data: any) => {
    console.log(data);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    fetcher(
      AdminPaymentsEndpoints.bulk_create(formData)
    )
      .then(() => onSuccess('Subido!'))
      .catch(e => onError(e))
  }

  const onSuccess = async (title: string, body?: string) => {
    reset()
    toast({
      title,
      colorScheme: 'olaggPink',
      status: 'success',
      description: 'Archivo de Lote subido correctamente!',
      position: 'bottom-right'
    })
    setTimeout(() => {
      location.reload()
    }, 750)
  }

  const onError = (e: { error: { message: string } } | string) => {
    const message = typeof e === 'string' ? e : e.error.message
    toast({
      title: 'Error',
      colorScheme: 'olaggYellow',
      status: 'error',
      description: (
        <div
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
      ),
      position: 'bottom-right'
    })
  }

  return (
    <Flex w={'full'} py={'15px'} pb={4} minH='600px' justifyContent='center'>
      <VStack color={'white'} w={'full'} maxW={'375px'}>
        <Flex flexDirection="column" w={'full'}>
          <Heading fontSize={'30px'}>Carga masiva de pagos</Heading>
          <Flex fontSize={'14px'}>Subir un archivo de lote para generar pagos de tokens</Flex>
        </Flex>

        <form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data" style={{ width: '100%' }}>
          <Flex w={'full'} flexDirection='column' mt={'20px'}>
            <FormControl
              isInvalid={errors.file ? true : false}
            >
              <FormLabel htmlFor="file">
                Seleccionar archivo de lote* (.xlsx)
              </FormLabel>
              <Input
                sx={{
                  lineHeight: '32px',
                  paddingLeft: 0
                }}

                type='file'
                id="file"
                {...register('file')}
              />
              <FormErrorMessage>
                {errors.file &&
                  errors.file.message?.toString()}
              </FormErrorMessage>
            </FormControl>

            <Box textAlign='left' w='100%'>
              <a href='https://olagg.io/downloads/payments_create.xlsx'>
                <Button
                  variant="filledGradient"
                  flex="1"
                  size={'sm'} py='5px' mt='5px'
                >
                  Descargar archivo de ejemplo
                </Button>
              </a>
            </Box>

            <Flex w="full" style={{ marginTop: '40px' }}>
              <Button
                variant="filledGradient"
                type="submit"
                flex="1"
              >
                Subir archivo
              </Button>
            </Flex>
          </Flex>
        </form>
      </VStack>
    </Flex>
  )
}

export default batchCreate
