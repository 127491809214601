import { Table as ChakraTable, TableContainer, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { RouletteValue } from '@olagg/db-types';
import Row from './Row';

export default function Table({
  rouletteValues,
  update
}: {
  rouletteValues: RouletteValue[],
  update: (id: number, data: Partial<RouletteValue>) => void
}) {
  if (!rouletteValues?.length) return null;

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" my='5'>
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
        size='sm'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100' h='50px'>
            <Th color="white">Posición en la ruleta</Th>
            <Th color="white">XP</Th>
            <Th color="white">Probabilidad de ocurrencia (0 a 100)</Th>
            <Th color="white">Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {rouletteValues.map((val: RouletteValue) => (
            <Row key={val.id} value={val} update={update} />
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}
