import { FC, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { VStack, Button, Box, Flex } from '@chakra-ui/react'
import OlaggForm from '../../components/Forms/OlaggForm'
import OlaggFormSelect from '../../components/Forms/OlaggFormSelect'
import OlaggFormInput from '../../components/Forms/OlaggFormInput'
import { RelevantAction } from '@olagg/db-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { formRelevantActionSchema, FormRelevantActionData } from '@olagg/validation-schemas/relevantAction.schemas'
import { iconsOptions } from './Icons'

interface IRelevantActionFormProps {
  relevantAction?: RelevantAction;
  onSubmit: (dataForm: FormRelevantActionData) => void;
};

const RelevantActionForm: FC<IRelevantActionFormProps> = ({ relevantAction, onSubmit }) => {
  const useFormMethods = useForm<FormRelevantActionData>({
    resolver: yupResolver(formRelevantActionSchema),
    mode: 'onChange',
  });

  useEffect(
    () => {
      if (relevantAction) {
        const dataForm: FormRelevantActionData = {
          ...relevantAction,
          icon: {
            value: relevantAction.icon,
            label: iconsOptions.find(element => element?.value === relevantAction?.icon)?.label || <></>
          }
        }

        useFormMethods.reset(dataForm);
      }
    }, []
  )

  return (
    <OlaggForm
      useFormMethods={useFormMethods}
      onSubmit={onSubmit}
      style={{
        width: '100%'
      }}
    >
      <VStack color={'white'} w={'full'} maxW='600px' margin='0 auto'>
        <Box w='full'>
          <OlaggFormInput
            label='Nombre'
            controlName='name'
            required={true}
          />

          <OlaggFormInput
            label='Descripción de la estadística'
            controlName='statsLabel'
            description=''
            required={true}
          />

          <Flex direction='column' w='full' bgColor='#20173d' p='8' pb='4' mb='4' borderRadius={'12px'}>
            <OlaggFormInput
              label='Descripción de la estadística Inglés'
              controlName='extraData.en.statsLabel'
              required={true}
            />
            <OlaggFormInput
              label='Descripción de la estadística Portugés'
              controlName='extraData.pt.statsLabel'
              required={true}
            />
          </Flex>

          <OlaggFormInput
            label='Descripción de la acción'
            controlName='actionLabel'
            description=''
            required={true}
          />

          <Flex direction='column' w='full' bgColor='#20173d' p='8' pb='4' mb='4' borderRadius={'12px'}>
            <OlaggFormInput
              label='Descripción de la acción Inglés'
              controlName='extraData.en.actionLabel'
              required={true}
            />
            <OlaggFormInput
              label='Descripción de la acción Portugés'
              controlName='extraData.pt.actionLabel'
              required={true}
            />
          </Flex>

          <OlaggFormInput
            label='Texto del botón'
            controlName='buttonLabel'
            description=''
            required={true}
          />

          <Flex direction='column' w='full' bgColor='#20173d' p='8' pb='4' mb='4' borderRadius={'12px'}>
            <OlaggFormInput
              label='Texto del botón Inglés'
              controlName='extraData.en.buttonLabel'
              required={true}
            />
            <OlaggFormInput
              label='Texto del botón Portugés'
              controlName='extraData.pt.buttonLabel'
              required={true}
            />
          </Flex>

          <OlaggFormInput
            label='Link del botón'
            controlName='buttonLink'
            description=''
            required={true}
          />

          <OlaggFormSelect
            label='Icono'
            controlName='icon'
            required={true}
            isSearchable={false}
            staticOptions={iconsOptions}
          />

          <OlaggFormInput
            label='XP'
            controlName='score'
            required={true}
          />

          <OlaggFormInput
            label='Umbral mínimo'
            controlName='requiredUserScore'
            description='XP necesarios que tenga el user que refirió para que se le otorguen los XP al referido'
            required={true}
          />

          <OlaggFormInput
            label='Maxima cantidad de referidos'
            controlName='maxReferrers'
            description='Dejar en 0 para ilimitados'
            required={false}
          />
        </Box>

        <Button
          variant={'filledGradient'}
          type="submit"
          w={'full'}
          style={{
            marginTop: '30px'
          }}
        >
          Guardar
        </Button>

      </VStack>
    </OlaggForm>
  )
}

export default RelevantActionForm;
