import { AdminTokenDropsEndpoints, fetcher } from "@olagg/api-hooks";

const useAdminTokenDrops = () => {

    const fetchTokenDrops = async ({ page, limit, title }: { page: number, limit: number, title?: string }) =>
        fetcher(AdminTokenDropsEndpoints.all({ page, limit, title }))

    return {
        fetchTokenDrops
    }

}

export default useAdminTokenDrops;