import { Center, Heading, useToast, VStack } from "@chakra-ui/react"
import { AdminTournamentsEndpoints, fetcher, useAdminTournament } from "@olagg/api-hooks";
import { CreateTournamentParams } from "@olagg/db-types";
import { FormTournamentData } from "@olagg/validation-schemas/tournament.schemas";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import TournamentForm from "./Form"

const CreateTournament = () => {
  const [searchParams] = useSearchParams();
  const { tournament } = useAdminTournament(searchParams.get('duplicateId') || '');
  const toast = useToast();
  const navigate = useNavigate();

  const tournamentCopy = tournament && {
    ...tournament,
    title: `${tournament?.title} - Copia`
  };

  const onSubmit = (tournamentData: CreateTournamentParams) =>
    fetcher(AdminTournamentsEndpoints.create(tournamentData))
      .then(() => {
        toast({
          title: 'Torneo',
          colorScheme: 'olaggPink',
          status: 'success',
          description: `Torneo creado con éxito`,
          position: 'bottom-right'
        });
        navigate(ROUTES.TOURNAMENTS.all);
      })
      .catch((error: Error) => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: error.message,
          position: 'bottom-right'
        });
      })


  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Torneo
        </Heading>
        <TournamentForm onSubmit={onSubmit} tournament={tournamentCopy || undefined} />
      </VStack>
    </Center>
  )
}

export default CreateTournament
