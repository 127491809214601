import { Box, Heading, VStack } from "@chakra-ui/react"
import { AdminTokenDropsEndpoints, fetcher } from "@olagg/api-hooks";
import type { CreateTokenDrop as CreateTokenDropData } from "@olagg/db-types";
import TokenDropForm, { FormTokenDropData } from "./Form"


const CreateTokenDrop = () => {

    const onSubmit = (dataForm: FormTokenDropData) => {
        const tokenDropData: Partial<CreateTokenDropData> = dataForm

        return fetcher(AdminTokenDropsEndpoints.create({ tokenDrop: tokenDropData }))
    }

    return (
        <Box w={'full'} pb={4} minH='600px'>
            <VStack color={'white'} w={'full'}>
                <Heading my={2}>
                    Crear Token Drop
                </Heading>
                <TokenDropForm onSubmit={onSubmit} />
            </VStack>
        </Box>
    )
}

export default CreateTokenDrop
