import { Center, Heading, VStack } from "@chakra-ui/react"
import { useAdminAlerts } from "@olagg/api-hooks";
import { Alert } from "@olagg/db-types";
import { FormAlertData } from "@olagg/validation-schemas/alert.schemas";
import { useNavigate } from "react-router-dom";
import AlertsForm from "./Form"
import { ROUTES } from "../../consts/routes";

const CreateAlert = () => {
  const navigate = useNavigate();
  const { createAlert } = useAdminAlerts({ page: 1, limit: 10 })

  const onSubmit = (dataForm: FormAlertData) => {
    const alertData: Partial<Alert> = {
      ...dataForm,
      type: dataForm.type.value,
      showInPath: dataForm.showInPath.replace(/\s/g, '').split(',')
    };
    createAlert(alertData, () => navigate(ROUTES.ALERTS.all));
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Alerta
        </Heading>
        <AlertsForm onSubmit={onSubmit}/>
      </VStack>
    </Center>
  )
}

export default CreateAlert
