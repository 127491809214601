import { FC } from 'react'
import { useToast } from '@chakra-ui/react'
import { fetcher, AdminMarketPlaceEndpoints } from '@olagg/api-hooks'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import 'moment/min/locales'
import { ItemData } from '@olagg/validation-schemas'
import ItemForm from './Form'

const MarketplaceCreate: FC = () => {
  const navigate = useNavigate()
  const toast = useToast()

  const onSubmit = (data: FormData) =>
    fetcher(AdminMarketPlaceEndpoints.create(data))
      .then(() => {
        toast({
          title: 'Actualizado!',
          colorScheme: 'olaggPink',
          status: 'success',
          description: 'Item creado con éxito',
          position: 'bottom-right'
        })
        navigate(ROUTES.MARKETPLACE.all)
      })
      .catch(e => {
        toast({
          title: 'Error',
          colorScheme: 'olaggYellow',
          status: 'error',
          description: e.errors,
          position: 'bottom-right'
        })
      })

  return (
    <ItemForm onSubmit={onSubmit} />
  )
}

export default MarketplaceCreate
