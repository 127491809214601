import { Box, Heading, VStack } from "@chakra-ui/react"
import { AdminTokenDropsEndpoints, fetcher } from "@olagg/api-hooks";
import type { CreateTokenDrop, TokenDrop } from "@olagg/db-types";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TokenDropForm, { FormTokenDropData } from "./Form"


const UpdateTokenDrop = () => {
    const [tokenDrop, setTokenDrop] = useState<TokenDrop>()
    const params = useParams()
    const id = params.id!
    const onSubmit = (dataForm: FormTokenDropData) => {
        const tokenDropData: Partial<CreateTokenDrop> = dataForm

        return fetcher(AdminTokenDropsEndpoints.update(id, tokenDropData))
    }

    useEffect(() => {
        fetcher(AdminTokenDropsEndpoints.find(id))
            .then((response) => {
                // remove null values
                Object.keys(response.tokenDrop)
                    .forEach((key) => (
                        response.tokenDrop as any
                    )[key] === null && delete (response.tokenDrop as any)[key]);
                setTokenDrop(response.tokenDrop)
            })
    }, [id])

    return (
        <Box w={'full'} pb={4} minH='600px'>
            <VStack color={'white'} w={'full'}>
                <Heading my={2}>
                    Editar Token Drop
                </Heading>
                {tokenDrop && <TokenDropForm onSubmit={onSubmit} tokenDrop={tokenDrop} />}
            </VStack>
        </Box>
    )
}

export default UpdateTokenDrop
