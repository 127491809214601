import OlaggForm from "./OlaggForm";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { formNotificationSchema, NotificationFormData } from "@olagg/validation-schemas/notification.schema";
import OlaggFormInput from "./OlaggFormInput";
import OlaggFormSelect from "./OlaggFormSelect";
import useAdminSegments from "../../pages/Notification/useAdminSegments";
import { Button, Center, Flex, useToast, VStack } from "@chakra-ui/react";
import { AdminNotificationEndpoints, fetcher } from "@olagg/api-hooks";
import { useNavigate } from "react-router-dom";
import { Notification, NotificationType } from "@olagg/db-types";
import { useState } from "react";

const NotificationsForm = () => {
    const toast = useToast();
    const navigate = useNavigate();
    const [type, setType] = useState<string>('');
    const isInApp = type === 'IN_APP';

    const {segments, setName: setSegmentName} = useAdminSegments({
        page: 1,
        limit: 1000
    })

    const useFormMethods = useForm<NotificationFormData>({
        resolver: yupResolver(formNotificationSchema),
        mode: 'onChange'
    });

    const onSuccess = (message: string) => {
        toast({
            title: message,
            status: 'success',
            duration: 5000,
            isClosable: true
        })
        navigate('/notifications')
    }

    const onError = (e: any) => {
        toast({
            title: 'Error',
            description: e.message,
            status: 'error',
            duration: 5000,
            isClosable: true
        })
    }

    const onSubmit = (data: NotificationFormData) => {
        const body: Partial<Notification>= {
            title: data.title,
            body: data.body,
            image: data.image,
            link: data.link,
            linkLabel: data.linkLabel,
            type: data.type.value as NotificationType,
            segmentId: data.segment?.value,
            userId: data.userId,
            // userIds: data.userIds,
            extraData: data.extraData!
        }

        fetcher(
            AdminNotificationEndpoints.create(body)
          )
            .then(() => onSuccess('Notificacion creada correctamente'))
            .catch(onError)
    }

    const segmentOptions = segments?.map(segment => ({
        value: segment.id,
        label: `${segment.name} - ${segment.description}`
    })) || [{value: '', label: 'No hay segmentos disponibles'}]

    return(
        <OlaggForm
          useFormMethods={useFormMethods}
          onSubmit={onSubmit}
          style={{
            width: '100%'
          }}
        >
            <Center w={'full'} p={4} >
                <VStack
                w={{ base: '70vw', lg: '50vw' }}
                mt={2}
                mb={10}
                >
                    <OlaggFormSelect
                        controlName={'type'}
                        label={'Tipo'}
                        staticOptions={[
                            {value: 'PUSH', label: 'Push'},
                            {value: 'EMAIL', label: 'Email'},
                            {value: 'IN_APP', label: 'In-App'}
                        ]}
                        required={true}
                        onChange={newValue => setType(newValue?.value)}
                    />
                    <OlaggFormInput
                        controlName={'title'}
                        label={'Título'}
                        required={true}
                    />
                    <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                        <OlaggFormInput
                            label='Título (Inglés)'
                            controlName='extraData.en.title'
                        />
                        <OlaggFormInput
                            label='Título (Portugués)'
                            controlName='extraData.pt.title'
                        />
                    </Flex>
                    <OlaggFormInput
                        controlName={'body'}
                        label={'Descripción'}
                        description={'Texto a mostrar en el cuerpo de la notificación (para el caso de notificaciones In-App: el máximo recomendado son 100 caracteres)'}
                        required={true}
                    />
                    <Flex direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                        <OlaggFormInput
                            label='Descripción (Inglés)'
                            controlName='extraData.en.body'
                        />
                        <OlaggFormInput
                            label='Descripción (Portugués)'
                            controlName='extraData.pt.body'
                        />
                    </Flex>
                    <OlaggFormInput
                        controlName={'image'}
                        label={'Imagen'}
                        description={'Imagen que aparecerá en la notificación (para el caso de notificaciones In-App: el tamaño recomendado es 50x50px)'}
                    />
                    <OlaggFormInput
                        controlName={'link'}
                        label={'Link'}
                        description={'El usuario sera redirigido a este link al hacer click'}
                    />
                    <OlaggFormInput
                        controlName={'linkLabel'}
                        label={'Texto del Link'}
                        description={'Texto a mostrar en el link (sólo aplica en notificaciones In-App)'}
                        visible={isInApp}
                    />
                    <Flex display={isInApp ? 'flex' : 'none'} direction='column' w='full' bgColor='#150B36' p='8' borderRadius={'12px'}>
                        <OlaggFormInput
                            label='Texto del Link (Inglés)'
                            controlName='extraData.en.linkLabel'
                        />
                        <OlaggFormInput
                            label='Texto del Link (Portugués)'
                            controlName='extraData.pt.linkLabel'
                        />
                    </Flex>
                    <OlaggFormSelect
                        controlName={'segment'}
                        label={'Segmento'}
                        description={'La notificación será enviada a todos los usuarios que pertenezcan a este segmento'}
                        staticOptions={segmentOptions}
                        required={!isInApp}
                        visible={!isInApp}
                        onInputChange={setSegmentName}
                    />
                    <OlaggFormInput
                        controlName='userId'
                        label='User ID'
                        required={isInApp}
                        visible={isInApp}
                        description='ID del usuario que recibirá la notificación'
                    />

                    <Button
                        variant="filledGradient"
                        type="submit"
                        w={'full'}
                        mt={4}
                    >
                        Crear Notificación
                    </Button>
                </VStack>
            </Center>
        </OlaggForm>
    )
};
export default NotificationsForm;
