import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Center,
  Image,
  Text
} from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../consts/routes'
import back from '@olagg/ui-kit/svg/back.png'

const Breadcrumbs = () => {
  const navigate = useNavigate()
  const goBack = () => navigate(-1)

  const showBackButton =
    location.pathname !== ROUTES.GAMES.all &&
    location.pathname !== ROUTES.SCHOLARSHIP_APPLICATIONS &&
    location.pathname !== ROUTES.CREDENTIALS.all &&
    location.pathname !== ROUTES.USERS.all &&
    location.pathname !== ROUTES.HOME

  const crumbs = location.pathname
    .split('/')
    .filter(crumb => crumb)

  const isLast = (index: number) => {
    return index === crumbs.length - 1
  }

  const buildLink = (crumb: string) => {
    const index = crumbs.indexOf(crumb)
    const path = crumbs
      .slice(0, index + 1)
      .toString()
      .replace(/,/g, '/')
    return `/${path}`
  }

  const buildDisplayCrumb = (crumb: string) =>
    `${crumb[0]?.toUpperCase()}${crumb.slice(1).toLowerCase()}`

  return (
    <Center gap="4">
      {showBackButton && (
        <Button variant="link" onClick={goBack}>
          <Image src={back} w="15px" />
        </Button>
      )}
      <Breadcrumb
        color="white"
        alignItems="center"
        justifyContent="center"
      >
        <BreadcrumbItem>
          <Link to={ROUTES.HOME}>
            <Text>
              {buildLink(location.pathname) === '/'
                ? 'Home'
                : 'Pages'}
            </Text>
          </Link>
        </BreadcrumbItem>
        {crumbs.map((crumb: string, index: number) => (
          <BreadcrumbItem key={index}>
            {!isLast(index) ? (
              <Link to={buildLink(crumb)}>
                {buildDisplayCrumb(crumb)}
              </Link>
            ) : (
              <Text fontWeight="bold">
                {buildDisplayCrumb(crumb)}
              </Text>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </Center>
  )
}
export default Breadcrumbs
