import { Center, VStack, Heading, Spinner } from "@chakra-ui/react";
import { useAdminAlerts } from "@olagg/api-hooks";
import { Alert } from "@olagg/db-types";
import { FormAlertData } from "@olagg/validation-schemas/alert.schemas";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import AlertForm from "./Form";

const EditAlert = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { alert, find, updateAlert } = useAdminAlerts({ page: 1, limit: 10 });
  
  useEffect(() => {
    find(Number(id));
  }, [id]);

  const onSubmit = (dataForm: FormAlertData) => {
    const alertData: Partial<Alert> = {
      ...dataForm,
      type: dataForm.type.value,
      showInPath: dataForm.showInPath.replace(/\s/g, '').split(',')
    };
    updateAlert(Number(id), alertData, () => navigate(ROUTES.ALERTS.all));
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Editar Alerta
        </Heading>
        {alert ?
          <AlertForm onSubmit={onSubmit} alert={alert} /> :
          <Spinner>
            Cargando...
          </Spinner>}
      </VStack>
    </Center>
  );
}


export default EditAlert;
