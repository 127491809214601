import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { OlaggModule, PermissionEnum, TournamentData } from "@olagg/db-types";
import { Table as ChakraTable } from "@chakra-ui/react";
import { generatePath, Link as ReactLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../consts/routes";
import { BsSortDownAlt, BsSortDown } from 'react-icons/bs';
import { slugify } from '../../utils';
import useRoles from '../../features/Roles/hooks/useRoles';

const Table = ({
  tournaments,
  deleteTournament,
  sortByStartDate,
  togleSortByStartDate
}: {
  tournaments: TournamentData[],
  deleteTournament: (id: string) => void,
  sortByStartDate: 'asc' | 'desc',
  togleSortByStartDate: () => void
}) => {
  const { missingPermission } = useRoles();
  const navigate = useNavigate()
  const goToEdit = (id: string) => () => {
    const path = generatePath(ROUTES.TOURNAMENTS.update, { id })
    navigate(path)
  }

  return (
    <TableContainer overflowX={'scroll'} overflowY={'scroll'} w="80vw" h="60vh" mt="12">
      <ChakraTable
        variant="striped"
        colorScheme="olaggPurple"
        fontSize="18px"
        position='relative'
      >
        <Thead>
          <Tr position='sticky' bg='gray.800' top='0' zIndex='100'>
            <Th color="white" maxW='200px'>Título</Th>
            <Th color="white">Juego (slug)</Th>
            <Th color="white">Región</Th>
            <Th color="white">Id Organizador</Th>
            <Th color="white" cursor='pointer'
              _hover={{ color: '#777' }}
              onClick={() => togleSortByStartDate()}
            >
              <Flex gap='2'>
                <span>Inicio Torneo</span>
                {sortByStartDate === 'asc' ? <BsSortDownAlt fontSize='1.3em' /> : <BsSortDown fontSize='1.3em' />}
              </Flex>
            </Th>
            <Th color="white">Inicio Inscripción</Th>
            <Th color="white">Formato</Th>
            <Th color="white">URL</Th>
            <Th color="white">Estado</Th>
            <Th color="white" textAlign='center'>Código Partida</Th>
            <Th color="white" textAlign='center'>Inicio de Partida</Th>
            <Th color="white" textAlign='center'>Acción</Th>
          </Tr>
        </Thead>
        <Tbody>
          {tournaments && tournaments.map((tournament, index) => (
            <Tr key={index} _hover={{ color: 'cyan' }}>
              <Td onClick={goToEdit(tournament.id)} cursor={"pointer"}>{tournament.title}</Td>
              <Td>{tournament.slug}</Td>
              <Td>{tournament.region}</Td>
              <Td>{tournament.organizer?.name}</Td>
              <Td>{tournament.startDate && new Date(tournament.startDate).toDateString()}</Td>
              <Td>{new Date(tournament.inscriptionBases.openAt).toDateString()}</Td>
              <Td>{tournament.type}</Td>
              <Td>{`/torneos/${slugify(tournament.title)}`}</Td>
              <Td>{tournament.status}</Td>
              <Td textAlign='center'>
                {isInProgress(tournament) && tournament.matchCode}
              </Td>
              <Td textAlign='center'>
                {isInProgress(tournament) && tournament.matchStartDate &&
                  new Date(tournament.matchStartDate).toLocaleString('es-ES', {
                    day: '2-digit', month: 'short', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short'
                  })
                }
              </Td>
              <Td textAlign='center'>
                <Flex gap='3'>
                  <Button as={ReactLink} size={'sm'} padding='0px 8px' colorScheme='green'
                    isDisabled={missingPermission(OlaggModule.Tournament, PermissionEnum.EDIT)}
                    to={generatePath(ROUTES.TOURNAMENTS.update, { id: tournament.id })}
                  >
                    Editar
                  </Button>

                  <Button as={ReactLink} size={'sm'} padding='0px 8px' colorScheme='cyan'
                    to={{
                      pathname: ROUTES.TOURNAMENTS.create,
                      search: `?duplicateId=${tournament.id}`
                    }}
                    isDisabled={missingPermission(OlaggModule.Tournament, PermissionEnum.EDIT)}
                  >
                    Duplicar
                  </Button>

                  <Button as={ReactLink} size={'sm'} padding='0px 8px' colorScheme='blue'
                    to={generatePath(ROUTES.TOURNAMENTS.inscriptions, { id: tournament.id })}
                    isDisabled={missingPermission(OlaggModule.Inscription, PermissionEnum.VIEW)}
                  >
                    Participantes
                  </Button>

                  {isInProgress(tournament) && (
                    <Button as={ReactLink} size={'sm'} padding='0px 8px' colorScheme='green'
                      to={{
                        pathname: generatePath(ROUTES.TOURNAMENTS.update, { id: tournament.id }),
                        search: '?section=matches'
                      }}
                      isDisabled={missingPermission(OlaggModule.Tournament, PermissionEnum.EDIT)}
                    >
                      Partidas
                    </Button>
                  )}

                  <Popover>
                    <PopoverTrigger>
                      <Button
                        size={'sm'} padding='0px 8px' ml={0} colorScheme="red"
                        isDisabled={missingPermission(OlaggModule.Tournament, PermissionEnum.DELETE)}
                      >
                        Eliminar
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent>
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody>
                          <Button width={'100%'} colorScheme='red'
                            onClick={() => deleteTournament(tournament.id!)}
                          >
                            Confirmar Eliminar
                          </Button>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                </Flex>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  )
}

export default Table

export const isInProgress = (tournament: TournamentData) => {
  const TWO_HOURS = 2 * 60 * 60 * 1000;
  const now = Date.now();
  const tournamentStart = new Date(tournament.startDate).getTime();
  const tournamentEnd = tournament?.endDate ? new Date(tournament.endDate).getTime() : tournamentStart + TWO_HOURS;
  const inscriptionsEnd = tournament?.inscriptionBases?.closeAt ? new Date(tournament.inscriptionBases.closeAt).getTime() : tournamentStart;

  return (
    now <= inscriptionsEnd ||
    now <= tournamentStart ||
    now <= tournamentEnd
  );
}
