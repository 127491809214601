import { AdminTokensEndpoints, fetcher } from ".."

const useTokens = (sdk: any) => {

  const getTokenData = async (tokenAddress: string, tokenId: number) => {
    const contract = await sdk.getContract(tokenAddress)
    const tokenData = await contract.erc1155.get(tokenId)
    return tokenData
  }

  const getToken = async (tokenId: string) => {
    const response = await fetcher(AdminTokensEndpoints.find(tokenId))

    return response.token
  }

  return {
    getTokenData,
    getToken
  }
}

export default useTokens
