import { Center, Heading, VStack } from "@chakra-ui/react"
import { useAdminRelevantActions } from "@olagg/api-hooks";
import { RelevantAction } from "@olagg/db-types";
import { FormRelevantActionData } from "@olagg/validation-schemas/relevantAction.schemas";
import { useNavigate } from "react-router-dom";
import RelevantActionsForm from "./Form"
import { ROUTES } from "../../consts/routes";

const CreateRelevantAction = () => {
  const navigate = useNavigate();
  const { createRelevantAction } = useAdminRelevantActions({ page: 1, limit: 10 })

  const onSubmit = (dataForm: FormRelevantActionData) => {
    const relevantActionData: Partial<RelevantAction> = {
      ...dataForm,
      icon: dataForm.icon.value
    };
    createRelevantAction(relevantActionData, () => navigate(ROUTES.RELEVANT_ACTIONS.all));
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Acción Relevante
        </Heading>
        <RelevantActionsForm onSubmit={onSubmit}/>
      </VStack>
    </Center>
  )
}

export default CreateRelevantAction
