import { Center, Heading, VStack } from "@chakra-ui/react"
import { useAdminRoles } from "@olagg/api-hooks";
import { Role } from "@olagg/db-types";
import { useNavigate } from "react-router-dom";
import RolesForm, { FormRolesData } from "./Form"
import { ROUTES } from "../../../consts/routes";

const CreateRole = () => {
  const navigate = useNavigate();
  const { createRole } = useAdminRoles({ page: 1, limit: 10 })

  const onSubmit = (dataForm: FormRolesData) => {
    const rolesData: Partial<Role> = {
      ...dataForm,
    };
    createRole(rolesData, (newRole) => {
      navigate(ROUTES.ROLES.all);
    });
  }

  return (
    <Center w={'full'} pb={4}>
      <VStack color={'white'} w={'full'}>
        <Heading my={2}>
          Crear Rol
        </Heading>
        <RolesForm onSubmit={onSubmit}/>
      </VStack>
    </Center>
  )
}

export default CreateRole
