import type { InferType } from 'yup';
import * as Yup from 'yup';

export const formNotificationSchema = Yup.object({
    title: Yup
        .string()
        .required('El título es requerido')
        .min(3, 'El título debe ser de mínimo 3 caracteres')
        .max(100, 'El título debe ser de máximo 64 caracteres'),
    body: Yup
        .string()
        .required('El cuerpo es requerid')
        .min(3, 'El cuerpo debe ser de mínimo 3 caracteres')
        .max(100, 'El cuerpo debe ser de máximo 64 caracteres'),
    link: Yup.string().optional(),
    linkLabel: Yup.string().optional(),
    image: Yup.string().optional(),
    segment: Yup
        .object({
            value: Yup
                .string(),
            label: Yup
                .string()
        })
        .optional(),
    type: Yup
        .object({
            value: Yup
                .string()
                .required('Introduce el tipo de notificación'),
            label: Yup
                .string()
                .required('Opcion inválida (el tipo de notificación no puede estar en blanco)'),
        })
        .required('Introduce el tipo de notificación'),
    discordId: Yup.string().optional(),
    discordUsername: Yup.string().optional(),
    userId: Yup
        .number()
        .optional(),
    userIds: Yup
        .array(Yup.number())
        .optional(),
    extraData: Yup
        .object({
            en: Yup
                .object({
                    title: Yup.string().optional().nullable(),
                    body: Yup.string().optional().nullable(),
                    link_label: Yup.string().optional().nullable()
                })
                .optional()
                .nullable(),
            pt: Yup
                .object({
                    title: Yup.string().optional().nullable(),
                    body: Yup.string().optional().nullable(),
                    link_label: Yup.string().optional().nullable()
                })
                .optional()
                .nullable(),
        })
        .optional()
        .nullable()
})

export type NotificationFormData = InferType<typeof formNotificationSchema>